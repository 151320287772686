import "./styles.scss";
import { Select } from "antd";

type SingleSelectProps = {
  options: any[];
  label: string;
  value: any;
  onChange: (value: any) => void;
  loading?: boolean;
};

export const SingleSelect = ({
  options,
  label,
  value,
  onChange,
  loading,
}: SingleSelectProps) => {
  return (
    <div className="d-flex align-center single-select position-relative">
      <span className="position-absolute z-100 label">{label}:</span>
      <Select
        options={options}
        value={value}
        onChange={onChange}
        loading={loading}
      />
    </div>
  );
};
