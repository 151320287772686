import { ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "antd";

type HeaderProps = {
  header: string;
  onBackClicked: () => void;
  removeBackButton?: boolean;
};

export const Header = ({
  header,
  onBackClicked,
  removeBackButton,
}: HeaderProps) => {
  return (
    <>
      <div
        className={`greeting__container justify-space-${
          removeBackButton ? "around" : "between"
        } mb-2`}
      >
        {!removeBackButton && (
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size="large"
            className="back-btn"
            onClick={onBackClicked}
          />
        )}
        <h1 className="header">{header}</h1>
        {!removeBackButton && <div />}
      </div>
    </>
  );
};
