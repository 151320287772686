import { API_URL } from "../configs/apiUrl";
import { authHeader } from "./data";
import { SendVerificationCodeRequest } from "../types/SendVerificationCodeRequest";
import authAxios from "./data";

const fetchUser = async () => {
  const response = await authAxios.get(API_URL + "UserProfile", {
    headers: authHeader(),
  });

  return response.data;
};

const changeEmailNotificationSettings = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "UserProfile/ChangeEmailNotificationSettings",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const sendVerificationCode = async (request: SendVerificationCodeRequest) => {
  const response = await authAxios.post(
    API_URL + "VerificationCode/Send",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const confirmVerificationCode = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "VerificationCode/Confirm",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const changePassword = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "UserProfile/ChangePassword",
    request,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const changePhone = async (phone: string) => {
  const response = await authAxios.post(
    API_URL + "UserProfile/ChangePhone",
    {
      phone,
    },
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const changeEmail = async (email: string) => {
  const response = await authAxios.post(
    API_URL + "UserProfile/ChangeEmail",
    {
      email,
    },
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

export const ProfilesService = {
  fetchUser,
  changeEmailNotificationSettings,
  sendVerificationCode,
  confirmVerificationCode,
  changePassword,
  changePhone,
  changeEmail,
};
