import { FormInstance } from "antd";

export const useValidateOnBlur = (form: FormInstance) => {
  const validateOnBlur = (e: any, filedName: string) => {
    const value = e.target.value;

    if (value.length > 0) {
      form.validateFields([filedName]);
    }
  };

  return [validateOnBlur];
};
