import { Button } from "antd";
import { Outlet } from "react-router-dom";
import "./styles.scss";
import { Copyright } from "../../Copyright";
import { useCallback, useState } from "react";
import { TermsAndConditionsModal } from "../../TermsAndConditionsModal";

export const Footer = () => {
  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const onCloseTerms = useCallback(() => setTermsTabId(undefined), []);

  return (
    <>
      <Outlet />

      <div className="px-8 py-3 footer-logged-in__container position-fixed align-center z-100">
        <Copyright />
        <div className="footer-link d-flex align-center">
          <div className="elipse ml-2" />
          <Button
            type="link"
            className="footer-text pl-2 pr-0 py-0"
            onClick={() => setTermsTabId("1")}
          >
            Terms of Use
          </Button>
          <div className="elipse ml-2" />
          <Button
            type="link"
            className="footer-text px-2 py-0"
            onClick={() => setTermsTabId("2")}
          >
            Privacy Policy
          </Button>
        </div>

        <TermsAndConditionsModal
          termsTabId={termsTabId}
          setTermsTabId={setTermsTabId}
          onClose={onCloseTerms}
        />
      </div>
    </>
  );
};
