import { useState } from "react";
import { useAppDispatch } from "../../../store";
import { acceptAgreements } from "../../../acions/auth";

export const useAcceptTerms = () => {
  const dispatch = useAppDispatch();

  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const [acceptButton, setAcceptButton] = useState<any>(undefined);

  const onCloseTerms = () => setTermsTabId(undefined);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const accept = (onAccept: (token: string) => void): void => {
    setIsLoading(true);

    Promise.resolve(dispatch(acceptAgreements()))
      .then((res: any) => {
        onAccept(res.token);
        setTermsTabId(undefined);
        setIsLoading(false);
      })
      .catch(() => {
        setTermsTabId(undefined);
        setIsLoading(false);
      });
  };

  return {
    termsTabId,
    setTermsTabId,
    accept,
    acceptButton,
    setAcceptButton,
    isLoading,
    onCloseTerms,
  };
};
