import { Row } from "antd";

export const Terms = () => {
  return (
    <Row className="d-block pa-8 terms-modal-body">
      <h4 className="h h4 mt-0 mb-4 w-500">NOTESERVE, LLC</h4>
      <h4 className="h h4 mt-0 mb-4 w-500">TERMS OF USE</h4>
      <p className="text-md mt-0 mb-4 w-500">Effective as of April 12, 2024​</p>
      <h4 className="h h4 mt-0 mb-4">
        1.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Background​</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        A reference to “<span className="underline">NoteServe</span>,” “
        <span className="underline">We</span>,” “
        <span className="underline">Us</span>,” “
        <span className="underline">Our</span>,” or the “
        <span className="underline">Company</span>” is a reference to NoteServe,
        LLC, a New Mexico limited liability company. These Terms of Use (the "
        <span className="underline">Terms</span>" or "
        <span className="underline">Agreement</span>") apply to parties,
        including Escrow Providers and Customers, as defined below, who use the
        Sites and Services, as such terms are defined below (collectively “
        <span className="underline">Users”</span> or “
        <span className="underline">you”</span>) and set forth the terms and
        conditions for such use. The Services are also subject to additional
        policies, rules and terms and conditions, which you may be required to
        agree to as a condition of using those Services, including without limit
        the Platform Partner Agreement (collectively "
        <span className="underline">Additional Terms</span>"). In those cases,
        the applicable Additional Terms are set forth in the printed or online
        Service materials relating to those Services.
      </p>
      <p className="text-md mt-0 mb-4">
        For purposes of these Terms, except to the extent expressly excluded
        below, the "<span className="underline">Site</span>" shall mean
        portal.sunwestescrow.com and any other websites, webpages, and mobile
        websites operated by NoteServe in the United States, including the
        Platform as described below, and the "
        <span className="underline">Services</span>" shall mean any of the
        various services that NoteServe provides through the Site or any other
        channels, including without limitation, over the telephone, as well as
        operation of the Platform. However, the terms "
        <span className="underline">Site</span>" and "
        <span className="underline">Services</span>" do not include or involve
        any materials or applications which have separate terms of service that
        do not expressly incorporate these Terms by reference.
      </p>
      <p className="text-md mt-0 mb-4">
        Please read these Terms and any applicable Additional Terms before using
        the Site or Services. By using the foregoing, you hereby represent,
        warrant, understand, agree to and accept these Terms and any applicable
        Additional Terms in their entirety.
      </p>
      <p className="text-md mt-0 mb-4">
        <span className="text-md w-500">
          IMPORTANT NOTICE – This Agreement is subject to binding arbitration
          provisions and a waiver of class action rights, each as detailed in{" "}
          <span className="underline">Section 11</span>.
        </span>
        This Agreement contains an Agreement to Arbitrate, which will, with
        limited exception, require you to submit claims you have against
        NoteServe to binding and final arbitration, unless you opt out of the
        Agreement to Arbitrate (see{" "}
        <span className="underline">Section 11</span>) no later than 30 days
        after the date you first use the Site or Services. Unless you opt out:
        (a) you will only be permitted to pursue claims against NoteServe on an
        individual basis, not as a plaintiff or class member in any class or
        representative action or proceeding, and (b) you will only be permitted
        to seek relief (including monetary, injunctive, and declaratory relief)
        on an individual basis.
      </p>
      <p className="text-md mt-0 mb-4 w-500">
        Use of the Site and Services is subject to the NoteServe Privacy Policy,
        which is incorporated herein. If you object to any aspect of these
        Terms, the Privacy Policy, or any Additional Terms, do not use the Site
        or Services.
      </p>
      <p className="text-md mt-0 mb-4">
        <span className="text-md w-500">
          We reserve the right to revise these Terms or change or remove
          features of the Site at any time.
        </span>{" "}
        We will notify you about material changes in these Terms by placing a
        prominent notice on our Site, and such changes will go into effect as of
        the date they are posted. You should periodically check the Site for
        updates to these Terms so that you can choose whether to continue using
        our Services. Any use of the Site or the Services by you after the
        effective date of any changes will constitute your acceptance of such
        changes. Any updated Terms supersede all prior versions of the Terms.
        Your continued use of the Site means that you accept and agree to be
        bound by the revised Terms.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        2.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Description of Services</span>
      </h4>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        2.1.&nbsp;&nbsp;&nbsp;
        <span className="w-500">About Our Services</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        NoteServe offers various Services for record keeping and servicing
        contracts for escrow service providers (“
        <span className="underline">Escrow Providers</span>”) and their
        customers and contracting parties (“
        <span className="underline">Customers</span>”). The Services we offer
        include, among others:
      </p>
      <ul>
        <li className="mb-4">
          We provide a Software-as-a-Services Platform (the “
          <span className="underline">Platform</span>”) for Escrow Providers to
          create accounts for Customers, track payments, calculate interest,
          track disbursements, issue tax statements, detailed transaction
          history, and maintain copies of transaction documentation (“
          <span className="underline">Contracts</span>”).
        </li>
        <li className="mb-4">
          We enable Escrow Providers to create and customize them for specific
          Contracts.
        </li>
        <li className="mb-4">
          The Platform facilitate the service of the Contracts.
        </li>
        <li className="mb-4">
          We offer options through the Platform to maintain copies of Contract
          documentation for Escrow Providers and Customers to access.
        </li>
        <li className="mb-4">
          We fulfill the obligations of the Company pursuant to any Additional
          Terms between NoteServe and any Escrow Provider.
        </li>
        <li className="mb-4">
          The Platform facilitates processing payments in connection with the
          Contracts.
        </li>
        <li className="mb-4">
          The Platform facilitates processing for Services.
        </li>
        <li className="mb-4">
          We provided customer support for Escrow Providers and Customers on the
          Platform.
        </li>
        <li className="mb-4">
          We may offer through Fluid Pay LLC ("
          <span className="underline">Fluid Pay</span>"), or other bank
          third-party services that facilitate payment for Services, including
          servicing Contracts, via credit card, debit card or other forms of
          payment. These payment processing services are provided by Fluid Pay
          are subject to Fluid Pay’s terms and conditions, including its &nbsp;
          <a
            href="https://www.fluidpay.com/privacy-policy"
            target="_blank"
            rel="noreferrer"
            className="underline"
          >
            Privacy Policy
          </a>
          . Users that use these payment processing services also agree to be
          bound by such terms and conditions as may be modified by Fluid Pay
          from time to time in accordance of their terms. As a condition of
          NoteServe enabling payment processing services through FluidPay, you
          agree to provide FluidPay accurate and complete information about you,
          and you authorize NoteServe to share account and transaction
          information related to your use of the payment processing services
          provided by FluidPay. NoteServe assumes no liability or responsibility
          for any payments you may make through this service, and all such
          payments are non-refundable.
        </li>
      </ul>
      <p className="text-md mt-0 mb-4 ml-2">
        Our Services continue to grow and change. Please refer to our Site and
        periodically check for updates to these Terms for further information
        about the Services we provide.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        2.2.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Limitations of our Services</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        We do not employ or otherwise manage Escrow Providers. Except as set
        forth below, Escrow Providers are responsible for compliance with all
        applicable laws in their operations and subject to such agreements,
        terms, and conditions as they may require. We do not have control over
        the quality or nature of the services provided by Escrow Providers, nor
        the actions of Customers. We do not recommend Escrow Providers or
        Customers make any representations about the Services.
      </p>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        NoteServe is not responsible for the conduct, whether online or offline,
        of any User of the Site or Services. Moreover, NoteServe does not assume
        and expressly disclaims any liability that may result from the use of
        information provided on our Site. All Users, including both Customers
        and Escrow Providers, hereby expressly agree not to hold NoteServe or
        its officers, managers, members, employees, subsidiaries, other
        affiliates, successors, assignees, agents, representatives, advertisers,
        marketing partners, licensors, independent contractors, recruiters and
        corporate partners, (hereinafter "
        <span className="underline">Affiliates</span>") liable for the actions
        or inactions of any Escrow Provider, Customer, or other third-party or
        for any information, instruction, advice or services that may have
        originated through the Site or related to the Contracts. NoteServe and
        its Affiliates expressly disclaims any liability whatsoever for any
        damage, suits, claims, and/or controversies arising out of the actions
        of Customers and Escrow Providers in connection with the Services.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        2.3.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Customer Terms</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        The following terms apply to Users who qualify in connection with their
        use of the Services as Customers.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        Customers can view their Contracts and information through their account
        on the Platform. Customers receive escrow services directly from the
        applicable Escrow Provider. Customer agrees to pay all applicable fees
        and amounts pursuant to any agreement between them and the applicable
        Escrow Provider. Customers are responsible for providing accurate,
        current, and complete information in connection with their use of the
        Site and the Services, including providing complete and accurate
        information to Escrow Providers in connection with using the Site and
        Services. NoteServe is not responsible for any claims relating to any
        inaccurate, untimely, or incomplete information provided by Users of the
        Site, including Escrow Providers and Customers.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        Customers will be given access to the Platform by your Escrow Provider
        to review your Contracts and information. The first time you log into
        the Platform, you will be required to create a unique username and a
        unique password. You agree to keep your username and password
        confidential. Anyone with access to your username and password will have
        access to your personally identifiable information. It is your
        responsibility to change your password if you believe it has been
        compromised or shared with someone else. NoteServe takes no
        responsibility for and disclaims any and all liability for damages
        arising from a breach of confidentiality for any records or information
        resulting from you sharing or losing your password.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        2.4.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Escrow Provider Terms</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        The following terms apply to Users who qualify in connection with their
        use of the Services as Escrow Providers.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        Escrow Providers are required to enter into a Subscription Agreement
        with NoteServe prior to participating in the Services. The terms of the
        Subscription Agreement shall control over in any conflict between the
        Subscription Agreement and these Terms. Please refer to the Subscription
        Agreement for more information on the obligations between Escrow
        Providers and NoteServe.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        When an Escrow Provider provides Contracts to be processed by NoteServe,
        the Escrow Provider must include an executed copy of the Contract,
        including complete and accurate information about the underlying
        transaction with the following:
      </p>
      <ul>
        <li className="mb-4">Parties to the Contract;</li>
        <li className="mb-4">Effective date of Contract;</li>
        <li className="mb-4">Amounts due under Contract;</li>
        <li className="mb-4">Payment dates under Contract</li>
        <li className="mb-4">
          Property or other collateral securing obligations under Contract;
        </li>
        <li className="mb-4">
          Name and contact information for Escrow Provider, including fax and
          telephone; and
        </li>
        <li className="mb-4">Verification that the foregoing is accurate.</li>
      </ul>
      <p className="text-md mt-0 mb-4 ml-2">
        The Escrow Provider is responsible for maintaining physical copies all
        Contracts and related documentation as well as ensuring the Contract and
        all information on the Platform related to it is complete and accurate
        by regularly reviewing the Contract and informing NoteServe if any
        changes to the information are merited. If the Escrow Provider discovers
        in error in the Contract, the Escrow Provider shall immediately correct
        such error in the Platform or, if such Escrow Provider unable to do so,
        notify NoteServe.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        NoteServe may (but does not have any obligation to), in its sole
        discretion, review, edit, and delete any content provided by Escrow
        Provider, including Contracts, in each case in whole or in part, that in
        the sole judgment of NoteServe: (i) violates these Terms; (ii) that
        cannot be serviced by the Platform; or (iii) may be offensive, illegal,
        or threaten the safety of users of the Site or others. Escrow Providers
        are solely responsible for ensuring they have all necessary rights and
        authority to all Contracts, copyrighted material, trademarks, and all
        other materials, including intellectual property rights in such
        materials, to the Platform. Further, Escrow Providers grant a NoteServe
        an irrevocable, fully paid, global, transferable, sublicensable, and
        assignable license to all such materials for the purpose of providing
        the Services.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        Escrow Providers will be given unique access credentials upon entering
        into a Subscription Agreement to allow access to the Platform. The first
        time you log into the Platform, you will be required to create a unique
        username and a unique password. You agree to keep your username and
        password confidential. Anyone with access to your username and password
        will have access information linked to the account, including personally
        identifiable information attached to your Clients. It is your
        responsibility to change your password if you believe it has been
        compromised or shared with someone else. NoteServe takes no
        responsibility for and disclaims any and all liability for damages
        arising from a breach of confidentiality for any records or information
        resulting from you sharing or losing your password.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        Escrow Providers are responsible for complying with all laws, rules,
        regulations, and agreements with third-parties relevant to the Contracts
        or otherwise using the Services. Escrow Providers should review rules
        and laws rules to determine what limitations and requirements may apply.
        Information provided by NoteServe is for informational purposes only.
        Escrow Providers are solely responsible for complying with applicable
        data privacy laws when handling personal data from Customers. If
        questions arise, Escrow Providers should seek independent legal counsel.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        Escrow Providers are responsible and liable for their own acts and
        omissions, as well as any acts and omissions of anyone acting on their
        behalf when using the Platform or otherwise under the Subscription
        Agreement. Escrow Providers are solely responsible for management and
        operation of their business.
      </p>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        Users who are Escrow Providers acknowledge that operating as a escrow
        service provider carries inherent duties to Customers and risks, and
        agree that you assume the entire risk arising out of your access to and
        use of the Services. You agree that you have had sufficient opportunity
        to investigate the Platform, its Services, and applicable laws, rules,
        and regulations and that you are not relying upon any statement of law
        by NoteServe or its Affiliates.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        2.5.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Eligibility to Use the Site and Services</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        By requesting to use, registering to use and/or using the Site or the
        Services, you, as the User, represent and warrant that you have the
        right, authority and capacity to enter into these Terms and you commit
        to abide by all of the terms and conditions hereof. You also represent
        and warrant that you meet the following eligibility criteria:
      </p>
      <ul>
        <li className="mb-4">
          The Site and the Services are currently available only to individuals
          who are legally in the United States. If you reside outside the United
          States, you may not currently use NoteServe, the Sites or the
          Services.
        </li>
        <li className="mb-4">
          You are not a competitor of NoteServe or using our Services for
          reasons that are in competition with NoteServe.
        </li>
        <li className="mb-4">
          Your use of the Services must be in accordance with any and all
          applicable laws and regulations.
        </li>
      </ul>
      <p className="text-md mt-0 mb-4 ml-2">
        We welcome your feedback and questions about the Site and Services.
        However, you agree that any comments, ideas, messages, questions,
        suggestions, or other communications you send to us or share with us
        through any channel (including, without limitation, the Site, email,
        telephone, surveys, and our social media accounts) shall be and remain
        the exclusive property of NoteServe and we may use all such
        communications, all without notice to, consent from, or compensation to
        you.
      </p>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        NoteServe disclaims any liability whatsoever for any misstatements
        and/or misrepresentations made by any Users of the Site. Users hereby
        represent, understand and agree to hold NoteServe harmless for any
        misstatements and/or misrepresentations made by or on behalf of them on
        this Site or in any other venue.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        3.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Prohibited Uses</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        By using the Site or Services of NoteServe, you agree that you will not
        under any circumstances:
      </p>
      <ul>
        <li className="mb-4">
          use the Site, Services, or any information contained therein in any
          way that is abusive, threatening, obscene, defamatory, libelous, or
          racially, sexually, religiously, or otherwise objectionable and
          offensive;
        </li>
        <li className="mb-4">
          use the Site, Platform, or Services for any fraudulent or unlawful
          purpose, for any purpose not expressly intended by NoteServe or for
          the promotion of illegal activities;
        </li>
        <li className="mb-4">
          harass, abuse or harm another person or group, or attempt to do so;
        </li>
        <li className="mb-4">
          provide false or inaccurate information when using the Services or
          communicating with other Users;
        </li>
        <li className="mb-4">
          interfere or attempt to interfere with the proper functioning of the
          Services;
        </li>
        <li className="mb-4">
          make any automated use of the Services, or take any action that we
          deem to impose or to potentially impose an unreasonable or
          disproportionately large load on our servers or network
          infrastructure;
        </li>
        <li className="mb-4">
          bypass any robot exclusion headers or other measures we take to
          restrict access to the Service or use any software, technology, or
          device to scrape, spider, or crawl the Service or harvest or
          manipulate data (whether manually or through automated means);
        </li>
        <li className="mb-4">
          use the communication systems provided by or contacts made through
          NoteServe for any commercial solicitation purposes; or
        </li>
        <li className="mb-4">
          publish or link to malicious content intended to damage or disrupt
          another user's browser or computer.
        </li>
      </ul>
      <p className="text-md mt-0 mb-4">
        Should NoteServe find that you violated the terms of this Section or any
        terms stated herein, NoteServe reserves the right, at its sole
        discretion, to immediately terminate your use of the Site and Services.
        By using the Site and/or Services, you agree that NoteServe may assess,
        and you will be obligated to pay, $10,000 per each day that you misuse
        or misappropriate content available on the Site, including but not
        limited to, use on a "mirrored," competitive, or third-party site. This
        obligation shall be in addition to any other rights NoteServe may have
        under these Terms or applicable law.
      </p>
      <p className="text-md mt-0 mb-4">
        Further, in order to protect the integrity of the Site and the Services,
        NoteServe reserves the right at any time in its sole discretion to block
        Users based on IP addresses or MAC addresses from accessing the Site.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        4.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Termination</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        NoteServe reserves the right, in its sole discretion, to immediately
        terminate your access to all or part of the Site and/or Services, to
        remove any content sourced by or about you from the Site with or without
        notice for any reason or no reason in its sole discretion, including
        without limitation if NoteServe should determine that you are not
        eligible to use the Services, have violated any terms stated herein, are
        not suitable for participation as a User, have misused or
        misappropriated content or any materials from the Site, including but
        not limited to use on a "mirrored," competitive, or third-party site.
        Upon termination, NoteServe shall be under no obligation to provide you
        with a copy of any content posted by or about you on the Site. If we
        terminate your registration, we have no obligation to notify you of the
        reason, if any, for your termination.
      </p>
      <p className="text-md mt-0 mb-4">
        Following any termination of any individual's use of the Site or the
        Services, NoteServe reserves the right to send a notice thereof to other
        Users with whom we believe the individual has corresponded. Our decision
        to terminate an individual's registration and/or to notify other Users
        with whom we believe the individual has corresponded does not
        constitute, and should not be interpreted or used as information bearing
        on, the individual's character, general reputation, personal
        characteristics, or mode of living.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        5.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Privacy</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        NoteServe uses the information you provide on the Site or via the
        Services or in accordance with our Privacy Policy, which is incorporated
        herein.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        6.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Links to External Sites</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        Links from the Site to external sites (including external sites that are
        framed by NoteServe) or inclusion of advertisements and other
        third-party content do not constitute an endorsement of such sites or
        the content, products, advertising and other materials presented on such
        sites or of the products and services that are the subject of such
        third-party content, but are for users' reference and convenience.
      </p>
      <p className="text-md mt-0 mb-4">
        Users access such external sites at their own risk. It is the
        responsibility of the User to evaluate the content and usefulness of the
        information obtained from other sites. NoteServe does not control such
        sites and is not responsible for their content.
      </p>
      <p className="text-md mt-0 mb-4">
        Users further acknowledge that use of any site or content controlled,
        owned or operated by third parties is governed by the terms and
        conditions of use for those sites, and not by NoteServe’s Terms of Use
        and Privacy Policy. NoteServe expressly disclaims any liability derived
        from the use and/or viewing of links that may appear on this Site. All
        users hereby agree to hold NoteServe harmless from any liability that
        may result from the use of links that may appear on the Site.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        7.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Payment and Refund Policy</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        In order to utilize some Services, the User of such Services must pay
        NoteServe as set forth in the applicable terms and conditions, including
        the Additional Terms, as applicable. In addition, the User is
        responsible for any state or local sales taxes associated with the
        Services.
        <span className="text-md w-500">
          It is NoteServe’s policy that all payments for Services are
          non-refundable and there are no refunds or credits for unused or
          partially used services or cancellations, except in NoteServe’s sole
          discretion.
        </span>
      </p>
      <h4 className="h h4 mt-0 mb-4">
        8.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Age Restrictions</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        You must be at least 18 years of age to use the Site and Services.
        Individuals under the age of 18 may only utilize the Site and Services
        with the involvement of a parent or legal guardian and otherwise subject
        to these Terms of Use.
      </p>
      <p className="text-md mt-0 mb-4 w-500">
        If you are under the age of 18 and believe that applicable law permits
        you to use the Sites and Services absent the involvement of a parent or
        legal guardian, please contact us at:
      </p>
      <div className="ml-4">
        <p className="text-md mt-0 mb-1">NoteServe, LLC</p>
        <p className="text-md mt-0 mb-1">
          PO Box 36371, Albuquerque, NM 87176-6371
        </p>
        <p className="text-md mt-0 mb-2">support@noteserve.com</p>
      </div>
      <h4 className="h h4 mt-0 mb-4">
        9.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Disclaimers; Limitations; Waivers; Indemnification
        </span>
      </h4>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        9.1.&nbsp;&nbsp;&nbsp;
        <span className="w-500">No Warranty</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        The Services, information, and materials, including those relating to
        the Services, contained on the Site, including text, graphics,
        information, links or other items are provided "as is." Further,
        opinions, advice, statements, offers, or other information or content
        made available through the Services, but not directly by NoteServe or
        its Affiliates, are those of their respective authors, and should not
        necessarily be relied upon. Such authors are solely responsible for such
        content.
        <span className="text-md w-500">
          NOTESERVE DOES NOT: (1) WARRANT THE ACCURACY, ADEQUACY, OR
          COMPLETENESS OF THIS INFORMATION AND MATERIALS, INCLUDING CONTRACTS;
          (2) ADOPT, ENDORSE OR ACCEPT RESPONSIBILITY FOR THE ACCURACY OR
          RELIABILITY OF ANY OPINION, ADVICE, OR STATEMENT MADE BY ANY PARTY
          OTHER THAN NOTESERVE, INCLUDING FROM ESCROW PROVIDERS; (3) WARRANT
          THAT YOUR USE OF THE SITE OR SERVICES WILL BE SECURE, FREE FROM
          COMPUTER VIRUSES, UNINTERRUPTED, ALWAYS AVAILABLE, ERROR-FREE OR WILL
          MEET YOUR REQUIREMENTS, OR THAT ANY DEFECTS IN THE SERVICES WILL BE
          CORRECTED; OR (4) GIVE ANY WARRANTIES OF FITNESS FOR A PARTICULAR
          PURPOSE, MERCHANTABILITY, OR NON-INFRINGEMENT OF THIRD-PARTY RIGHTS
          RELATING TO THE SERVICES, SITE, OR SERVICES. TO THE EXTENT PERMITTED
          BY APPLICABLE LAW, NOTESERVE EXPRESSLY EXCLUDES ALL CONDITIONS,
          WARRANTIES AND OTHER TERMS WHICH MIGHT OTHERWISE BE IMPLIED BY
          STATUTE, COMMON LAW OR THE LAW OF EQUITY AND DISCLAIMS LIABILITY FOR
          ERRORS OR OMISSIONS IN THIS INFORMATION AND MATERIALS.
        </span>
      </p>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        IN ADDITION, AND WITHOUT LIMITING THE FOREGOING, NOTESERVE MAKES NO
        REPRESENTATION OR WARRANTIES OF ANY KIND WHETHER EXPRESS OR IMPLIED
        REGARDING THE SUITABILITY OF ANY USER OF OUR SITE TO PROVIDE SERVICES AS
        AN ESCROW PROVIDER. NOTESERVE IS NOT AN EMPLOYER OF ESCROW PROVIDERS,
        INCLUDING ESCROW PROVIDERS NOR DOES IT ENDORSE THE SERVICES OF ANY
        ESCROW PROVIDER, INCLUDING ANY ESCROW PROVIDER THAT IS A USER.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        9.2.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Assumption of Risk</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        You assume all risk when using the Site and the Services, including but
        not limited to all of the risks associated with any online or offline
        interactions with users of the Site or the Services.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        9.3.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Limitation of Liability</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        n no event will NoteServe be liable for any indirect, special,
        incidental, or consequential damages, losses or expenses arising out of
        or relating to the use or inability to use the Site or Services,
        including without limitation damages related to any information received
        from the Site or Services, removal of content from the Site, including
        profile information, any email distributed to any User or any linked web
        site or use thereof or inability to use by any party, or in connection
        with any termination of your subscription or ability to access the Site
        or Services, failure of performance, error, omission, interruption,
        defect, delay in operation or transmission, computer virus or line or
        system failure, even if NoteServe, its Affiliates, or representatives
        thereof, are advised of the possibility of such damages, losses or
        expenses.{" "}
        <span className="text-md w-500">
          UNDER NO CIRCUMSTANCES WILL NOTESERVE’S AGGREGATE LIABILITY, IN ANY
          FORM OF ACTION WHATSOEVER IN CONNECTION WITH THIS AGREEMENT OR THE USE
          OF THE SERVICES OR THE SITE, EXCEED THE AMOUNTS RECEIVED BY NOTESERVE
          IN CONNECTION WITH ANY SERVICES OBTAINED THROUGH THE SITE, OR, IF YOU
          HAVE NOT PAID NOTESERVE ANY AMOUNTS, THE AMOUNT OF $25.00.
        </span>
      </p>
      <p className="text-md mt-0 mb-4 ml-2 w-500">
        IN NO EVENT WILL NOTESERVE BE LIABLE FOR ANY DAMAGES WHATSOEVER, WHETHER
        DIRECT, INDIRECT, GENERAL, SPECIAL, COMPENSATORY, AND/OR CONSEQUENTIAL,
        ARISING OUT OF OR RELATING TO THE CONDUCT OF YOU OR ANYONE ELSE IN
        CONNECTION WITH THE USE OF THE SITE OR THE SERVICES OR ANY AGREEMENT OR
        RELATIONSHIP FORMED USING THE SITE OR SERVICE, INCLUDING WITHOUT
        LIMITATION, BODILY INJURY, EMOTIONAL DISTRESS, AND/OR ANY OTHER DAMAGES
        RESULTING FROM ANYONE'S RELIANCE ON INFORMATION OR OTHER CONTENT POSTED
        ON THE SITE, OR TRANSMITTED TO OR BY ANY USERS OR ANY OTHER INTERACTIONS
        WITH OTHER USERS OF THE SITE OR SERVICES, WHETHER ONLINE OR OFFLINE.
        THIS INCLUDES ANY CLAIMS, LOSSES OR DAMAGES ARISING FROM THE CONDUCT OF
        USERS WHO HAVE REGISTERED UNDER FALSE PRETENSES OR WHO ATTEMPT TO
        DEFRAUD OR HARM YOU.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        9.4.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Release of Liability for Conduct and Disputes
        </span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        By using this Site or our Services, you hereby represent, understand,
        and expressly agree to hold NoteServe harmless for any claim or
        controversy that may arise out of the actions of or relationship between
        you and any other Users of the Site, including without limit your
        relationship as Customer to an Escrow Provider. You agree to take
        reasonable precautions in all interactions with other Users of the Site
        or the Services, particularly if you decide to meet offline. By using
        the Site or the Services, you agree to report any alleged actions in
        violation of these Terms by any Users to NoteServe immediately by
        notifying NoteServe of the same via electronic correspondence as
        described below.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        9.5.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Indemnification</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        By agreeing to these Terms, users of the Site and Services agree to
        indemnify, defend and hold harmless NoteServe and its Affiliates from
        and against any and all claims, losses, expenses or demands of
        liability, including reasonable attorneys' fees and costs incurred by
        NoteServe and its Affiliates in connection with any claim by a
        third-party (including an intellectual property claim) arising out of:
        (i) materials and content you submit, post or transmit through the Site,
        including any third party claims in connection with{" "}
        <span className="underline">Section 10</span>; (ii) use of the Site or
        Services by you in violation of these Terms of Use or in violation of
        any applicable law; (iii) any relationship or agreement formed with
        another User using the Site or Services; or (iv) any act or omission by
        a User using the Site or Services, negligent or otherwise. Further,
        Escrow Providers agree to indemnify, defend and hold harmless NoteServe
        and its Affiliates from and against any and all claims, losses, expenses
        or demands of liability, including reasonable attorneys' fees and costs
        incurred by NoteServe and its Affiliates in connection with any claim by
        a third-party (including an intellectual property claim) arising out of:
        (x) the NoteServe’s failure to verify Contract information in a timely
        manner; and (y) any use of Contract information other than directly in
        relation to the Services. Users further agree that they will cooperate
        as reasonably required in the defense of such claims. NoteServe and its
        Affiliates reserve the right, at their own expense, to assume the
        exclusive defense and control of any matter otherwise subject to
        indemnification by Users, and Users shall not, in any event, settle any
        claim or matter assigning any liability to NoteServe without the written
        consent of NoteServe. Any such assignment made absent NoteServe’s
        express written approval shall be null and void. Users further agree to
        hold harmless NoteServe and its Affiliates from any claim arising from a
        third-party's use of information or materials of any kind posted to the
        Site, including Contracts.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        10.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Copyright Notices/Complaints</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        It is NoteServe’s policy to respond to notices of alleged copyright
        infringement with the Digital Millennium Copyright Act ("
        <span className="underline">DMCA</span>"). If you believe any materials
        accessible on or from our Site infringe your copyright, you may request
        removal of those materials (or access thereto) from the Site by
        contacting our copyright agent (identified below) and providing the
        following information:
      </p>
      <ul>
        <li className="mb-4">
          Identification of the copyrighted work that you believe to be
          infringed. Please describe the work, and where possible include a copy
          or the location (e.g., URL) of an authorized version of the work.
        </li>
        <li className="mb-4">
          Identification of the material that you believe to be infringing and
          its location. Please describe the material and provide us with its URL
          or any other pertinent information that will allow us to locate the
          material.
        </li>
        <li className="mb-4">
          Your name, address, telephone number and (if available) e-mail
          address.
        </li>
        <li className="mb-4">
          A statement that you have a good faith belief that the complained of
          use of the materials is not authorized by the copyright owner, its
          agent, or the law.
        </li>
        <li className="mb-4">
          A statement that the information that you have supplied is accurate,
          and indicating that "under penalty of perjury," you are the copyright
          owner or are authorized to act on the copyright owner's behalf.
        </li>
        <li className="mb-4">
          A signature or the electronic equivalent from the copyright holder or
          authorized representative.
        </li>
      </ul>
      <div className="my-4">
        NoteServe’s agent for copyright issues relating to this Site is as
        follows:
      </div>
      <div className="ml-4">
        <p className="text-md mt-0 mb-1">c/o: Copyright Agent</p>
        <p className="text-md mt-0 mb-1">NoteServe, LLC</p>
        <p className="text-md mt-0 mb-0">
          PO Box 36371, Albuquerque, NM 87176-6371
        </p>
        <a href="mailto:support@noteserve.com" className="underline">
          support@noteserve.com
        </a>
      </div>
      <p className="text-md mt-0 mb-4 mt-4">
        In an effort to protect the rights of copyright owners, NoteServe
        maintains a policy for the termination, in appropriate circumstances, of
        Users of this Site who are repeat infringers.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        11.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Agreement to Arbitrate</span>
      </h4>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.1.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Agreement to Arbitrate</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        This <span className="underline">Section 11</span> is referred to in
        these Terms as the "Arbitration Agreement." Unless you opt-out in
        accordance with the opt-out procedures set forth in{" "}
        <span className="underline">Section 11.7</span> below, you agree that
        all claims relating to or arising out of these Terms or the breach
        thereof, whether sounding in contract, tort, or otherwise that have
        arisen or may arise between you and NoteServe or its Affiliates, whether
        relating to these Terms (including any alleged breach thereof), the
        Services, the Site, or otherwise (“
        <span className="underline">Claims</span>”), shall be resolved
        exclusively through final and binding arbitration, rather than a court,
        in accordance with the terms of this Arbitration Agreement, except you
        may assert individual claims in small claims court, if your claims
        qualify. Your rights will be determined by a neutral arbitrator, not a
        judge or jury. The Federal Arbitration Act governs the interpretation
        and enforcement of this Arbitration Agreement.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.2.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Prohibition of Class and Representative Actions and Non-Individualized
          Relief
        </span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        <span className="text-md w-500">
          YOU AND NOTESERVE AGREE THAT EACH PARTY MAY BRING CLAIMS AGAINST THE
          OTHER ONLY ON AN INDIVIDUAL BASIS AND NOT AS A PLAINTIFF OR CLASS
          MEMBER IN ANY PURPORTED CLASS OR REPRESENTATIVE ARBITRATION, ACTION OR
          PROCEEDING. UNLESS BOTH YOU AND NOTESERVE EXPRESSLY AGREE OTHERWISE,
          THE ARBITRATOR MAY NOT CONSOLIDATE OR JOIN MORE THAN ONE PERSON'S OR
          PARTY'S CLAIMS AGAINST NOTESERVE AND MAY NOT OTHERWISE PRESIDE OVER
          ANY FORM OF A CONSOLIDATED, REPRESENTATIVE, OR CLASS PROCEEDING. ALSO,
          THE ARBITRATOR MAY AWARD RELIEF (INCLUDING MONETARY, INJUNCTIVE, AND
          DECLARATORY RELIEF) ONLY IN FAVOR OF THE INDIVIDUAL PARTY SEEKING
          RELIEF AND ONLY TO THE EXTENT NECESSARY TO PROVIDE RELIEF NECESSITATED
          BY THAT PARTY'S INDIVIDUAL CLAIM(S). ANY RELIEF AWARDED CANNOT AFFECT
          OTHER NOTESERVE USERS.
        </span>{" "}
        If any court or arbitrator determines that the class action waiver set
        forth in this paragraph is void or unenforceable for any reason or that
        an arbitration can proceed on a class basis, then this{" "}
        <span className="underline">Section 11.2</span> shall be deemed null and
        void.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.3.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Pre-Arbitration Dispute Resolution</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        NoteServe is always interested in resolving disputes amicably and
        efficiently. Therefore, before you commence arbitration, we suggest that
        you contact us to explain your complaint, as we may be able to resolve
        it without the need for arbitration. You may contact us via email at
        support@noteserve.com.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.4.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Arbitration Procedures</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        If we cannot resolve a Claim informally, any Claim either of us asserts
        will be resolved only by binding arbitration and not in courts of
        general jurisdiction.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        For information on JAMS, please visit its website,&nbsp;
        <a
          href="https://www.jamsadr.com/"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          https://www.jamsadr.com/
        </a>
        . Information about JAMS's Rules and fees for consumer disputes can be
        found at the JAMS consumer arbitration page, &nbsp;
        <a
          href="https://www.jamsadr.com/rules-comprehensive-arbitration/"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          https://www.jamsadr.com/rules-comprehensive-arbitration/
        </a>
        . If there is any inconsistency between the JAMS Rules and this
        Arbitration Agreement, the terms of this Arbitration Agreement will
        control unless the arbitrator determines that the application of the
        inconsistent Arbitration Agreement terms would not result in a
        fundamentally fair arbitration. The arbitrator must also follow the
        provisions of these Terms as a court would, including without
        limitation, the limitation of liability provisions in Section 9.
        Although arbitration proceedings are usually simpler and more
        streamlined than trials and other judicial proceedings, the arbitrator
        can award the same damages and relief on an individual basis that a
        court can award to an individual under the Terms and applicable law.
        Decisions by the arbitrator are enforceable in court and may be
        overturned by a court only for very limited reasons.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        To commence an arbitration against NoteServe, you must write a demand
        for arbitration that includes a description of the dispute and the
        amount of damages sought to be recovered. You can find a copy of a
        Demand for Arbitration at &nbsp;
        <a
          href="https://www.jamsadr.com/"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          www.jamsadr.com
        </a>
        . You should send three copies of the Demand, plus the appropriate
        filing fee, to JAMS at 500 North State College Blvd., Suite 600, Orange,
        CA 92868, and send one copy to NoteServe at support@noteserve.com. For
        more information, see the JAMS arbitration rules and forms, &nbsp;
        <a
          href="https://www.jamsadr.com/rules-download/"
          target="_blank"
          rel="noreferrer"
          className="underline"
        >
          https://www.jamsadr.com/rules-download/
        </a>
        . You may represent yourself in the arbitration or be represented by an
        attorney or another representative. Once we receive your arbitration
        claim, we may assert any counterclaims we may have against you.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        The arbitration shall be held in the county in which you reside or at
        another mutually agreed location. If the value of the relief sought is
        $10,000 or less, you or NoteServe may elect to have the arbitration
        conducted by telephone or based solely on written submissions, which
        election shall be binding on you and NoteServe subject to the
        arbitrator's discretion to require an in-person hearing, if the
        circumstances warrant. Attendance at any in-person hearing may be made
        by telephone by you and/or NoteServe, unless the arbitrator requires
        otherwise.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        The arbitrator, and not any federal, state or local court or agency,
        shall have exclusive authority to resolve all claims relating to or
        arising out of this contract, or the breach thereof, whether sounding in
        contract, tort, or otherwise and all disputes arising out of or relating
        to the interpretation, applicability, enforceability or formation of
        these Terms, including, but not limited to any claim that all or any
        part of these Terms are void or voidable, or whether a claim is subject
        to arbitration. The arbitrator shall be empowered to grant whatever
        relief would be available in a court under law or in equity. The
        arbitrator's award shall be written, and binding on the parties and may
        be entered as a judgment in any court of competent jurisdiction.
      </p>
      <p className="text-md mt-0 mb-4 ml-2">
        The arbitrator will decide the substance of all claims in accordance
        with the laws of the State of New Mexico, including recognized
        principles of equity, and will honor all claims of privilege recognized
        by law. The arbitrator shall not be bound by rulings in prior
        arbitrations involving different Users but is bound by rulings in prior
        arbitrations involving the same User to the extent required by
        applicable law.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.5.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Costs of Arbitration</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        Payment of all filing, administration, and arbitrator fees
        (collectively, the "<span className="underline">Arbitration Fees</span>
        ") will be governed by the JAMS Rules, unless otherwise provided in this
        Agreement to Arbitrate. If you demonstrate to the arbitrator that you
        are economically unable to pay your portion of the Arbitration Fees or
        if the arbitrator otherwise determines for any reason that you should
        not be required to pay your portion of the Arbitration Fees, NoteServe
        will pay your portion of such fees. In addition, if you demonstrate to
        the arbitrator that the costs of arbitration will be prohibitive as
        compared to the costs of litigation, NoteServe will pay as much of the
        Arbitration Fees as the arbitrator deems necessary to prevent the
        arbitration from being cost-prohibitive. Each party will be responsible
        for all other fees it incurs in connection with the arbitration,
        including without limitation, all attorney fees. In the event the
        arbitrator determines the claim(s) you assert in the arbitration to be
        frivolous, you agree to reimburse NoteServe for all fees associated with
        the arbitration paid by NoteServe on your behalf that you otherwise
        would be obligated to pay under the JAMS rules.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.6.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Confidentiality</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        All aspects of the arbitration proceeding, and any ruling, decision or
        award by the arbitrator, will be strictly confidential for the benefit
        of all parties.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.7.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Opt-Out Procedure</span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        You can choose to reject this Arbitration Agreement by mailing us a
        written opt-out notice ("
        <span className="underline">Opt-Out Notice</span>") in accordance with
        the terms of this Section. For new Users, the Opt-Out Notice must be
        postmarked no later than 30 Days after the date you use our Site or
        Services for the first time. You must mail the Opt-Out Notice to:
      </p>
      <div className="ml-4">
        <p className="text-md mt-0 mb-1">NoteServe, LLC</p>
        <p className="text-md mt-0 mb-1">
          PO Box 36371, Albuquerque, NM 87176-6371
        </p>
        <p className="text-md mt-0 mb-4">support@noteserve.com</p>
      </div>
      <p className="text-md mt-0 mb-4 ml-2">
        Notice must state that you do not agree to the Arbitration Agreement and
        must include your name, address, phone number, and the email address(es)
        associated with the Site and Services to which the opt-out applies. You
        must sign the Opt-Out Notice for it to be effective. This procedure is
        the only way you can opt out of the Arbitration Agreement. If you opt
        out of the Arbitration Agreement, NoteServe will likewise not be bound
        by these arbitration provisions. All other terms of these Terms will
        continue to apply. Opting out of the Arbitration Agreement has no effect
        on any previous, other, or future arbitration agreements that you may
        have with us. Users who accepted a previous version of these Terms that
        included an arbitration agreement, and who did not timely opt out of
        that arbitration agreement, remain bound by the last arbitration
        agreement that they accepted. Upon receipt of a valid Opt-Out Notice,
        NoteServe will provide the opting out User with a copy of the
        arbitration agreement from the last version of the Terms that the User
        accepted, if any exists.
      </p>
      <h5 className="h h5 mt-0 mb-4 ml-2">
        11.8.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Future Changes to this Arbitration Agreement
        </span>
      </h5>
      <p className="text-md mt-0 mb-4 ml-2">
        Notwithstanding any provision in these Terms to the contrary, you and we
        agree that if we make any change to this Arbitration Agreement (other
        than a change to any notice address or website link provided herein) in
        the future, such change shall not be effective until at least 60 days
        from the date of posting, and shall not apply to any claim that was
        filed in a legal proceeding against NoteServe prior to the effective
        date of the change. Moreover, if we seek to terminate this Arbitration
        Agreement from these Terms, such termination shall not be effective
        until 30 days after the version of these Terms not containing the
        Arbitration Agreement is posted to the Site, and shall not be effective
        as to any claim that was filed in a legal proceeding against NoteServe
        prior to the effective date of removal.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        12.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Governing Law and Jurisdiction</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        These Terms, and all claims relating to or arising out of this contract,
        or the breach thereof, whether sounding in contract, tort, or otherwise,
        shall be governed by the laws of the State of New Mexico including New
        Mexico’s statutes of limitations governing your claim, without giving
        effect to its principles of conflicts of law, provided that the Federal
        Arbitration Act shall govern the interpretation and enforcement of{" "}
        <span className="underline">Section 11</span>, the Arbitration
        Agreement.
      </p>
      <p>
        Unless you and we agree otherwise, in the event that the Arbitration
        Agreement is found not to apply to you or to a particular claim or
        dispute (except for small-claims court actions), either as a result of
        your decision to opt-out of the Arbitration Agreement or as a result of
        a decision by the arbitrator or a court order, you agree that any claim
        or dispute that has arisen or may arise between you and NoteServe must
        be resolved exclusively by a state or federal court located in the State
        of New Mexico. You and NoteServe agree to submit to the personal
        jurisdiction of the courts located within the State of New Mexico for
        the purpose of litigating all such claims or disputes.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        13.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Consent to Electronic Communication</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        By using the Site or Services, you agree to allow NoteServe and its
        Affiliates to communicate with you electronically, and you consent to
        electronic delivery of notices, documents, or products from NoteServe
        via email, telephone call, or text message. You undertake to notify
        NoteServe in the event you change your email address or phone number.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        14.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Miscellaneous</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        Nothing in this Agreement shall be construed as making either party the
        partner, joint venturer, agent, legal representative, employer,
        contractor, or employee of the other. Neither party shall have, or hold
        itself out to any third-party as having any authority to make any
        statements, representations or commitments of any kind, or to take any
        action, that shall be binding on the other, except as provided for
        herein or authorized in writing by the party to be bound. The
        invalidity, illegality or unenforceability of any term or provision of
        these Terms shall in no way effect the validity, legality or
        enforceability of any other term or provision of these Terms. Each
        Affiliate is expressly made a third-party beneficiary of this Agreement
        and may enforce this Agreement directly against you. This Agreement will
        be binding on and will inure to the benefit of the legal
        representatives, successors and assigns of the parties hereto.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        15.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Severability</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        If a court decides that any term or provision of these Terms other than{" "}
        <span className="underline">Section 11.2</span> is invalid or
        unenforceable, the parties agree to replace such term or provision with
        a term or provision that is valid and enforceable and that comes closest
        to expressing the intention of the invalid or unenforceable term or
        provision, and these Terms shall be enforceable as so modified. The
        remainder of the Terms will continue to apply.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        16.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Contact Information</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        If you have any questions or need further information as to the Site or
        Services provided by NoteServe, or need to notify NoteServe as to any
        matters relating to the Site or Services, please contact us at:
      </p>
      <div className="ml-4">
        <p className="text-md mt-0 mb-1">NoteServe, LLC</p>
        <p className="text-md mt-0 mb-1">
          PO Box 36371, Albuquerque, NM 87176-6371
        </p>
        <p className="text-md mt-0 mb-4">support@noteserve.com</p>
      </div>
    </Row>
  );
};
