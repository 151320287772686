import {
  COLOR_WHITE,
  FONT_FAMILY,
  PRIMARY_6_COLOR,
  RED_6_COLOR,
} from "../lib/constants";

export const themeConfig = {
  token: {
    colorPrimary: PRIMARY_6_COLOR,
    fontFamily: FONT_FAMILY,
  },
  components: {
    Alert: {
      borderRadiusLG: 4,
      colorErrorBorder: RED_6_COLOR,
    },
    Card: {
      borderRadiusLG: 4,
      paddingLG: 32,
    },
    Tooltip: {
      colorBgDefault: COLOR_WHITE,
    },
  },
};
