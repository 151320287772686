import AuthService from "../../services/auth";
import { useGetRole } from "./getRole";

export const useRedirectToMain = (navigate: (value: string) => void) => {
  const [role] = useGetRole();

  const redirectToMain = (): void => {
    const isLoggedIn = AuthService.isLoggedIn();

    let path: string;
    if (isLoggedIn && role) {
      path = role === "User" ? "/accounts" : "/home";
    } else {
      path = "/login";
    }

    navigate(path);
  };

  return [redirectToMain];
};
