import { useLocation } from "react-router-dom";

type propState = {
  email?: string;
};

export const useInitialDataFromLocation = () => {
  const location = useLocation();

  let initialData = {};
  if (location.state) {
    const { email } = location.state as propState;
    initialData = { username: email };
  }

  return { initialData };
};
