import { useEffect, useState } from "react";
import { update } from "../../../acions/userDataCheck";
import { getFormatted } from "../../../lib/PhoneFormatter";
import UserDataCheckService from "../../../services/userDataCheck";
import { useAppDispatch } from "../../../store";
import { NavigateFunction, useNavigate } from "react-router-dom";

export const useUserDataCheck = () => {
  const dispatch = useAppDispatch();

  const navigate: NavigateFunction = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [isFormDisabled, setIsFormDisabled] = useState(false);

  const [data, setData] = useState<any>({});

  const [phone, setPhone] = useState<string | null>(null);

  useEffect(() => {
    const { phone } = data;
    setPhone(phone ? getFormatted(phone).slice(2) : null);
  }, [data]);

  useEffect(() => {
    UserDataCheckService.get()
      .then((res) => {
        setData(res);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const updateData = (values: any): void => {
    setIsFormDisabled(true);

    const request = {
      ...values,
      newPassword: values.password,
      phone: `1${values.phone.replace(/[\s-()]/g, "")}`,
    };

    if (!data.isUsernameChangeRequired) {
      delete request.newUsername;
    }

    Promise.resolve(dispatch(update(request)))
      .then((res: any) => {
        setIsFormDisabled(false);

        navigate(res.role && res.role === "User" ? "/accounts" : "/home");
      })
      .catch(() => setIsFormDisabled(false));
  };

  return { data, isLoading, phone, isFormDisabled, updateData };
};
