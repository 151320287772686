import { Row, Col, Button } from "antd";
import image from "../../images/success-register.svg";
import "./styles.scss";

export const SuccessSignUp = () => {
  return (
    <>
      <Row className="mt-16 success-register__container justify-center px-4">
        <Col>
          <Row>
            <Col>
              <div className="mb-10">
                <h1 className="header my-0">Your login has been created</h1>
              </div>
              <div>
                <p className="mb-6 h h5 normal ls-5">
                  Thank you for creating an account with us!
                </p>
                <p className="mb-6 h h5 ls-5">
                  Within 24-48 hours you will be activated.
                </p>
                <p className="mb-6 h h5 normal ls-5">
                  Once your entered data is validated and activated, you will
                  receive an email notification with further instructions.
                </p>
                <p className="mb-12 h h5 normal ls-5">
                  If you have any urgent questions or concerns, please contact
                  us&nbsp;
                  <Button
                    className="underline px-0 py-0"
                    type="link"
                    href="mailto:info@sunwestescrow.com"
                    style={{ fontSize: "16px" }}
                  >
                    info@sunwestescrow.com
                  </Button>
                  .
                </p>
                <p className="h h5 normal ls-5">Thank you.</p>
                <div className="success-img__container">
                  <img className="w-100" src={image} alt="Register success" />
                </div>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
