import { Input, InputRef } from "antd";
import { CloseCircleFilled, SearchOutlined } from "@ant-design/icons";
import "./styles.scss";
import { useEffect, useRef } from "react";

type SearchWithClearProps = {
  isLoading: boolean;
  search?: string;
  onSearchChange: (e: any) => void;
  onClear: () => void;
};

export const SearchWithClear = ({
  isLoading,
  search,
  onSearchChange,
  onClear,
}: SearchWithClearProps) => {
  const ref = useRef<InputRef>(null);

  useEffect(() => {
    if (!isLoading) {
      ref.current?.focus();
    }
  }, [isLoading]);

  return (
    <Input
      className="search-users-input"
      placeholder="Search"
      prefix={<SearchOutlined className="search-icon" />}
      onChange={onSearchChange}
      disabled={isLoading}
      value={search}
      ref={ref}
      suffix={
        search !== "" ? (
          <CloseCircleFilled className="search-icon" onClick={onClear} />
        ) : (
          <div />
        )
      }
    />
  );
};
