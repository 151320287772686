import { useMemo } from "react";

export const useGetFormattedPhoneSecure = (data: any) => {
  const formattedPhone = useMemo(() => {
    return data.phone ? (
      <span>
        {`+1 ${data.phone[1]}`}
        &#x2022;&#x2022;&ensp;&#x2022;&#x2022;&#x2022;&ensp;&#x2022;
        {`${data.phone[11] + data.phone[12] + data.phone[13]}`}
      </span>
    ) : (
      ""
    );
  }, [data]);

  return [formattedPhone];
};
