import { ExclamationCircleFilled } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useGetRole } from "../hooks/getRole";
import { App } from "antd";
import { useAppDispatch } from "../../store";
import { deleteUserData, logout, refresh } from "../../acions/auth";
import { setMessage } from "../../acions/message";
import { LOGOUT_REASON } from "../constants";

const START_COUNTER = 59;

const ONE_SECOND = 1000;

const ONE_MINUTE = 60 * 1000;

export const LogoutModal = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, _isAdmin, data] = useGetRole();

  const dispatch = useAppDispatch();

  const { modal } = App.useApp();

  const [modalInstance, setModalInstance] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (modalInstance !== null) {
      let counter = START_COUNTER;
      const interval = setInterval(() => {
        counter--;
        modalInstance.update({
          title: `Your session expires in 0:${
            counter > 9 ? counter : "0" + counter
          }`,
        });

        if (counter === 0) {
          clearInterval(interval);
          modalInstance.destroy();
          Promise.resolve(dispatch(logout()))
            .then(() =>
              dispatch(setMessage({ type: "info", message: LOGOUT_REASON }))
            )
            .catch((err) => {
              if (err.response.status === 401) {
                dispatch(deleteUserData());
                dispatch(setMessage({ type: "info", message: LOGOUT_REASON }));
              }
            });
        }
      }, ONE_SECOND);

      return () => {
        clearInterval(interval);
        modalInstance.destroy();
      };
    }
  }, [modalInstance, dispatch]);

  useEffect(() => {
    const timeToShowModal = data.exp * 1000 - Date.now() - ONE_MINUTE;

    const timer = setTimeout(() => {
      const instanse = modal.confirm({
        icon: <ExclamationCircleFilled />,
        title: `Your session expires in 0:59`,
        content: (
          <div className="text-md mb-1">
            Would you like to stay logged in for 1 more hour?
          </div>
        ),
        okText: "Stay logged in",
        okType: "primary",
        okButtonProps: {
          shape: "round",
          size: "small",
          disabled: isLoading,
        },
        cancelButtonProps: {
          shape: "round",
          size: "small",
          disabled: isLoading,
        },
        cancelText: "Log out",
        onCancel: () => {
          setIsLoading(true);

          return Promise.resolve(dispatch(logout()))
            .then(() => {
              clearTimeout(timer);
              instanse.destroy();
              setIsLoading(false);
            })
            .catch((err) => {
              clearTimeout(timer);
              setIsLoading(false);
              instanse.destroy();
              if (err.response.status === 401) {
                dispatch(deleteUserData());
              }
              setIsLoading(false);
            });
        },
        onOk: () => {
          setIsLoading(true);

          return Promise.resolve(dispatch(refresh()))
            .then(() => {
              clearTimeout(timer);
              instanse.destroy();
              setIsLoading(false);
            })
            .catch((err) => {
              clearTimeout(timer);
              instanse.destroy();
              setIsLoading(false);

              if (err.response.status === 401) {
                dispatch(deleteUserData());
              }
            });
        },
      });

      setModalInstance(instanse);
    }, timeToShowModal);

    return () => clearTimeout(timer);
  }, [data, modal, dispatch, isLoading]);

  return <></>;
};
