import "./styles.scss";
import { Row, Button } from "antd";
import logo from "../../../images/logo.svg";
import { UserOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useState } from "react";
import { HelpModal } from "../../HelpModal";
import { logout } from "../../../acions/auth";
import { useAppDispatch } from "../../../store";
import { useNavigate, NavigateFunction } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { useLocation, Location } from "react-router-dom";
import { useRedirectToMain } from "../../../lib/hooks/redirectToMain";
import { useGetRole } from "../../../lib/hooks/getRole";
import { NavItems } from "./NavItems";
import { MobileMenu } from "./MobileMenu";

export const Header = () => {
  const dispatch = useAppDispatch();
  const navigate: NavigateFunction = useNavigate();
  const location: Location = useLocation();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const user: any = useSelector((state: any) => state.auth.user);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [redirectToMain] = useRedirectToMain(navigate);

  const onLogoutClicked = () => {
    Promise.resolve(dispatch(logout()))
      .then(() => {
        navigate("/login");
      })
      .catch(() => {});
  };

  const onProfileClicked = () => {
    location.pathname !== "/profile" && navigate("/profile");
  };

  return (
    <>
      <Row className="justify-space-between logged-header__container">
        <div className="d-flex">
          <img
            src={logo}
            alt="Sunwest Escrow"
            className="logo logo-cursor py-5 mr-8"
            onClick={redirectToMain}
          />
          {isAdmin && <NavItems location={location} navigate={navigate} />}
        </div>
        <div className="d-flex">
          <Button
            type="text"
            className={`header-button desktop-btn  align-center ${
              location.pathname === "/profile" && "active"
            }`}
            onClick={onProfileClicked}
          >
            <UserOutlined className="mr-1" />
            <span className="user-header-label elipsis">{`${user.firstName} ${user.lastName}`}</span>
          </Button>
          <Button
            type="text"
            className="header-button"
            onClick={() => setIsModalOpen(true)}
          >
            Help
          </Button>
          <Button
            type="text"
            className="header-button desktop-btn"
            onClick={onLogoutClicked}
          >
            Log out
          </Button>
          {!isAdmin && (
            <MobileMenu
              onLogoutClicked={onLogoutClicked}
              onProfileClicked={onProfileClicked}
            />
          )}
        </div>

        <HelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
      </Row>

      <Outlet />
    </>
  );
};
