import { useMemo } from "react";
import { useLocation, Location } from "react-router-dom";

type propState = {
  status?: number;
  dateFilter?: number;
};

export const useInitialStates = () => {
  const location: Location = useLocation();

  const statusesInitialState: (string | number)[] = useMemo(() => {
    if (location.state) {
      const { status } = location.state as propState;
      return [status || 0];
    }
    return ["All"];
  }, [location]);

  const lastPaymnetDateInitialState: (string | number)[] = useMemo(() => {
    if (location.state) {
      const { dateFilter } = location.state as propState;
      return [dateFilter || "All"];
    }
    return ["All"];
  }, [location]);

  const requsetInitialState: any = useMemo(() => {
    const value: any = {
      pageSize: 20,
      pageNumber: 1,
    };

    if (location.pathname === "/other-accounts") {
      value.isOther = true;
    }

    if (statusesInitialState[0] !== "All") {
      value.statusFilter = statusesInitialState;
    }

    if (lastPaymnetDateInitialState[0] !== "All") {
      value.dateFilter = lastPaymnetDateInitialState[0];
    }

    return value;
  }, [statusesInitialState, lastPaymnetDateInitialState, location]);

  return {
    requsetInitialState,
    lastPaymnetDateInitialState,
    statusesInitialState,
  };
};
