import { Button, Col, Form, Input, Row } from "antd";
import { useState } from "react";
import {
  CapsLockHint,
  useToggleCapsLock,
} from "../../../components/CapsLockHint";
import {
  ENTER_EMAIL_OR_USERNAME,
  ENTER_PASSWORD,
  MAX_EMAIL_USERNAME,
  MAX_PASSWORD,
} from "../../../lib/constants";
import { LoadingOutlined } from "@ant-design/icons";
import { UsefulLinks } from "../UsefulLinks";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";

type LoginFormProps = {
  data: any;
  onLoginButtonClicked: (value: any) => Promise<any>;
};

export const LoginForm = ({ data, onLoginButtonClicked }: LoginFormProps) => {
  const [form] = Form.useForm();

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const [validateOnBlur] = useValidateOnBlur(form);

  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);

  const [isCapsLockOn, onPasswordType] = useToggleCapsLock(false);

  const onLogIn = async (values: any) => {
    try {
      setIsFormDisabled(true);
      await onLoginButtonClicked(values);
      setIsFormDisabled(false);
    } catch (err: any) {
      setIsFormDisabled(false);
    }
  };

  return (
    <>
      <Row>
        <Col span={12} offset={6}>
          <div className="greeting__container d-flex mt-16 justify-space-around mb-10">
            <h1 className="header">Welcome to Sunwest</h1>
          </div>
        </Col>
      </Row>
      <Row className="justify-center mx-4">
        <Col className="login-form">
          <Row className="d-flex justify-center">
            <Col className="form__container">
              <Form
                form={form}
                layout="vertical"
                onFinish={onLogIn}
                autoComplete="off"
                initialValues={data}
                requiredMark={false}
                disabled={isFormDisabled}
              >
                <Form.Item
                  name="username"
                  label="Email or Username"
                  className="mb-5"
                  rules={[
                    {
                      required: true,
                      message: ENTER_EMAIL_OR_USERNAME,
                      validateTrigger: "onSubmit",
                    },
                    {
                      max: 100,
                      message: MAX_EMAIL_USERNAME,
                      validateTrigger: "onSubmit",
                    },
                  ]}
                >
                  <Input
                    size="large"
                    onBlur={(e) => validateOnBlur(e, "username")}
                  />
                </Form.Item>
                <div className="input__container mb-8">
                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: ENTER_PASSWORD,
                        validateTrigger: "onSubmit",
                      },
                      {
                        max: 30,
                        message: MAX_PASSWORD,
                        validateTrigger: "onSubmit",
                      },
                    ]}
                  >
                    <Input.Password
                      size="large"
                      visibilityToggle={{
                        visible: isPasswordVisible,
                        onVisibleChange: setIsPasswordVisible,
                      }}
                      onKeyUp={onPasswordType}
                      onBlur={(e) => validateOnBlur(e, "password")}
                    />
                  </Form.Item>

                  {isCapsLockOn && <CapsLockHint />}
                </div>

                <Form.Item>
                  <Button
                    type="primary"
                    shape="round"
                    size="large"
                    htmlType="submit"
                    block
                  >
                    Log in
                    {isFormDisabled && <LoadingOutlined />}
                  </Button>
                </Form.Item>
              </Form>

              <UsefulLinks />
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
