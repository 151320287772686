import { Tag } from "antd";
import { ReactNode } from "react";

export const useSidebarHeader = () => {
  const getStatus = (value: number): ReactNode => {
    let color;
    let label = "Rejected";

    if (value === 1) {
      color = "green";
      label = "Active";
    }

    if (value === 2) {
      color = "red";
      label = "Blocked";
    }
    if (value === 0) {
      color = "orange";
      label = "Pending";
    }

    return <Tag color={color}>{label}</Tag>;
  };

  const getHeader = (record: any, value: string): ReactNode => {
    return (
      <div className="d-flex align-center">
        <h4
          className="my-0 title mr-4 elipsis"
          title={`${value} ${record.lastName}`}
        >{`${value} ${record.lastName}`}</h4>
        <div>{getStatus(record.status)}</div>
      </div>
    );
  };

  return { getStatus, getHeader };
};
