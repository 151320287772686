import { Loader } from "../../../../components/Loader";
import { Card, Col, Row } from "antd";
import { GeneralInfo } from "./GeneralInfo";
import { NextPayment } from "./NextPayment";
import { useState, useEffect } from "react";
import AccountsService from "../../../../services/accounts";
import { Coupon } from "./Coupon";
import "./styles.scss";
import { useGetRole } from "../../../../lib/hooks/getRole";
import { Disclaimer } from "./Disclaimer";
import { PastPayments } from "./PastPayments";

type DetailsProps = {
  selectedContract: any;
};

export const Details = ({ selectedContract }: DetailsProps) => {
  const [data, setData] = useState<any>(null);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);

    selectedContract &&
      AccountsService.fetchAccountContracts(selectedContract.accountNumber)
        .then((res) => {
          setData(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
  }, [selectedContract]);

  return (
    <div className="tab-content">
      {!isAdmin && <Coupon selectedContract={selectedContract} data={data} />}
      {isLoading ? (
        <Loader fontSize="128px" />
      ) : (
        <div className="info-tablets" style={{ height: "100%" }}>
          {data && (
            <>
              <Row className="cards-row w-100 justify-center pt-6">
                <Col className="main-info-card">
                  <Card>
                    <GeneralInfo data={data.generalInformation} />
                  </Card>
                </Col>
                <Col className="secondary-info-card">
                  <Card>
                    <NextPayment data={data.nextPayment} />
                  </Card>
                </Col>
              </Row>
              <Row className="w-100">
                {!isAdmin ? (
                  <Col className="w-100 d-flex justify-center">
                    <Disclaimer />
                  </Col>
                ) : (
                  <Row className="w-100 justify-center">
                    <Row className="past-payments-container">
                      <Card className="mr-4 mt-4 mb-4">
                        <PastPayments data={data.pastPayments} />
                      </Card>
                    </Row>
                  </Row>
                )}
              </Row>
            </>
          )}
        </div>
      )}
    </div>
  );
};
