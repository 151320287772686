import { SET_MESSAGE, CLEAR_MESSAGE } from "./types";
import { Notification } from "../types/Notification";
export const setMessage = (message: Notification) => ({
  type: SET_MESSAGE,
  payload: message,
});

export const clearMessage = () => ({
  type: CLEAR_MESSAGE,
});
