import { Row, Table } from "antd";
import { useState, useMemo } from "react";

import { getTableColumns, onSortChange } from "./accountsTableColumns";
import { View } from "./View";
import { TablePagination } from "../../components/TablePagination";
import { useGetLastUpdate } from "../../lib/hooks/getLastUpdate";
import { useInitialStates } from "./hooks/initialStates";
import { useFetchData } from "./hooks/fetchData";
import { useFetchDataCount } from "./hooks/fetchDataCount";
import { Filters } from "./Filters";
import { useIsOther } from "./hooks/isOther";

export const Accounts = () => {
  const [lastUpdatedLabel] = useGetLastUpdate();

  const {
    requsetInitialState,
    lastPaymnetDateInitialState,
    statusesInitialState,
  } = useInitialStates();

  const [request, setRequest] = useState(requsetInitialState);

  const { data, isLoading } = useFetchData(request);

  const [selectedContract, setSelectedContract] = useState<any>(null);

  const { isOther } = useIsOther(request, setRequest, setSelectedContract);

  const { total, isLoading: isTotalLoading } = useFetchDataCount(request);

  const columns = useMemo(() => getTableColumns(setSelectedContract), []);
  const onPageChange = (pageNumber: number, pageSize: number) => {
    const newRequest = { ...request, pageNumber, pageSize };

    setRequest(newRequest);
  };

  const onSorterChange = (_pagination: any, _filter: any, sorter: any) => {
    onSortChange(sorter, setRequest, request);
  };

  return (
    <>
      <Row className="accounts table-page-content">
        <Filters
          request={request}
          statusesInitialState={statusesInitialState}
          lastPaymnetDateInitialState={lastPaymnetDateInitialState}
          isLoading={isLoading}
          isOther={isOther}
          setRequest={setRequest}
        />
        <Row className="d-block table__container width-100">
          <Table
            dataSource={data}
            columns={columns}
            loading={isLoading}
            pagination={false}
            className="overflow-y-auto"
            rowKey={"id"}
            onChange={onSorterChange}
          />
          <TablePagination
            isTotalLoading={isTotalLoading}
            total={total}
            currentPage={request.pageNumber}
            pageSize={request.pageSize}
            onPageChange={onPageChange}
          />
        </Row>
      </Row>
      {selectedContract && (
        <View
          selectedContract={selectedContract}
          onBack={setSelectedContract}
          lastUpdatedLabel={lastUpdatedLabel}
        />
      )}
    </>
  );
};
