import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  CONFIRM_CODE_SUCCESS,
  CONFIRM_CODE_FAIL,
  UPDATE_USER_DATA,
} from "../acions/types";

const userData = localStorage.getItem("userData");
const user = userData ? JSON.parse(userData || "") : false;

const initialState = user ? { user } : { user: null };

export default function auth(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case REGISTER_SUCCESS:
      return {
        ...state,
      };
    case REGISTER_FAIL:
      return {
        ...state,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        user: payload,
      };
    case UPDATE_USER_DATA:
      return {
        ...state,
        user: { ...state.user, ...payload },
      };
    case CONFIRM_CODE_SUCCESS: {
      return { ...state, code: payload.code };
    }
    case CONFIRM_CODE_FAIL:
      return {
        ...state,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}
