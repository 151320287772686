import { ReactNode, useCallback } from "react";
import { USER_EVENT_TYPES } from "../../../../../../lib/constants";
import { getFormatted } from "../../../../../../lib/PhoneFormatter";

const LABELS: any = {
  FirstName: "First Name",
  LastName: "Last Name",
  Email: "Email",
  Phone: "Phone",
  AccountNumber: "Account Number",
  TaxID: "Tax ID",
  TaxIdDigits: "Tax ID",
  TradeRole: "Trade Role",
  RegistrationType: "Trade Role",
  Role: "Role",
  Username: "Username",
  AccessNumber: "Access Number"
};

export const useGetCollapseHeader = () => {
  const getHeader = useCallback((item: any): ReactNode => {
    let headerText;

    if (item.type === USER_EVENT_TYPES.BLOCKED) {
      headerText = `Blocked by ${item.createdByUserFullName || "the system"}`;
    }

    if (item.type === USER_EVENT_TYPES.UNBLOCKED) {
      headerText = `Unblocked by ${item.createdByUserFullName || "the system"}`;
    }

    if (
      item.type === USER_EVENT_TYPES.UPDATE_PROFILE_BY_SELF ||
      item.type === USER_EVENT_TYPES.UPDATE_PROFILE_BY_ADMIN
    ) {
      headerText = `Profile was updated by ${
        item.createdByUserFullName || "the system"
      }`;
    }

    if (
      item.type === USER_EVENT_TYPES.UPDATE_PASSWORD_BY_SELF ||
      item.type === USER_EVENT_TYPES.RESET_PASSWROD
    ) {
      headerText = `Password was updated by ${
        item.createdByUserFullName || "the system"
      }`;
    }

    if (item.type === USER_EVENT_TYPES.CREATED) {
      headerText = `Created by ${item.createdByUserFullName || "the system"}`;
    }

    if (item.type === USER_EVENT_TYPES.ACCESS_REQUESTED) {
      headerText = "Access requested";
    }

    if (item.type === USER_EVENT_TYPES.CREATED_BY_IMPORT) {
      headerText = "Created by the system";
    }

    if (item.type === USER_EVENT_TYPES.ACCESS_GRANTED) {
      headerText = `Access granted by ${
        item.createdByUserFullName || "the system"
      }`;
    }

    if (item.type === USER_EVENT_TYPES.ACCESS_DENIED) {
      headerText = `Access rejected by ${
        item.createdByUserFullName || "the system"
      }`;
    }

    if (item.type === USER_EVENT_TYPES.ACCESS_NUMBER_MODIFIED) {
      headerText = `Access Number was updated by ${
        item.createdByUserFullName || "the system"
      }`;
    }

    return (
      <div className="collapse-header">
        <p
          className="text-md elipsis my-0"
          title={`${item.createdByUserFullName || "The system"}`}
        >
          {headerText}
        </p>
        <p className="my-0 text-sm time">{item.createdAt}</p>
      </div>
    );
  }, []);

  const getBody = useCallback((item: any): ReactNode => {
    let content = <div />;
    if (
      item.type === USER_EVENT_TYPES.BLOCKED ||
      item.type === USER_EVENT_TYPES.UNBLOCKED ||
      item.type === USER_EVENT_TYPES.ACCESS_DENIED
    ) {
      content = (
        <div className="mb-6 mx-6 content-box">
          <span className="text-md h pr-1">Reason:</span>
          <span className="text-md pre-line">{item.reason || "-"}</span>
        </div>
      );
    }

    if (
      item.type === USER_EVENT_TYPES.UPDATE_PROFILE_BY_SELF ||
      item.type === USER_EVENT_TYPES.UPDATE_PROFILE_BY_ADMIN ||
      item.type === USER_EVENT_TYPES.ACCESS_NUMBER_MODIFIED
    ) {
      content = (
        <div className="mb-6 mx-6 content-box">
          <div className="text-md h mb-2">Updated Data</div>
          <div>
            {item.fields.map((item: any, i: number) => {
              const value =
                item.name === "Phone" || item.name === "PhoneNumber"
                  ? `Phone: from ${getFormatted(item.oldValue).slice(
                      2
                    )} to ${getFormatted(item.newValue).slice(2)}`
                  : `${LABELS[item.name]}: from ${item.oldValue} to ${
                      item.newValue
                    }`;

              return (
                <div key={i} className="mb-2">
                  {value}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (
      item.type === USER_EVENT_TYPES.CREATED ||
      item.type === USER_EVENT_TYPES.ACCESS_REQUESTED ||
      item.type === USER_EVENT_TYPES.CREATED_BY_IMPORT
    ) {
      content = (
        <div className="mb-6 mx-6 content-box">
          <div className="text-md h mb-2">Provided Data</div>
          <div>
            {item.fields.map((item: any, i: number) => {
              const value = `${
                item.name === "Phone"
                  ? getFormatted(item.newValue).slice(2)
                  : item.newValue
              }`;
              return (
                <div key={i} className="mb-2 elipsis" title={value}>
                  {`${LABELS[item.name]}: ${value}`}
                </div>
              );
            })}
          </div>
        </div>
      );
    }

    if (item.type === USER_EVENT_TYPES.ACCESS_GRANTED) {
      content = (
        <div className="mb-6 mx-6 content-box">
          <span className="text-md h pr-1">Access Number:</span>
          <span className="text-md pre-line">
            {item.fields[0].newValue || "-"}
          </span>
        </div>
      );
    }

    return content;
  }, []);

  return [getHeader, getBody];
};
