import { useCallback, useEffect, useState } from "react";
import { usePrevious } from "../../../lib/hooks/previous";
import AccountsService from "../../../services/accounts";

export const useFetchDataCount = (request: any) => {
  const [isLoading, setIsLoading] = useState(false);

  const [total, setTotal] = useState<number>(1);

  const prevRequest = usePrevious(request);

  const fetchDataCount = useCallback(() => {
    setIsLoading(true);
    AccountsService.fetchAccountsCount(request).then((res: any) => {
      setIsLoading(false);
      setTotal(res);
    });
  }, [request]);

  useEffect(() => {
    if (
      !prevRequest ||
      prevRequest.statusFilter !== request.statusFilter ||
      prevRequest.search !== request.search ||
      prevRequest.dateFilter !== request.dateFilter ||
      prevRequest.dateFilterNextPayment !== request.dateFilterNextPayment ||
      prevRequest.isOther !== request.isOther
    ) {
      fetchDataCount();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataCount]);

  return { total, isLoading };
};
