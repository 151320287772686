import { Button } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";
import { useState } from "react";
import { GREY_12_COLOR } from "../../lib/constants";
import { GuestLink } from "../../types/GuestLink";

const ICON_STYLE = { fontSize: 12, marginLeft: 6, color: GREY_12_COLOR };

type GuestListLinksProps = {
  header: string;
  links: GuestLink[];
  isComplex?: boolean;
  additionalClass?: string;
};

export const ListLinks = ({
  header,
  links,
  isComplex,
  additionalClass,
}: GuestListLinksProps) => {
  const [isListCollapsed, seIsListCollapsed] = useState<boolean>(true);

  return (
    <div className={additionalClass || ""}>
      <Button
        type="link"
        style={{ height: "25px" }}
        className="useful-link px-0 py-0"
        onClick={() => seIsListCollapsed(!isListCollapsed)}
      >
        {header}
        {isListCollapsed ? (
          <DownOutlined style={ICON_STYLE} />
        ) : (
          <UpOutlined style={ICON_STYLE} />
        )}
      </Button>
      {!isListCollapsed && (
        <div className="useful-links-list items">
          {links.map((item: GuestLink, i: number) => {
            return (
              <div key={i}>
                {!isComplex ? (
                  <Button
                    type="link"
                    className="useful-link item"
                    href={item.href || "#"}
                  >
                    {item.header}
                  </Button>
                ) : (
                  <div key={i}>{item.node}</div>
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
