import { Button, Form, Input } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  ACCESS_NUMBER_IS_REQUIRED,
  REASON_REQUIRED,
  WROND_6_DIGIT_NUMBER,
} from "../../lib/constants";
import { CustomModal } from "../../lib/CustomModal";
import { useMemo, useState } from "react";
import { ACCOUNT_NUMBER_6_DIGITS_RULE } from "../../lib/validationRules";
import { useAppDispatch } from "../../store";
import { acceptUser, rejectUser } from "../../acions/registrationRequests";
import { fetchCount } from "../../acions/registrationRequests";

type AcceptModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  isAccept: boolean;
  userId: string | undefined;
  reloadData: () => void;
};

export const AcceptModal = ({
  isModalOpen,
  setIsModalOpen,
  isAccept,
  userId,
  reloadData,
}: AcceptModalProps) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const rules = useMemo(() => {
    const value: any = [
      {
        required: true,
        message: !isAccept ? REASON_REQUIRED : ACCESS_NUMBER_IS_REQUIRED,
        validateTrigger: "onSubmit",
      },
    ];
    isAccept &&
      value.push({
        pattern: ACCOUNT_NUMBER_6_DIGITS_RULE,
        message: WROND_6_DIGIT_NUMBER,
        validateTrigger: "onSubmit",
      });

    return value;
  }, [isAccept]);

  const onCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  const onSubmitClicked = (values: any) => {
    if (userId) {
      const request: any = { userId };

      if (isAccept) {
        request["accessCode"] = values.reason;
      } else {
        request["reason"] = values.reason;
      }

      setIsLoading(true);

      Promise.resolve(
        dispatch(isAccept ? acceptUser(request) : rejectUser(request))
      )
        .then(() => {
          setIsLoading(false);
          setIsModalOpen(false);
          reloadData();
          form.resetFields();

          dispatch(fetchCount({ statuses: 0 }));
        })
        .catch(() => {
          setIsLoading(false);
          form.resetFields();
        });
    }
  };

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      titleText={
        !isAccept
          ? "Specify the reason for rejection"
          : "Link the user with his access number"
      }
      width={396}
      headerClass="mr-3 text w-500"
      modalClass="reason-modal"
    >
      <div className="mt-8">
        {!isAccept && (
          <p className="my-0">
            Rejection reason will be sent to the user via email.
          </p>
        )}
        <div className="mt-6">
          <Form
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
            form={form}
            disabled={isLoading}
            onFinish={onSubmitClicked}
          >
            <Form.Item
              name="reason"
              label={!isAccept ? "Rejection reason" : "Access Number"}
              className="mb-5"
              rules={rules}
            >
              {!isAccept ? <TextArea rows={2} /> : <Input size="large" />}
            </Form.Item>

            <Form.Item className="mt-8 mb-0">
              <div className="d-flex justify-end mt-8 mb-0">
                <Button shape="round" className="mr-2" onClick={onCancel}>
                  Cancel
                </Button>
                <Button type="primary" shape="round" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </CustomModal>
  );
};
