import { Row, Col, Button } from "antd";
import { useCallback, useState } from "react";
import logo from "../../../images/logo.svg";
import { Outlet } from "react-router-dom";
import { HelpModal } from "../../HelpModal";
import { useNavigate } from "react-router-dom";
import { useRedirectToMain } from "../../../lib/hooks/redirectToMain";
import "./styles.scss";

export const Header = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const [redirectToMain] = useRedirectToMain(navigate);

  const openHelp = useCallback(() => setIsModalOpen(true), []);

  return (
    <>
      <Row className="guest-header justify-space-between" align="middle">
        <img
          src={logo}
          alt="logo"
          className="logo-cursor"
          onClick={redirectToMain}
          height={32}
        />
        <Row justify="end">
          <Col>
            <Button
              type="link"
              className="useful-link z-10 px-0"
              onClick={openHelp}
            >
              Help
            </Button>

            <HelpModal
              isModalOpen={isModalOpen}
              setIsModalOpen={setIsModalOpen}
            />
          </Col>
        </Row>
      </Row>

      <Outlet />
    </>
  );
};
