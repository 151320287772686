import { Row, Col, Form, Input, Button } from "antd";
import { Header } from "../../components/SecurityCheck/Header";
import { useNavigate } from "react-router-dom";
import {
  MAX_EMAIL_USERNAME,
  ENTER_EMAIL_OR_USERNAME,
} from "../../lib/constants";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { useAppDispatch } from "../../store";
import { clearMessage } from "../../acions/message";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";

type FirstStepProps = {
  setStep: (value: boolean) => void;
  data: any;
  setData: (value: any) => void;
};

export const FirstStep = ({ setStep, data, setData }: FirstStepProps) => {
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const [validateOnBlur] = useValidateOnBlur(form);

  const dispatch = useAppDispatch();

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const onNextButtonClicked = (values: any) => {
    setIsFormDisabled(true);
    setData(values);
    setIsFormDisabled(false);
    setStep(true);
    dispatch(clearMessage());
  };

  return (
    <Row className="mt-16 justify-center mx-4">
      <Col className="forgot-acc-form">
        <Row className="d-block">
          <Header
            header="Want to reset your password?"
            onBackClicked={() => navigate(-1)}
          />

          <Row className="mt-2 d-flex justify-center">
            <div style={{ width: "280px" }}>
              <p className="my-0 text-center">
                Enter the email address or username you registered with and we
                will help you
              </p>
            </div>
          </Row>
        </Row>

        <Row className="d-flex justify-center mt-10 w-100">
          <Col className="forgot-pass">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              onFinish={onNextButtonClicked}
              disabled={isFormDisabled}
              initialValues={data}
              className="form__container"
            >
              <Form.Item
                name="userName"
                label="Email or Username"
                className="mb-8"
                rules={[
                  {
                    required: true,
                    message: ENTER_EMAIL_OR_USERNAME,
                    validateTrigger: "onSubmit",
                  },
                  {
                    max: 100,
                    message: MAX_EMAIL_USERNAME,
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  size="large"
                  onBlur={(e) => validateOnBlur(e, "userName")}
                />
              </Form.Item>

              <Form.Item className="mt-3 mb-12">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Next
                  {isFormDisabled && <LoadingOutlined />}
                </Button>
              </Form.Item>
            </Form>

            <div className="d-flex justify-center links-container">
              <Button
                type="link"
                className="height-0 pt-0 pl-2"
                href="/forgot-account-details"
              >
                I forgot email/username
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
