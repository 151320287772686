import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { updateUserData } from "../../acions/auth";
import { Loader } from "../../components/Loader";
import { ProfilesService } from "../../services/profiles";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import { deleteUserData } from "../../acions/auth";
import { fetchCount } from "../../acions/registrationRequests";
import { useGetRole } from "../hooks/getRole";
import { LogoutModal } from "./LogoutModal";

type PrivateRouteContainerProps = {
  userData: any;
};

export const PrivateRouteContainer = ({
  userData,
}: PrivateRouteContainerProps) => {
  const dispatch = useAppDispatch();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (isAdmin) {
      dispatch(fetchCount({ statuses: 0 }));
    }
  }, [dispatch, isAdmin]);

  useEffect(() => {
    if (userData.firstName && userData.lastName) {
      setIsLoading(false);
    } else {
      setIsLoading(true);

      ProfilesService.fetchUser()
        .then((res) => {
          dispatch(
            updateUserData({
              firstName: res.firstName,
              lastName: res.lastName,
            })
          );
          setIsLoading(false);
        })
        .catch(() => {
          Promise.resolve(dispatch(deleteUserData())).then(() => {
            navigate("/login");
            setIsLoading(false);
          });
        });
    }
  }, [dispatch, navigate, userData.firstName, userData.lastName]);

  return isLoading ? (
    <Loader fontSize="128px" />
  ) : (
    <>
      <LogoutModal />
      <Outlet />
    </>
  );
};
