import { Row, Col, Button } from "antd";
import image from "../../images/internal-server-error.svg";
import "../NotFound/styles.scss";

type InternalServerErrorProps = {
  dashboard?: boolean;
};

export const InternalServerError = ({
  dashboard,
}: InternalServerErrorProps) => {
  return (
    <div className={`${!dashboard ? "height-100" : ""}`}>
      <Row className="flex-column justify-center height-100 error-page">
        <Row>
          <Col className="error-page-left__container">
            <h1 className="header h h1 pb-9">Sorry, unexpected error.</h1>
            <div className="pt-9 pb-9">
              <div className="text">Error code: 500.</div>
              <div className="text pt-6">
                There is a technical problem that we're working on. We’ll fix
                everything shortly.
              </div>
            </div>
            <h4 className="pt-9 pb-8 h h4">What to do?</h4>
            <div className="text pb-5">You might try again later.</div>
            <div className="text pb-9">
              Or if you need immediate help,{" "}
              <Button
                type="link"
                className="text"
                href="mailto:info@sunwestescrow.com"
              >
                contact us
              </Button>
              .
            </div>
            <div className="text pt-9">Thanks for your patience!</div>
          </Col>
          <Col className="error-img">
            <img src={image} alt="404" />
          </Col>
        </Row>
      </Row>
    </div>
  );
};
