import { Row, Table } from "antd";
import { Filters } from "./Filters";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import { UserRegistrationService } from "../../services/registrationRequests";
import { getTableColumns, onSortChange } from "./userActivationTableColumns";
import { useSidebar } from "./hooks/sidebar";
import { createPortal } from "react-dom";
import { useSidebarHeader } from "../../lib/hooks/sidebarHeader";
import "./styles.scss";
import { TablePagination } from "../../components/TablePagination";
import { usePrevious } from "../../lib/hooks/previous";
import { useSearch } from "../../lib/hooks/search";

export const UserActivation = () => {
  const mountedRef = useRef<boolean>(false);

  const [data, setData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [request, setRequest] = useState<any>({
    pageSize: 10,
    pageNumber: 1,
  });

  const [search, debouncedSearch, onSearchChange, onClearSearch] = useSearch();

  const [status, setStatus] = useState<string | number>("All");

  const { getStatus, getHeader } = useSidebarHeader();

  const fetchData = useCallback(() => {
    setIsLoading(true);
    UserRegistrationService.fetchRegistrationsList(request).then((res) => {
      setIsLoading(false);
      setData(res);
    });
  }, [request]);

  const fetchDataCount = useCallback(() => {
    setIsTotalLoading(true);
    UserRegistrationService.fetchRegistrationsListCount(request)
      .then((res) => {
        setIsTotalLoading(false);
        setTotal(res);
      })
      .catch(() => setIsTotalLoading(false));
  }, [request]);

  const [showSideBar, sideBar, onOpenSideBar, openDeleteModal] = useSidebar(
    fetchData,
    fetchDataCount
  );

  const columns = useMemo(
    () => getTableColumns(onOpenSideBar, getStatus, getHeader, openDeleteModal),
    [getHeader, getStatus, onOpenSideBar, openDeleteModal]
  );

  const [total, setTotal] = useState<number>(1);

  const [isTotalLoading, setIsTotalLoading] = useState<boolean>(false);

  const onPageChange = (pageNumber: number, pageSize: number) => {
    const newRequest = { ...request, pageNumber, pageSize };

    setRequest(newRequest);
  };

  useEffect(() => fetchData(), [fetchData]);

  useEffect(() => {
    if (mountedRef.current) {
      const newRequest: any = { ...request, pageNumber: 1 };

      if (search) {
        newRequest["search"] = debouncedSearch;
      } else {
        delete newRequest["search"];
      }

      newRequest["statuses"] = status !== "All" ? status : [0, 4];

      setRequest(newRequest);
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearch, status]);

  const prevRequest = usePrevious(request);

  useEffect(() => {
    if (
      !prevRequest ||
      prevRequest.statuses !== request.statuses ||
      prevRequest.search !== request.search
    ) {
      fetchDataCount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchDataCount]);

  const onSorterChange = (_pagination: any, _filter: any, sorter: any) => {
    onSortChange(sorter, setRequest, request);
  };

  return (
    <Row className="table-page-content mx-8">
      <Row className="d-block width-100">
        <Row className="my-6">
          <h3 className="h h3">User Activation</h3>
        </Row>
        <Filters
          setStatus={setStatus}
          isLoading={isLoading}
          search={search}
          onSearchChange={onSearchChange}
          onClearSearch={onClearSearch}
        />
      </Row>
      <Row className="d-block table__container mt-2">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="overflow-y-auto"
          rowKey={"id"}
          onChange={onSorterChange}
        />
        <TablePagination
          isTotalLoading={isTotalLoading}
          total={total}
          currentPage={request.pageNumber}
          pageSize={request.pageSize}
          onPageChange={onPageChange}
        />
      </Row>
      {showSideBar && createPortal(sideBar, document.body)}
    </Row>
  );
};
