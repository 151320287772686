import { useLocation, Location } from "react-router-dom";
import { useEffect, useMemo, useRef } from "react";

export const useIsOther = (
  request: any,
  setRequest: (value: any) => void,
  setSelectedContract: (value: any) => void
) => {
  const location: Location = useLocation();

  const mountedRef = useRef<boolean>(false);

  const isOther: boolean = useMemo(
    () => location.pathname === "/other-accounts",
    [location.pathname]
  );

  useEffect(() => {
    if (mountedRef.current) {
      const newRequest: any = { ...request, pageNumber: 1 };

      setSelectedContract(null);

      if (isOther) {
        newRequest["isOther"] = isOther;
      } else {
        delete newRequest["isOther"];
      }

      setRequest(newRequest);
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOther, setSelectedContract]);

  return { isOther };
};
