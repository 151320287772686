import { Button, Row } from "antd";
import { useEffect, useState, useMemo } from "react";
import { Loader } from "../../../components/Loader";
import { UserRegistrationService } from "../../../services/registrationRequests";
import { Info } from "./Info";
import { History } from "./History";
import { AcceptModal } from "../AcceptModal";

type SideBarContentProps = {
  user: any | null;
  reloadData: () => void;
  openDeleteModal: (userId: string) => void;
};

export const SideBarContent = ({
  user,
  reloadData,
  openDeleteModal,
}: SideBarContentProps) => {
  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const [isAccept, setIsAccept] = useState<boolean>(false);

  useEffect(() => {
    if (user && user.status === 0) {
      setIsLoading(true);

      UserRegistrationService.fetchRegistrationUser(user.userId)
        .then((res) => {
          setData(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [user]);

  const onAcceptClicked = (value: boolean) => {
    setIsAccept(value);
    setIsModalOpen(true);
  };

  const footerButtons = useMemo(() => {
    if (user) {
      return (
        <div className="px-6 py-6 sidebar-footer">
          {user.status === 0 ? (
            <>
              <Button
                shape="round"
                type="primary"
                className="mr-4"
                onClick={() => onAcceptClicked(true)}
              >
                Accept User
              </Button>
              <Button
                shape="round"
                danger
                onClick={() => onAcceptClicked(false)}
              >
                Reject User
              </Button>
            </>
          ) : (
            <Button shape="round" onClick={() => openDeleteModal(user.userId)}>
              Delete Record
            </Button>
          )}
        </div>
      );
    }

    return <div />;
  }, [user, openDeleteModal]);

  return (
    <>
      <AcceptModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        isAccept={isAccept}
        userId={user.userId || undefined}
        reloadData={reloadData}
      />
      <Row className="sidebar-body user-content">
        {isLoading ? (
          <div className="info-container">
            <Loader fontSize="64px" />
          </div>
        ) : (
          <>
            {user.status === 0 ? (
              <Info data={data} />
            ) : (
              <History userId={user.userId} />
            )}
          </>
        )}
      </Row>
      {footerButtons}
    </>
  );
};
