import { useState, useEffect } from "react";
import { Collapse } from "antd";
import { useGetCollapseHeader } from "./hooks/getCollapseHeader";

type UserEventsProps = {
  data: any[];
};

const { Panel } = Collapse;

export const UserEvents = ({ data }: UserEventsProps) => {
  const [items, setItems] = useState<any[]>([]);

  const [getHeader, getBody] = useGetCollapseHeader();

  useEffect(() => {
    const newItems = data.map((item: any): any => ({
      header: getHeader(item),
      children: getBody(item),
    }));

    setItems(newItems);
  }, [data, getHeader, getBody]);

  return (
    <div className="event-collapse">
      {items.length > 0 && (
        <Collapse>
          {items.map((item: any, i: number) => {
            return (
              <Panel header={item.header} key={i}>
                {item.children}
              </Panel>
            );
          })}
        </Collapse>
      )}
    </div>
  );
};
