import "./styles.scss";
import { CloseOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ReactNode } from "react";

type SideBarContainerProps = {
  title: string | ReactNode;
  setShowSideBar: (value: boolean) => void;
  children: ReactNode;
};

export const SideBarContainer = ({
  setShowSideBar,
  title,
  children,
}: SideBarContainerProps) => {
  const onCloseClicked = () => {
    setShowSideBar(false);
  };

  return (
    <div className="sidebar__container">
      <div className="px-6 py-6 d-flex align-center sidebar-header">
        <Button
          className="mr-3 close-button"
          shape="circle"
          icon={<CloseOutlined />}
          type="text"
          onClick={onCloseClicked}
        />
        {title}
      </div>
      <div className="sidebar-content">{children}</div>
    </div>
  );
};
