import { useEffect, useState } from "react";
import AccountsService from "../../../services/accounts";
import { Account } from "../../../types/Account";

export const useFetchData = (request: any) => {
  const [data, setData] = useState<Account[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    AccountsService.fetchAccounts(request).then((res: any) => {
      res && setData(res.map((item: any, i: number) => ({ ...item, id: i })));
      setIsLoading(false);
    });
  }, [request]);

  return { data, isLoading };
};
