export const PRIMARY_1_COLOR = "#F0F7FF";
export const PRIMARY_6_COLOR = "#2B59C3";

export const FONT_FAMILY = "Roboto";

export const GREY_12_COLOR = "#141414";

export const MODAL_MASK_COLOR = "#000000b3";

export const RED_6_COLOR = "#F5222D";

export const WARNING_COLOR = "#DF6420";

export const ENTER_EMAIL_OR_USERNAME = "Please enter Email or Username";

export const ENTER_6_DIGITS = "Please enter a 6-digit code";

export const ENTER_PASSWORD = "Please enter Password";

export const INCORRECT_PASSWORD_MSG = "Not allowed character is used";

export const FAIL_LOGIN_MESSAGE =
  "Incorrect Email/Username or Password. Please try again.";

export const FAIL_CONFIRM_ACCOUNT = "Incorrect code. Please try again";

export const CODE_LIMIT_REACHED =
  "Resend Code limit reached. Please try again later.";

export const FIRST_NAME_REQUIRED = "Please enter First Name";

export const LAST_NAME_REQUIRED = "Please enter Last Name";

export const ACCOUNT_NUMBER_REQUIRED = "Please enter Account Number";

export const TAX_ID_REQUIRED = "Please enter Tax ID Number";

export const EMAIL_REQUIRED = "Please enter Email address";

export const WRONG_TAX_ID = "Tax ID Number should contain 4 numbers only.";

export const WRONG_ACCOUNT_NUMBER =
  "Account Number should contain number only.";

export const WRONG_ACCEPT_NUMBER = "Accept Number should contain number only.";

export const WROND_6_DIGIT_NUMBER = "Access Number must be exactly 6 digits";

export const WRONG_PHONE_FORMAT = "Wrong phone number format";

export const WRONG_FIRST_NAME =
  "First Name should contain letters, numbers and dashes only";

export const WRONG_LAST_NAME =
  "Last Name should contain letters, numbers and dashes only";

export const WRONG_EMAIL_FORMAT = "Wrong email format";

export const EMAIL_IS_USED = "Email address is already registered.";

export const CODE_SEND = "New code was sent";

export const RESET_PASSWORD_SEND = "Reset password link was sent";

export const RESET_PASSWORD_SUCCESS = "Password is successfully changed";

export const RESET_PHONE_SUCCESS = "Phone number is successfully changed";

export const RESET_EMAIL_SECCESS = "Email is successfully changed";

export const INVALID_ACCOUNT_INFO =
  "Invalid Account Information. The provided Account information is invalid. Please ensure you have entered the correct details associated with your account.";

export const PHONE_REQUIRED = "Please enter Phone Number";

export const ACCOUNT_STATUSES = [
  { label: "Active", value: 0 },
  { label: "Inactive", value: 2 },
  { label: "Default", value: 1 },
  { label: "Paid Off", value: 3 },
  { label: "Replaced", value: 4 },
  { label: "Foreclosed", value: 5 },
  { label: "Cancelled", value: 6 },
];

export const NEXT_PAYMENT_OPTIONS = [
  { label: "Overdue", value: 1 },
  { label: "Today", value: 2 },
];

export const LAST_PAYMENT_OPTIONS = [
  { label: "Last 6 months", value: 1 },
  { label: "More than 6 months", value: 2 },
];

export const MAX_EMAIL_USERNAME =
  "Email/Username shouldn't contain more than 100 symbols";

export const MAX_PASSWORD = "Password shouldn't contain more than 30 symbols";

export const MAX_FIRSTNAME =
  "First name shouldn't contain more than 50 symbols";

export const MAX_LASTNAME = "Last name shouldn't contain more than 50 symbols";

export const MAX_ACCOUNT_NUMBER =
  "Account number shouldn't contain more than 50 numbers";

export const MAX_EMAIL = "Email shouldn't contain more than 100 symbols";

export const FAILED_PASSWORD_CHANGE = "Failed to change password.";

export const FAILED_FIND_ACCOUNT = "Failed to find account data.";

export const CREATE_ADMIN_SUCCESS = "Admin is successfully created.";

export const UPDATE_USER_SUCCESS = "User’s profile was updated";

export const REASON_REQUIRED = "Reason is required.";

export const ACCESS_NUMBER_IS_REQUIRED = "Access Number is required.";

export const ACCEPT_USER_SUCCESS = "User was accepted";

export const REJECT_USER_SUCCESS = "User was rejected";

export const DELETE_USER_SUCCESS = "Record was deleted";

export const USER_EVENT_TYPES = {
  BLOCKED: 100,
  UNBLOCKED: 101,
  UPDATE_PROFILE_BY_SELF: 300,
  UPDATE_PROFILE_BY_ADMIN: 301,
  UPDATE_PASSWORD_BY_SELF: 302,
  RESET_PASSWROD: 303,
  ACCESS_NUMBER_MODIFIED: 304,
  CREATED: 400,
  ACCESS_REQUESTED: 401,
  ACCESS_GRANTED: 402,
  ACCESS_DENIED: 403,
  CREATED_BY_IMPORT: 404,
};

export const DEFAULT_TIME_FORMAT = "MM/DD/YY, hh:mm A";

export const DATE_FORMAT = "MM/DD/YY";

export const BLOCK_ERROR_MESSGAE = "Sorry, your account is blocked";

export const LOGOUT_REASON =
  "You were logged out due to session expiration.It helps to protect your data. To proceed please log in again.";

export const CHART_TITLE_COLOR = "#0000000f";

export const CHART_VALUE_COLOR = "#000000d9";

export const CHART_GREEN_5 = "#73D13D";

export const CHART_GOLD_5 = "#FFC53D";

export const CHART_VOLCANO_5 = "#FF7A45";

export const CHART_RED_7 = "#CF1322";

export const CHART_BLUE_7 = "#096DD9";

export const CHART_CYAN_5 = "#36CFC9";

export const CHART_BLUE_5 = "#40A9FF";

export const CHART_PURPLE_5 = "#9254DE";

export const NEW_ROLE =
  "The changes were saved. Please log in again to apply new role changes";

export const MAX_USERNAME = "Username shouldn't contain more than 100 symbols";

export const DATA_UPDATED = "Your data was updated";

export const ISSUE_WITH_ACCOUNT = "There is an issue with your account";

export const COLOR_WHITE = "#FFFFFF";

export const ACCESS_NUMBR_CAN_BE_ONLY_INT = "Only numbers are allowed";

export const SEVERAL_USERS_WITH_SAME_EMAIL = "There are several users with the same email";

export {};
