import { Tabs, TabsProps } from "antd";
import { Content } from "./Content";

type HistoryProps = {
  userId: string;
};

export const History = ({ userId }: HistoryProps) => {
  const tabItems: TabsProps["items"] = [
    {
      key: "1",
      label: "History",
      children: <Content userId={userId} />,
    },
  ];
  return (
    <>
      <Tabs items={tabItems} defaultActiveKey="1" />
    </>
  );
};
