import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import { useMemo } from "react";

export const useGetRole = () => {
  const user: any = useSelector((state: any) => state.auth.user);

  const data: any = useMemo(() => {
    return user && user.token ? jwtDecode(user.token) : {};
  }, [user]);

  const role: string = useMemo(() => {
    return data["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  }, [data]);

  const isAdmin: boolean = role === "Admin" || role === "SuperAdmin";

  return [role, isAdmin, data];
};
