import { Footer as GuestFooter } from "../components/Guest/Footer";
import { Header as GuestHeader } from "../components/Guest/Header";
import { PrivateRoutes } from "../lib/PrivateRoutes";
import { Header as LoggedInHeader } from "../components/LoggedIn/Header";
import { Footer as LoggedInFooter } from "../components/LoggedIn/Footer";
import { AdminScope } from "../components/AdminScope";
import { RedirectHome } from "../lib/RedirectHome";

import { NotFound } from "../pages/NotFound";
import { Login } from "../pages/Login";
import { SignUp } from "../pages/SignUp";
import { SuccessSignUp } from "../pages/SuccessSignUp";
import { ForgotPassword } from "../pages/ForgotPassword";
import { ResetPassword } from "../pages/ResetPassword";
import { ForgotAccount } from "../pages/ForgotAccount";
import { Accounts } from "../pages/Accounts";
import { Profile } from "../pages/Profile/idnex";
import { ChangePassword } from "../pages/Profile/ChangePassword";
import { ChangePhone } from "../pages/Profile/ChangePhone";
import { ChangeEmail } from "../pages/Profile/ChangeEmail";
import { UserManagement } from "../pages/UserManagement";
import { UserActivation } from "../pages/UserActivation";
import { InternalServerError } from "../pages/InternalServerError";
import { Dashboard } from "../pages/Dashboard";

export const routes = [
  {
    element: <RedirectHome />,
    children: [
      {
        path: "*",
        element: <NotFound />,
      },
      {
        path: "/",
        element: <RedirectHome />,
      },
      {
        path: "/internal-server-error",
        element: <InternalServerError />,
      },
      {
        element: <GuestHeader />,
        children: [
          {
            element: <GuestFooter />,
            children: [
              {
                path: "/login",
                element: <Login />,
              },
              {
                path: "/signup",
                element: <SignUp />,
              },
              {
                path: "/register-completed",
                element: <SuccessSignUp />,
              },
              {
                path: "/forgot-password",
                element: <ForgotPassword />,
              },
              {
                path: "/reset-password",
                element: <ResetPassword />,
              },
              {
                path: "/forgot-account-details",
                element: <ForgotAccount />,
              },
            ],
          },
        ],
      },
      {
        element: <PrivateRoutes />,
        children: [
          {
            element: <LoggedInHeader />,
            children: [
              {
                element: <LoggedInFooter />,
                children: [
                  {
                    path: "/accounts",
                    element: <Accounts />,
                  },
                  {
                    path: "/other-accounts",
                    element: <Accounts />,
                  },
                  {
                    path: "/profile",
                    element: <Profile />,
                  },
                ],
              },
            ],
          },
          {
            element: <AdminScope />,
            children: [
              {
                element: <LoggedInHeader />,
                children: [
                  {
                    element: <LoggedInFooter />,
                    children: [
                      {
                        path: "/home",
                        element: <Dashboard />,
                      },
                      {
                        path: "/user-management",
                        element: <UserManagement />,
                      },
                      {
                        path: "/user-activation",
                        element: <UserActivation />,
                      },
                    ],
                  },
                ],
              },
            ],
          },

          {
            element: <GuestHeader />,
            children: [
              {
                element: <GuestFooter />,
                children: [
                  {
                    path: "/profile/change-password",
                    element: <ChangePassword />,
                  },
                  {
                    path: "/profile/change-phone",
                    element: <ChangePhone />,
                  },
                  {
                    path: "/profile/change-email",
                    element: <ChangeEmail />,
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  },
];
