import "./styles.scss";
import { WarningOutlined } from "@ant-design/icons";
import { useState } from "react";
import { WARNING_COLOR } from "../../lib/constants";
import { KeyboardEvent } from "react";

export const useToggleCapsLock = (initialState: boolean) => {
  const [isCapsLockOn, setIsCapsLockOn] = useState<boolean>(initialState);

  const onPasswordType = (e: KeyboardEvent) => {
    const isCapsLock: boolean = e.getModifierState("CapsLock");

    if (isCapsLock !== isCapsLockOn) {
      setIsCapsLockOn(isCapsLock);
    }
  };

  return [isCapsLockOn, onPasswordType] as const;
};

export const CapsLockHint = () => {
  return (
    <div className="hint-right ">
      <WarningOutlined style={{ color: WARNING_COLOR }} />
      <span>Caps Lock is on</span>
    </div>
  );
};
