import "./styles.scss";
import {
  useState,
  useEffect,
  useMemo,
  ReactNode,
  useCallback,
  useRef,
} from "react";
import { createPortal } from "react-dom";
import { Filters } from "./Filters";
import { UsersService } from "../../services/users";
import { App, Row, Table } from "antd";
import { getTableColumns, onSortChange } from "./userManagementTableColumns";
import { SideBarContainer } from "../../components/SideBarContainer";
import { UserContent } from "./SideBar/UserContent";
import { useGetRole } from "../../lib/hooks/getRole";
import { ReasonModal } from "./ReasonModal";
import { useSidebarHeader } from "../../lib/hooks/sidebarHeader";
import { TablePagination } from "../../components/TablePagination";
import { useSearch } from "../../lib/hooks/search";
import { useGetDeleteDialogProps } from "../../lib/hooks/getDeleteDialogProps";
import { deleteUser } from "../../acions/users";
import { useAppDispatch } from "../../store";

export const UserManagement = () => {
  const dispatch = useAppDispatch();

  const mountedRef = useRef<boolean>(false);

  const [data, setData] = useState<any[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, _, me] = useGetRole();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [roles, setRoles] = useState<any[]>([]);

  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const [sideBarTitle, setSideBarTitle] = useState<string | ReactNode>("");

  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);

  const [query, setQuery] = useState<any>({});

  const [search, debouncedSearch, onSearchChange, onClearSearch] = useSearch();

  const [currentPage, setCurrentPage] = useState<number>(1);

  const [pageSize, setPageSize] = useState<number>(20);

  const [total, setTotal] = useState<number>(1);

  const [request, setRequest] = useState<any>({});

  const [isTotalLoading, setIsTotalLoading] = useState<boolean>(false);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [isBlock, setIsBlock] = useState<boolean>(false);

  const [isEditUser, setIsEditUser] = useState(false);

  const [userOrder, setUserOrder] = useState<number | null>(null);

  const [isDeleting, setIsDeleting] = useState(false);

  const [options] = useGetDeleteDialogProps(isDeleting);

  const { modal } = App.useApp();

  const onOpenSideBar = (header: string | ReactNode, userId: string) => {
    setSelectedUserId(userId);
    setSideBarTitle(header);
    setShowSideBar(true);
  };

  const onOpenBlockModal = (userId: string | null, isBlockValue: boolean) => {
    setSelectedUserId(userId);
    setShowModal(true);
    setIsBlock(isBlockValue);
  };

  const onOpenEditUser = (header: string | ReactNode, userId: string) => {
    setIsEditUser(true);
    setSelectedUserId(userId);
    setSideBarTitle(header);
    setShowSideBar(true);
  };

  const fetchUsers = useCallback(() => {
    setIsLoading(true);

    const dataRequest = {
      ...request,
      pageSize,
      pageNumber: currentPage,
    };

    if (userOrder) {
      dataRequest.userOrder = userOrder;
    }

    UsersService.fetchUserList(dataRequest)
      .then((res) => {
        setData(res.filter((item: any) => item.status !== 0));
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [currentPage, pageSize, request, userOrder]);

  useEffect(() => {
    if (mountedRef.current) {
      const newRequest = { ...query };

      if (search) {
        newRequest["search"] = debouncedSearch;
      }

      setRequest(newRequest);
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, debouncedSearch]);

  const loadCount = useCallback(() => {
    setIsTotalLoading(true);

    UsersService.fetchUsersCount(request).then((res) => {
      setTotal(res);
      setIsTotalLoading(false);
    });
  }, [request]);

  useEffect(() => loadCount(), [loadCount]);

  const { getStatus, getHeader } = useSidebarHeader();

  const onCloseSideBar = (value: boolean) => {
    setShowSideBar(value);
    setIsEditUser(false);
  };

  const userContentReloadData = useCallback(() => {
    loadCount();
    fetchUsers();
    setShowSideBar(false);
    setIsEditUser(false);
  }, [fetchUsers, loadCount]);

  const openDeleteModal = useCallback(
    (id: string) => {
      modal.confirm({
        ...options,
        title: "Delete user?",
        content: (
          <div className="text-md mb-1">Are you sure you want to delete?</div>
        ),
        onOk: async () => {
          setIsDeleting(true);

          try {
            await Promise.resolve(dispatch(deleteUser(id)));
            userContentReloadData();
            setIsDeleting(false);
          } catch {
            return setIsDeleting(false);
          }
        },
      });
    },
    [dispatch, modal, options, userContentReloadData]
  );

  const columns = useMemo(
    () =>
      getTableColumns(
        onOpenSideBar,
        role,
        onOpenBlockModal,
        onOpenEditUser,
        me,
        getStatus,
        getHeader,
        openDeleteModal
      ),
    [role, me, getStatus, getHeader, openDeleteModal]
  );

  const sideBar = useMemo(
    () => (
      <SideBarContainer title={sideBarTitle} setShowSideBar={onCloseSideBar}>
        <UserContent
          userId={selectedUserId}
          onOpenBlockModal={onOpenBlockModal}
          setIsEditUser={setIsEditUser}
          isEditUser={isEditUser}
          roles={roles}
          reloadData={userContentReloadData}
          openDeleteModal={openDeleteModal}
        />
      </SideBarContainer>
    ),
    [
      sideBarTitle,
      selectedUserId,
      isEditUser,
      roles,
      userContentReloadData,
      openDeleteModal,
    ]
  );

  useEffect(() => {
    UsersService.fetchRolesList().then((res) => {
      res &&
        setRoles(
          res.map((role: any) => ({ label: role.name, value: role.id }))
        );
    });
  }, []);

  const onPageChange = (pageNumber: number, pageSize: number) => {
    setCurrentPage(pageNumber);

    setPageSize(pageSize);
  };

  const toggleBlockModalReloadData = () => {
    fetchUsers();
    setShowSideBar(false);
  };

  const onSorterChange = (_pagination: any, _filter: any, sorter: any) => {
    onSortChange(sorter, setUserOrder);
  };

  return (
    <Row className="table-page-content mx-8 ">
      <ReasonModal
        isModalOpen={showModal}
        setIsModalOpen={setShowModal}
        isBlock={isBlock}
        userId={selectedUserId}
        reloadData={toggleBlockModalReloadData}
      />

      <Row className="d-block width-100">
        <Row className="my-6">
          <h3 className="h h3">User Management</h3>
        </Row>
        <Filters
          roles={roles}
          isLoading={isLoading}
          search={search}
          debouncedSearch={debouncedSearch}
          query={query}
          loadCount={loadCount}
          fetchUsers={fetchUsers}
          setCurrentPage={setCurrentPage}
          setQuery={setQuery}
          onClearSearch={onClearSearch}
          onSearchChange={onSearchChange}
        />
      </Row>
      <Row className="d-block table__container mt-2">
        <Table
          dataSource={data}
          columns={columns}
          loading={isLoading}
          pagination={false}
          className="overflow-y-auto"
          onChange={onSorterChange}
          rowKey={"id"}
        />
        <TablePagination
          isTotalLoading={isTotalLoading}
          total={total}
          currentPage={currentPage}
          pageSize={pageSize}
          onPageChange={onPageChange}
        />
      </Row>
      {showSideBar && createPortal(sideBar, document.body)}
    </Row>
  );
};
