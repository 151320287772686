import { Col, Row, Button, Spin } from "antd";
import { useAppDispatch } from "../../store";
import { useEffect, useMemo, useCallback, useState } from "react";
import { ListLinks } from "../../components/Guest/ListLinks";
import { GuestLink } from "../../types/GuestLink";
import { requestPasswordChange } from "../../acions/auth";
import { ISSUE_WITH_ACCOUNT } from "../../lib/constants";
import { IssueWithAccount } from "../../components/IssueWithAccount";
import { HelpModal } from "../../components/HelpModal";

type SecondStepProps = {
  userName: string;
};

export const SecondStep = ({ userName }: SecondStepProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [hasAccountIssues, setIsHasAccountIssues] = useState<boolean>(false);

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const sendEmail = useCallback(() => {
    setIsLoading(true);
    Promise.resolve(dispatch(requestPasswordChange({ userName })))
      .then(() => setIsLoading(false))
      .catch((err: any) => {
        setIsLoading(false);

        if (err.response.data.title === ISSUE_WITH_ACCOUNT) {
          setIsHasAccountIssues(true);
        }
      });
  }, [dispatch, userName]);

  const onContactSupportBtn = useCallback(
    () => setIsModalOpen(true),
    [setIsModalOpen]
  );

  useEffect(() => sendEmail(), [sendEmail]);

  const links: GuestLink[] = useMemo(
    (): Array<GuestLink> => [
      {
        header: "",
        node: <div className="pb-2 pl-3">1.&ensp;Check your spam folder.</div>,
      },
      {
        header: "",
        node: (
          <div className="pb-1 pl-3">
            2.&ensp;Double check for any typos in the specified email.
          </div>
        ),
      },
      {
        header: "Please check your spam folder or resend code",
        node: (
          <div className="pl-3">
            3.&ensp;
            <Button
              type="link"
              className="useful-link item px-0 py-0"
              onClick={() => sendEmail()}
            >
              Resend link.
            </Button>
          </div>
        ),
      },
      {
        header: "",
        node: (
          <div className="pl-3">
            4.&ensp;
            <Button
              type="link"
              className="useful-link item px-0 py-0"
              onClick={onContactSupportBtn}
            >
              Contact our support.
            </Button>
            The account with that email may not exist in the system.
          </div>
        ),
      },
    ],
    [sendEmail, onContactSupportBtn]
  );

  return (
    <Row>
      {!isLoading ? (
        <>
          {!hasAccountIssues ? (
            <Col className="mt-16 w-100">
              <Row className="justify-center d-flex mx-4">
                <Col>
                  <h1 className="header mb-2 text-center">Check your email</h1>
                  <div className="sub-title__container mb-10">
                    <p className="justify-center my-0">
                      We’ve just sent a password reset link to
                      <span className="email-text">&ensp;{userName}</span>
                    </p>
                  </div>
                </Col>
              </Row>

              <Row className="justify-center d-flex">
                <Col>
                  <p className="mt-0 mb-2">
                    You should get the email within 5 minutes.
                  </p>
                </Col>
              </Row>
              <Row className="justify-center d-flex">
                <Col>
                  <ListLinks
                    header="I didn’t receive email"
                    links={links}
                    isComplex={true}
                    additionalClass="d-flex flex-column justify-center check-links_ss-wrapper"
                  />

                  <HelpModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                  />
                </Col>
              </Row>
            </Col>
          ) : (
            <Col className="w-100">
              <Row className="justify-center d-flex w-100">
                <IssueWithAccount
                  header={ISSUE_WITH_ACCOUNT}
                  username={userName}
                />
              </Row>
            </Col>
          )}
        </>
      ) : (
        <Col span={16} offset={4} className="mt-16">
          <Row className="justify-center d-flex">
            <Spin size="large" className="pt-10" />
          </Row>
        </Col>
      )}
    </Row>
  );
};
