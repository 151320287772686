import { Form, Input, Button, Row } from "antd";
import {
  EMAIL_REQUIRED,
  WRONG_EMAIL_FORMAT,
  MAX_EMAIL,
} from "../../lib/constants";
import { PasswordInput } from "../../components/PasswordInput";
import { useState, useEffect } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { PhoneInput } from "../../components/PhoneInput";
import { EMAIL_RULE } from "../../lib/validationRules";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";
import { TermsAndConditionsModal } from "../../components/TermsAndConditionsModal";

type SecondStepProps = {
  data: any;
  onRegister: (value: any) => void;
  isFormDisabled: boolean;
  emailError: string | null;
};

export const SecondStep = ({
  data,
  onRegister,
  isFormDisabled,
  emailError,
}: SecondStepProps) => {
  const [form] = Form.useForm();

  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const [validateOnBlur] = useValidateOnBlur(form);

  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const onCloseTerms = () => setTermsTabId(undefined);

  const onRegisterClicked = (values: any) =>
    isPasswordValid && onRegister(values);

  useEffect(() => {
    emailError &&
      form.setFields([
        {
          name: "email",
          errors: [emailError],
        },
      ]);
  }, [emailError, form]);

  return (
    <Form
      initialValues={data}
      className="mt-10"
      form={form}
      layout="vertical"
      autoComplete="off"
      requiredMark={false}
      onFinish={onRegisterClicked}
      disabled={isFormDisabled}
    >
      <PhoneInput form={form} />

      <Form.Item
        name="email"
        label="Email"
        className="mb-5"
        rules={[
          {
            required: true,
            message: EMAIL_REQUIRED,
            validateTrigger: "onSubmit",
          },
          {
            pattern: EMAIL_RULE,
            message: WRONG_EMAIL_FORMAT,
            validateTrigger: "onSubmit",
          },
          {
            max: 100,
            message: MAX_EMAIL,
            validateTrigger: "onSubmit",
          },
        ]}
      >
        <Input size="large" onBlur={(e) => validateOnBlur(e, "email")} />
      </Form.Item>

      <PasswordInput
        setIsPasswordValid={setIsPasswordValid}
        form={form}
        label="Password"
      />

      <Row className="mt-2">
        <p className="my-0 text-sm">
          By selecting <strong>Agree and Sign Up</strong> below, you agree to
          Sunwest’s &nbsp;
          <strong
            className="ant-btn-link logo-cursor"
            onClick={() => setTermsTabId("1")}
          >
            Terms of Use
          </strong>
          &nbsp;and&nbsp;
          <strong
            className="ant-btn-link logo-cursor"
            onClick={() => setTermsTabId("2")}
          >
            Privacy Policy
          </strong>
          .
        </p>
      </Row>

      <Form.Item className="mt-3">
        <Button
          type="primary"
          shape="round"
          size="large"
          htmlType="submit"
          disabled={!isPasswordValid || isFormDisabled}
          block
        >
          Agree and Sign Up
          {isFormDisabled && <LoadingOutlined />}
        </Button>
      </Form.Item>

      <TermsAndConditionsModal
        termsTabId={termsTabId}
        setTermsTabId={setTermsTabId}
        onClose={onCloseTerms}
      />
    </Form>
  );
};
