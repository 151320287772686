import { Row, Col, Modal, Button, Tabs } from "antd";
import { MODAL_MASK_COLOR } from "../../lib/constants";
import "./styles.scss";
import { ReactNode, useMemo } from "react";
import { Terms } from "./components/Terms";
import { Policy } from "./components/Policy";

type TermsAndConditionsModalProps = {
  termsTabId: undefined | "1" | "2";
  setTermsTabId: (value: undefined | "1" | "2") => void;
  acceptButton?: {
    label: string;
    action: () => void;
  };
  isLoading?: boolean;
  onClose: () => void;
  closeLabel?: string;
};

export const TermsAndConditionsModal = ({
  termsTabId,
  setTermsTabId,
  acceptButton,
  isLoading,
  onClose,
  closeLabel = "Close",
}: TermsAndConditionsModalProps) => {
  const setIsOpen = (value: boolean): void => {
    value ? setTermsTabId("1") : onClose();
  };

  const header: ReactNode[] = useMemo(() => {
    return [
      <Row justify="start" key="modal-title">
        <Col>
          <div className={"header mt-0"}>Terms and Privacy</div>
        </Col>
      </Row>,
    ];
  }, []);

  const footer: ReactNode = useMemo(() => {
    return (
      <>
        {acceptButton && (
          <Button
            type="primary"
            shape="round"
            onClick={() => acceptButton.action()}
            className="mr-4"
            disabled={isLoading}
          >
            {acceptButton.label}
          </Button>
        )}
        <Button
          className="ml-0"
          type={acceptButton ? "default" : "primary"}
          shape="round"
          onClick={onClose}
        >
          {closeLabel}
        </Button>
      </>
    );
  }, [acceptButton, closeLabel, isLoading, onClose]);

  const tabs: any[] = useMemo(() => {
    return [
      {
        key: "1",
        label: "Terms of Use",
        children: <Terms />,
      },
      {
        key: "2",
        label: "Privacy Policy",
        children: <Policy />,
      },
    ];
  }, []);

  const onChangeTab = (key: string): void => {
    if (key === "1" || key === "2") {
      setTermsTabId(key);
    }
  };

  return (
    <Modal
      title={header}
      footer={footer}
      open={!!termsTabId}
      onCancel={() => setIsOpen(false)}
      className="terms-modal"
      width={900}
      centered
      maskStyle={{ backgroundColor: MODAL_MASK_COLOR }}
      wrapClassName="z-1000 position-fixed"
    >
      <Tabs items={tabs} activeKey={termsTabId} onChange={onChangeTab} />
    </Modal>
  );
};
