import { Col, Row, Form, Button, Input } from "antd";
import { Header } from "../../../components/SecurityCheck/Header";
import {
  EMAIL_REQUIRED,
  MAX_EMAIL,
  WRONG_EMAIL_FORMAT,
} from "../../../lib/constants";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";
import { EMAIL_RULE } from "../../../lib/validationRules";

type SecondStepProps = {
  onBackClicked: () => void;
  setNewEmail: (value: string) => void;
};

export const SecondStep = ({ onBackClicked, setNewEmail }: SecondStepProps) => {
  const [form] = Form.useForm();

  const [validateOnBlur] = useValidateOnBlur(form);

  const onConfirm = (values: any) => {
    setNewEmail(values.email);
  };

  return (
    <Row className="mt-16 justify-center">
      <Col className="security-check-content mb-8">
        <Header
          header="Enter your new email"
          onBackClicked={() => onBackClicked()}
        />

        <Row className="mt-10 d-flex justify-center">
          <Col className="form-content">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              className="form__container"
              onFinish={onConfirm}
            >
              <Form.Item
                name="email"
                label="Email"
                className="mb-5"
                rules={[
                  {
                    required: true,
                    message: EMAIL_REQUIRED,
                    validateTrigger: "onSubmit",
                  },
                  {
                    pattern: EMAIL_RULE,
                    message: WRONG_EMAIL_FORMAT,
                    validateTrigger: "onSubmit",
                  },
                  {
                    max: 100,
                    message: MAX_EMAIL,
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  size="large"
                  onBlur={(e) => validateOnBlur(e, "email")}
                />
              </Form.Item>
              <Form.Item className="mt-3 mb-12">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
