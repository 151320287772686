import { Button } from "antd";
import { GuestLink } from "../../types/GuestLink";
import { useMemo } from "react";

export const UsefulLinks = () => {
  const links: GuestLink[] = useMemo(
    () => [
      {
        header: "Forgot password",
        href: "/forgot-password",
      },
      {
        header: "Forgot email/username",
        href: "/forgot-account-details",
      },
    ],
    []
  );

  return (
    <>
      <div className="useful-links__container mt-12 d-flex justify-space-between">
        <div className="d-flex flex-column align-start">
          {links.map((link) => {
            return (
              <Button
                type="link"
                className="pa-0"
                href={link.href}
                key={link.header}
              >
                {link.header}
              </Button>
            );
          })}
        </div>
        <div className="no-account__container d-flex justify-center">
          <span>New to the service?</span>
          <Button type="link" className="height-0" href="/signup">
            Sign Up
          </Button>
        </div>
      </div>
    </>
  );
};
