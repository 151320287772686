import { SET_REGISTERED_COUNT } from "../acions/types";

const initialState = { count: 0 };

export default function requestUsers(state = initialState, action: any) {
  const { type, payload } = action;

  switch (type) {
    case SET_REGISTERED_COUNT:
      return {
        ...state,
        count: payload,
      };
    default:
      return state;
  }
}
