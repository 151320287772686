import { Col, Row } from "antd";
import { useEffect, useMemo, useState } from "react";
import { Loader } from "../../../../../components/Loader";
import { UsersService } from "../../../../../services/users";
import { getFormatted } from "../../../../../lib/PhoneFormatter";
import { EditProfile } from "./EditProfile";

type ProfileTabProps = {
  userId: string | null;
  setUser: (value: any) => void;
  isEdit: boolean;
  roles: any[];
  isSaveClicked: boolean;
  setIsSaveClicked: (value: boolean) => void;
  reloadData: () => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
};

export const ProfileTab = ({
  userId,
  setUser,
  isEdit,
  roles,
  setIsSaveClicked,
  isSaveClicked,
  reloadData,
  isLoading,
  setIsLoading,
}: ProfileTabProps) => {
  const [data, setData] = useState<any>(null);

  const fullName = useMemo(
    () => `${data?.firstName} ${data?.lastName}`,
    [data]
  );

  const formattedPhone = useMemo(
    () => getFormatted(data?.phone).slice(2),
    [data]
  );

  useEffect(() => {
    if (userId) {
      setIsLoading(true);
      UsersService.fetchUser(userId)
        .then((res) => {
          setData(res);
          setUser(res);
          setIsLoading(false);
        })
        .catch(() => setIsLoading(false));
    }
  }, [userId, setUser, setIsLoading]);

  const getRow = (label: string, value: string, isTitle: boolean) => {
    return (
      <Row className="align-center mb-4">
        <Col span={8}>
          <span className="text-md title">{label}</span>
        </Col>
        <Col span={16}>
          <div className="elipsis" title={isTitle ? value : ""}>
            {value}
          </div>
        </Col>
      </Row>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <>
          {!isEdit ? (
            <div className="py-8 px-6">
              {getRow("Name", fullName, true)}
              {getRow("Username", data?.userName || "-", true)}
              {getRow("Email", data?.email, true)}
              {getRow("Phone", formattedPhone, false)}
              {getRow(
                "Email notice",
                data?.isPaymentNoticeEmailed ? "Subscribed" : "Not Subscribed",
                false
              )}
              {getRow("Role", data?.role, false)}
              {data?.role === "User" && getRow("Access Number", data?.accessNumber, true)}
            </div>
          ) : (
            <EditProfile
              user={data}
              roles={roles}
              isSaveClicked={isSaveClicked}
              setIsSaveClicked={setIsSaveClicked}
              reloadData={reloadData}
            />
          )}
        </>
      )}
    </>
  );
};
