import { Col, Row, Form, Button } from "antd";
import { Header } from "../../../components/SecurityCheck/Header";
import { PhoneInput } from "../../../components/PhoneInput";

type SecondStepProps = {
  onBackClicked: () => void;
  setNewPhone: (value: string) => void;
};

export const SecondStep = ({ onBackClicked, setNewPhone }: SecondStepProps) => {
  const [form] = Form.useForm();

  const onConfirm = (values: any) => {
    setNewPhone(values.phone);
  };

  return (
    <Row className="mt-16 justify-center">
      <Col className="security-check-content mb-8">
        <Header
          header="Enter your new phone number"
          onBackClicked={() => onBackClicked()}
        />

        <Row className="mt-10 d-flex justify-center">
          <Col className="form-content">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              className="form__container"
              onFinish={onConfirm}
            >
              <PhoneInput form={form} label="New Phone Number" />

              <Form.Item className="mt-3 mb-12">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  block
                >
                  Confirm
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
