export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const UPDATE_USER_DATA = "UPDATE_USER_DATA";

export const CONFIRM_CODE_SUCCESS = "CONFIRM_CODE_SUCCESS";
export const CONFIRM_CODE_FAIL = "CONFIRM_CODE_FAIL";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

export const SET_REGISTERED_COUNT = "SET_REGISTERED_COUNT";
