import { Area } from "@ant-design/plots";
import { CHART_BLUE_7, DATE_FORMAT } from "../../../lib/constants";
import { useEffect, useState, useMemo } from "react";
import { DashboardService } from "../../../services/dashboard";
import moment from "moment";
import { Spin } from "antd";
import { KPI_CODES } from "../kpiCodes";

type LineChartCardProps = {
  totalImpound: number | null;
  isDataLoading: boolean;
};

export const LineChartCard = ({
  totalImpound,
  isDataLoading,
}: LineChartCardProps) => {
  const [data, setData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const now = useMemo(() => moment().format(DATE_FORMAT), []);

  useEffect(() => {
    const now = moment();

    const request = {
      DateTo: now.format("YYYY-MM-DD"),
      Datefrom: now.subtract(6, "months").format("YYYY-MM-DD"),
      KpiCode: KPI_CODES.IMPOUNDS_TOTAL_BALANCE,
    };
    DashboardService.fetchDashboardHistoryData(request)
      .then((res) => {
        if (res.length > 0) {
          const value = res
            .map((item: any) => {
              const month = moment(item.createdAt).format("MMM");

              return {
                month,
                amount: item.kpiValues[0].value,
                tooltipValue: `${month}, ${moment(item.createdAt).format(
                  "YYYY"
                )}`,
              };
            })
            .reverse();

          setData(value);
        }

        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, []);

  const config = {
    data,
    padding: "auto" as const,
    xField: "month",
    yField: "amount",
    height: 264,
    color: CHART_BLUE_7,
    xAxis: {
      tickCount: 5,
    },
    areaStyle: () => {
      return {
        fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
      };
    },
    tooltip: {
      customContent: (title: any, data: any) => {
        return data.length > 0
          ? `<div class="px-3 py-2"><div class="text-sm w-400 pb-1">${
              data[0].data.tooltipValue
            }</div><div class="text-sm w-500">${data[0].data.amount.toLocaleString()}k</div></div>`
          : "";
      },
    },
  };
  return (
    <>
      {!isLoading && !isDataLoading ? (
        <div>
          <div className="d-flex align-end mb-6">
            <h3 className="mt-0 h h3">
              {totalImpound ? `$${totalImpound.toLocaleString()}K` : ""}
            </h3>
            <p className="text mt-0 ml-2" style={{ marginBottom: "1px" }}>
              by {now}
            </p>
          </div>

          <Area {...config} />
        </div>
      ) : (
        <div className="h-100 d-flex justify-center align-center">
          <Spin size="large" />
        </div>
      )}
    </>
  );
};
