import { LoadingOutlined } from "@ant-design/icons";

type LoaderProps = {
  fontSize: string;
};

export const Loader = ({ fontSize }: LoaderProps) => {
  return (
    <div className="d-flex justify-center align-center loader__container">
      <LoadingOutlined className="loader" style={{ fontSize: fontSize }} />
    </div>
  );
};
