import { Button, Row } from "antd";
import { MultiSelect } from "../../components/MultiSelect";
import { SearchWithClear } from "../../components/SearchWithClear";
import {
  ACCOUNT_STATUSES,
  LAST_PAYMENT_OPTIONS,
  NEXT_PAYMENT_OPTIONS,
} from "../../lib/constants";
import { ExportOutlined } from "@ant-design/icons";
import { useEffect, useRef, useState } from "react";
import { useSearch } from "../../lib/hooks/search";
import { useExportExcel } from "./hooks/exportExcel";
import { useGetRole } from "../../lib/hooks/getRole";

type FiltersProps = {
  request: any;
  statusesInitialState: (string | number)[];
  lastPaymnetDateInitialState: (string | number)[];
  isLoading: boolean;
  isOther: boolean;
  setRequest: (value: any) => void;
};

export const Filters = ({
  request,
  statusesInitialState,
  lastPaymnetDateInitialState,
  isLoading,
  isOther,
  setRequest,
}: FiltersProps) => {
  const mountedRef = useRef<boolean>(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  const [search, debouncedSearch, onSearchChange, onClearSearch] = useSearch();

  const [statuses, setStatuses] =
    useState<(string | number)[]>(statusesInitialState);

  const [nextPaymnetDates, setNextPaymnetDates] = useState<(string | number)[]>(
    ["All"]
  );

  const [lastPaymentDates, setLastPaymentDates] = useState<(string | number)[]>(
    lastPaymnetDateInitialState
  );

  const { onExportExcelButtonClicked, isLoading: isExportLoading } =
    useExportExcel(request);

  useEffect(() => {
    if (mountedRef.current) {
      const newRequest: any = { ...request, pageNumber: 1 };

      if (statuses.includes("All")) {
        delete newRequest["statusFilter"];
      } else {
        newRequest["statusFilter"] = statuses;
      }

      if (nextPaymnetDates.includes("All")) {
        delete newRequest["dateFilterNextPayment"];
      } else {
        newRequest["dateFilterNextPayment"] = nextPaymnetDates[0];
      }

      if (lastPaymentDates.includes("All")) {
        delete newRequest["dateFilter"];
      } else {
        newRequest["dateFilter"] = lastPaymentDates[0];
      }

      if (search) {
        newRequest["search"] = debouncedSearch;
      } else {
        delete newRequest["search"];
      }

      setRequest(newRequest);
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [statuses, nextPaymnetDates, debouncedSearch, lastPaymentDates]);

  return (
    <Row className="d-block width-100">
      <Row className="title">
        <h3 className="h h3">{isOther && "Other"} Accounts</h3>
      </Row>
      <Row className={`mb-4 ${isAdmin ? "justify-space-between" : ""}`}>
        <div className="d-flex filters">
          {isAdmin && (
            <>
              <div className="mr-4 date-selector">
                <SearchWithClear
                  onSearchChange={onSearchChange}
                  isLoading={isLoading}
                  search={search}
                  onClear={onClearSearch}
                />
              </div>
              {!isOther && (
                <>
                  <div className="mr-4 date-selector">
                    <MultiSelect
                      options={LAST_PAYMENT_OPTIONS}
                      isLoading={isLoading}
                      onChange={setLastPaymentDates}
                      label="Last Payment"
                      defaultValue={lastPaymentDates}
                    />
                  </div>
                  <div className="mr-4 date-selector">
                    <MultiSelect
                      options={NEXT_PAYMENT_OPTIONS}
                      isLoading={isLoading}
                      onChange={setNextPaymnetDates}
                      label="Next Payment"
                      defaultValue={nextPaymnetDates}
                    />
                  </div>
                </>
              )}
            </>
          )}
          {!isOther && (
            <MultiSelect
              options={ACCOUNT_STATUSES}
              isLoading={isLoading}
              onChange={setStatuses}
              label="Status"
              defaultValue={statuses}
            />
          )}
        </div>
        {isAdmin && (
          <Button
            shape="round"
            onClick={onExportExcelButtonClicked}
            disabled={isExportLoading}
          >
            <ExportOutlined />
            Export
          </Button>
        )}
      </Row>
    </Row>
  );
};
