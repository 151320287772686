import { Tag } from "antd";
import moment from "moment";
import { ColumnsType } from "antd/es/table";
import { DATE_FORMAT } from "../../lib/constants";
import { getFormattedCurrency } from "../../lib/currencyFormatter";

export const getTagColorProps = (value: string) => {
  let color: string | undefined;

  if (value === "Active") {
    color = "green";
  } else if (value === "Default") {
    color = "volcano";
  } else if (value === "Inactive") {
    color = "orange";
  }

  const props: any = {};

  if (color) {
    props.color = color;
  }

  return props;
};

export const onSortChange = (
  sorter: any,
  setRequest: (value: any) => void,
  request: any
) => {
  const newRequest = { ...request };
  if (!sorter.order) {
    delete newRequest["order"];
  }

  if (sorter.field === "accountNumber") {
    if (sorter.order === "ascend") {
      newRequest["order"] = 0;
    }

    if (sorter.order === "descend") {
      newRequest["order"] = 1;
    }
  }

  if (sorter.field === "status") {
    if (sorter.order === "ascend") {
      newRequest["order"] = 2;
    }

    if (sorter.order === "descend") {
      newRequest["order"] = 3;
    }
  }

  setRequest(newRequest);
};

export const getTableColumns = (
  setSelectedContract: (value: any) => void
): ColumnsType<any> => [
  {
    title: "Account #",
    dataIndex: "accountNumber",
    key: "accountNumber",
    render: (value: string, record: any) => (
      <div
        className="cell-elipsis"
        title={value}
        onClick={() => setSelectedContract(record)}
      >
        <span className="link-btn">{value}</span>
      </div>
    ),
    sorter: true,
    width: "13%",
  },
  {
    title: "Buyer",
    dataIndex: "buyer",
    key: "buyer",
    width: "17%",
    ellipsis: true,
    render: (value: string) => (
      <div className="elipsis" title={value}>
        {value}
      </div>
    ),
    responsive: ["xl"],
  },
  {
    title: "Seller",
    dataIndex: "seller",
    key: "seller",
    width: "17%",
    ellipsis: true,
    render: (value: string) => (
      <div className="elipsis" title={value}>
        {value}
      </div>
    ),
    responsive: ["xl"],
  },
  {
    title: "Property Address",
    dataIndex: "propertyAddress",
    key: "propertyAddress",
    width: "18%",
    ellipsis: true,
    render: (value: string) => (
      <div className="elipsis" title={value}>
        {value || "-"}
      </div>
    ),
    responsive: ["xl"],
  },
  {
    title: "Last Payment",
    dataIndex: "lastPaymentDate",
    key: "lastPaymentDate",
    render: (value: string) =>
      value ? moment(value).format(DATE_FORMAT) : "-",
    width: "10%",
    responsive: ["xl"],
  },
  {
    title: "Next Payment",
    dataIndex: "nextPayment",
    key: "nextPayment",
    align: "right",
    render: (value: any) => {
      const dateToDisplay = value.paymentDate
        ? moment(value.paymentDate).format(DATE_FORMAT)
        : "-";

      const isPast = moment().isAfter(moment(value.paymentDate));
      const amount = getFormattedCurrency(value.amount);

      return (
        <div className="next-payment-col d-flex justify-end">
          <div className="elipsis" title={amount}>
            {amount}
          </div>
          <div className="date-val">
            &nbsp;to&nbsp;
            <span className={isPast ? "error" : ""}>{dateToDisplay}</span>
          </div>
        </div>
      );
    },
    ellipsis: true,
    width: "16%",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (value: string) =>
      value ? <Tag {...getTagColorProps(value)}>{value}</Tag> : "No Status",
    sorter: true,
    width: "9%",
  },
];
