import { Button, Card } from "antd";
import { RightOutlined } from "@ant-design/icons";
import { useMemo, useState } from "react";
import { SideBarContainer } from "../../../../components/SideBarContainer";
import { createPortal } from "react-dom";

export const Disclaimer = () => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const sideBar = useMemo(
    () => (
      <SideBarContainer
        title={<h4 className="h h4">Disclaimers</h4>}
        setShowSideBar={setShowSideBar}
      >
        <div className="py-8 px-6 overflow-y-auto disclamer-text">
          <div className="pb-8">
            <h5 className="my-0 h h5 pb-4">Balance and transaction history</h5>
            <p className="my-0 pb-4 text-md">
              This balance and transaction history is provided for information
              purposes only and should not be used for payoff purposes.
            </p>
            <p className="my-0 pb-4 text-md">
              The information applies to all borrowers and sellers of records
              for the entire 2023 calendar year. This includes borrowers and
              sellers that had their responsibility or ownership in this account
              transferred and, therefore, are no longer borrowers and sellers on
              this account. This also includes other borrowers and sellers, in
              the case where multiple borrowers and sellers exist for this
              account.
            </p>
            <p className="my-0 pb-4 text-md">
              The above information would apply to you in its entirety only if
              this account and did not have any responsibility or ownership
              transferred in 2023 and you are the only borrower or seller of
              record in 2023.
            </p>
            <p className="my-0 text-md">
              Please consult with the IRS or your tax advisor for full reporting
              requirements.
            </p>
          </div>
          <div>
            <h5 className="my-0 h h5 pb-4">Payment</h5>
            <p className="my-0 pb-4 text-md">
              Please be advised that it may take up to 24 hours to process the
              transaction. All payments will be posted as of the payment
              effective date and not the payment initiation date.
            </p>
            <p className="my-0 pb-4 text-md">
              The availability of funds will be subject to your bank's policies
              and procedures. If you are required to pay in Certified Funds and
              an online payment is received, it will be held for five business
              days before your payment will be posted.
            </p>
            <p className="my-0 pb-4 text-md">
              Payment will not be accepted if less than the Regular Payment
              Amount. If an additional amount is entered, the 'Payment Amount'
              should be the sum of 'Reg Payment Amt' and 'Additional Amount'.
            </p>
            <p className="my-0 text-md">
              We kindly request your patience during this processing period. If
              you have any questions or concerns, please don't hesitate to{" "}
              <a href="mailto:info@sunwestescrow.com" className="ant-btn-link">
                contact our customer support
              </a>{" "}
              team. We appreciate your understanding and value your business.
            </p>
          </div>
        </div>
      </SideBarContainer>
    ),
    []
  );

  const onOpenSideBar = () => {
    setShowSideBar(true);
  };

  return (
    <Card className="mt-4 disclamer">
      <div>
        <p className="mt-0 mb-5 text-sm title">
          Balance and transaction history provided for information only.
          Applicable to all borrowers and sellers, regardless of ownership
          transfers. Consult IRS/tax advisor for reporting.
        </p>
        <p className="mt-0 mb-4 text-sm title">
          Payments processed within 24 hours. Funds availability subject to bank
          policies. Certified Funds online held for 5 business days. Payment
          amount must meet or exceed Regular Payment Amount.
        </p>
        <p className="mt-0 mb-4 text-sm title">
          Contact customer support for assistance. Thank you for your business
          and understanding.
        </p>
      </div>

      <Button type="link" className="text-sm py-0 px-0" onClick={onOpenSideBar}>
        Read more about balance and payments
        <RightOutlined className="ml-1" />
      </Button>

      {showSideBar && createPortal(sideBar, document.body)}
    </Card>
  );
};
