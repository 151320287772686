import { useMemo } from "react";
import { ExclamationCircleFilled } from "@ant-design/icons";

export const useGetDeleteDialogProps = (isDeleting: boolean) => {
  const options: any = useMemo(() => {
    return {
      icon: <ExclamationCircleFilled />,
      okText: "Delete",
      okType: "primary",
      okButtonProps: {
        shape: "round",
        danger: true,
        disabled: isDeleting,
      },
      cancelButtonProps: {
        shape: "round",
        disabled: isDeleting,
      },
    };
  }, [isDeleting]);

  return [options];
};
