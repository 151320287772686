import { useEffect, useState } from "react";
import { UserRegistrationService } from "../../../../services/registrationRequests";
import moment from "moment";
import { DEFAULT_TIME_FORMAT } from "../../../../lib/constants";
import { Loader } from "../../../../components/Loader";
import { UserEvents } from "../../../UserManagement/SideBar/UserContent/HistoryTab/UserEvents";

type ContentProps = {
  userId: string;
};

export const Content = ({ userId }: ContentProps) => {
  const [data, setData] = useState<any[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);

    UserRegistrationService.fetchRejectedRegistrationEvents(userId)
      .then((res) => {
        const formatted = res.map((item: any) => ({
          ...item,
          createdAt: moment(item.createdAt).format(DEFAULT_TIME_FORMAT),
        }));
        setData(formatted);
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  }, [userId]);

  return (
    <>
      {isLoading ? (
        <Loader fontSize="64px" />
      ) : (
        <div className="py-8 px-6">
          <UserEvents data={data} />
        </div>
      )}
    </>
  );
};
