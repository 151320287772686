import { Outlet } from "react-router-dom";
import { useGetRole } from "../../lib/hooks/getRole";
import { NotFound } from "../../pages/NotFound";

export const AdminScope = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, isAdmin] = useGetRole();

  return isAdmin ? <Outlet /> : <NotFound />;
};
