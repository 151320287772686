import { FirstStep } from "./FirstStep";
import { useState, useMemo, useEffect, useCallback } from "react";
import { SecurityCheck } from "../../components/SecurityCheck";
import { ThirdStep } from "./ThirdStep";
import { clearMessage } from "../../acions/message";
import { useAppDispatch } from "../../store";
import {
  confirmVerificationCode,
  sendVerificationCode,
  show,
} from "../../acions/auth";
import { useGetFormattedPhoneSecure } from "../../lib/hooks/getFormattedPhoneSecure";
import { FirstStepError } from "./FirstStepError";

export const ForgotAccount = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<any>({});

  const [step, setStep] = useState<string>("first");

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const [verificationCodeId, setVerificationCodeId] = useState<string | null>(
    null
  );

  const [isMultipleUsers, setIsMultipleUsers] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>("");

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  const [foundData, setFoundData] = useState<any>({});

  const [formattedPhone] = useGetFormattedPhoneSecure(data);

  const onSwitchToSecondStep = (values: any) => {
    setIsFormDisabled(true);
    setPhone(`1${values.phone.replace(/[\s-()]/g, "")}`);
    setData(values);
  };

  const sendCode = useCallback(async () => {
    dispatch(clearMessage());
    setIsConfirmDisabled(true);
    try {
      const response: any = await Promise.resolve(
        dispatch(sendVerificationCode("ForgotUsername", { ...data, phone }))
      );
      setVerificationCodeId(response.data.verificationCodeId);
      setIsFormDisabled(false);
      setIsConfirmDisabled(false);
      setStep("second");
    } catch (err: any) {
      err.isMultipleUsers && setIsMultipleUsers(true);
      setIsConfirmDisabled(false);
      setIsFormDisabled(false);
    }
  }, [data, dispatch, phone]);

  useEffect(() => {
    Object.keys(data).length > 0 && sendCode();
  }, [data, sendCode]);

  const securityCheckSubTitle = useMemo(
    () => (
      <div className="sub-title__container mb-10">
        <p className="justify-center my-0">We just want to be sure it’s you.</p>
        <p className="justify-center my-0">
          Enter the code we’ve just sent to
          <span className="email-text">&ensp;{formattedPhone}</span>
        </p>
      </div>
    ),
    [formattedPhone]
  );

  const onSuccess = (values: any) => {
    setIsConfirmDisabled(true);
    dispatch(clearMessage());
    const code = values.securityCode.replace(/-/g, "");

    const request: any = {
      code,
      verificationCodeId,
      ...data,
      phone,
    };

    Promise.resolve(
      dispatch(confirmVerificationCode("ForgotUsername", request))
    )
      .then(() => {
        Promise.resolve(dispatch(show({ ...data, phone, verificationCodeId })))
          .then((res: any) => {
            setIsConfirmDisabled(false);
            setFoundData(res);
            setStep("third");
          })
          .catch(() => {
            setIsConfirmDisabled(false);
          });
      })
      .catch(() => {
        setIsConfirmDisabled(false);
      });
  };

  return (
    <>
      {step === "first" && (
        <>
          {!isMultipleUsers ? (
            <FirstStep
              data={data}
              isFormDisabled={isFormDisabled}
              onSwitchToSecondStep={onSwitchToSecondStep}
            />
          ) : (
            <FirstStepError onBackClicked={() => setIsMultipleUsers(false)} />
          )}
        </>
      )}

      {step === "second" && (
        <SecurityCheck
          header="Quick security check"
          setStep={() => setStep("first")}
          isRememberCode={false}
          isTimer={true}
          subTitle={securityCheckSubTitle}
          onConfirm={(values) => onSuccess(values)}
          type="SMS"
          isConfirmDisabled={isConfirmDisabled}
          onResend={() => sendCode()}
        />
      )}

      {step === "third" && <ThirdStep setStep={setStep} data={foundData} />}
    </>
  );
};
