import { Select } from "antd";
import { useMemo, useState } from "react";

type PaymentNumberCardProps = {
  isLoading: boolean;
  paymnentAmounts: any[] | null;
};

export const PaymentNumberCard = ({
  isLoading,
  paymnentAmounts,
}: PaymentNumberCardProps) => {
  const [selected, setSelected] = useState<string>("lastDay");

  const paymentOptions = useMemo(
    () => [
      { value: "lastDay", label: "Last Day" },
      { value: "thisMonth", label: "This Month" },
    ],
    []
  );

  const amount = useMemo(() => {
    if (paymnentAmounts) {
      return paymnentAmounts.find((item: any) => item.label === selected).value;
    }

    return "";
  }, [selected, paymnentAmounts]);

  return (
    <>
      <div className="d-flex justify-space-between mb-2">
        <p className="my-0 text-md">Payments Number</p>
        <Select
          value={selected}
          disabled={isLoading}
          options={paymentOptions}
          size="small"
          onChange={setSelected}
        />
      </div>
      <h3 className="my-0 h3">{amount.toLocaleString()}</h3>
    </>
  );
};
