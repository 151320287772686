import { FirstStep } from "./FirstStep";
import { useState } from "react";
import { SecondStep } from "./SecondStep";

export const ForgotPassword = () => {
  const [step, setStep] = useState<boolean>(false);

  const [data, setData] = useState<any>({});

  return (
    <>
      {!step ? (
        <FirstStep setStep={setStep} data={data} setData={setData} />
      ) : (
        <SecondStep userName={data.userName} />
      )}
    </>
  );
};
