import { Input, FormInstance, Form } from "antd";
import MaskedInput from "react-text-mask";
import { WRONG_PHONE_FORMAT, PHONE_REQUIRED } from "../../lib/constants";
import { PHONE_MASK_RULE, PHONE_RULE } from "../../lib/validationRules";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";

type PhoneInputProps = {
  form: FormInstance;
  label?: string;
  size?: "large" | "middle";
  formItemMargin?: string;
  initialValue?: string;
  disabled?: boolean;
};

export const PhoneInput = ({
  form,
  label,
  size,
  formItemMargin,
  initialValue,
  disabled,
}: PhoneInputProps) => {
  const [validateOnBlur] = useValidateOnBlur(form);

  return (
    <Form.Item
      name="phone"
      label={label || "Phone"}
      className={formItemMargin || "mb-5"}
      initialValue={initialValue || ""}
      rules={[
        {
          required: true,
          message: PHONE_REQUIRED,
          validateTrigger: "onSubmit",
        },
        {
          pattern: PHONE_RULE,
          message: WRONG_PHONE_FORMAT,
          validateTrigger: "onSubmit",
        },
      ]}
    >
      <MaskedInput
        mask={PHONE_MASK_RULE}
        guide={false}
        showMask
        render={(ref, props) => {
          const value = form.getFieldValue("phone");

          const newProps = {
            ...props,
            onBlur: (e: any) => validateOnBlur(e, "phone"),
          };

          return (
            <Input
              disabled={disabled || false}
              addonBefore="+1"
              value={value}
              size={size || "large"}
              ref={(input: any) => ref(input && input.input)}
              {...newProps}
            />
          );
        }}
      />
    </Form.Item>
  );
};
