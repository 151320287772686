import axios from "axios";
import { User } from "../types/User";
import Cookies from "js-cookie";
import { RegisterRequest } from "../types/RegisterRequest";
import { ConfirmRequest } from "../types/ConfirmRequest";
import authAxios, { authHeader } from "./data";
import { API_URL } from "../configs/apiUrl";

const sendVerificationCode = async (type: string, values: any) => {
  return await axios.post(API_URL + `${type}/SendVerificationCode`, values);
};

const confirmVerificationCode = async (
  type: string,
  values: ConfirmRequest
) => {
  return await axios.post(API_URL + `${type}/ConfirmVerificationCode`, values);
};

const register = async (values: RegisterRequest) => {
  return await axios.post(API_URL + "Register/Register", values);
};

const loginFirstStep = async (values: any) => {
  const response = await axios.post(API_URL + "Auth/LoginFirstStep", values);

  return response.data;
};

const loginSecondStep = async (user: User) => {
  const response = await axios.post(API_URL + "Auth/LoginSecondStep", user);

  return response.data;
};

const logout = async () => {
  const config: any = {
    headers: authHeader(),
  };

  return await authAxios.post(API_URL + "Auth/Logout", {}, config);
};

const acceptAgreements = async () => {
  const config: any = {
    headers: authHeader(),
  };

  const response = await authAxios.post(API_URL + "UserAgreements", {}, config);

  return response.data;
};

const refresh = async () => {
  const config: any = {
    headers: authHeader(),
  };

  const response = await axios.post(API_URL + "Auth/Refresh", {}, config);

  return response.data;
};

const isLoggedIn = () => {
  return !!localStorage.getItem("userData");
};

const getRememberMeCode = (): string | null => {
  let rememberDeviceCode: string | undefined | null =
    Cookies.get("rememberMeCode");

  if (rememberDeviceCode === "null" || !rememberDeviceCode) {
    rememberDeviceCode = null;
  }

  return rememberDeviceCode;
};

const requestPasswordChange = async (values: any) => {
  const response = await axios.post(
    API_URL + "ForgotPassword/RequestPasswordChange",
    values
  );

  return response.data;
};

const changePassword = async (values: any) => {
  const response = await axios.post(
    API_URL + "ForgotPassword/ChangePassword",
    values
  );

  return response.data;
};

const show = async (values: any) => {
  const response = await axios.post(API_URL + "ForgotUsername/Show", values);

  return response.data;
};

const AuthService = {
  register,
  loginSecondStep,
  isLoggedIn,
  sendVerificationCode,
  confirmVerificationCode,
  loginFirstStep,
  logout,
  refresh,
  getRememberMeCode,
  requestPasswordChange,
  changePassword,
  show,
  acceptAgreements,
};

export default AuthService;
