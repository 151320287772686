import "./styles.scss";
import { Form, Input } from "antd";
import { useState, useEffect, useCallback } from "react";
import { PASSWORD_RULE } from "../../lib/validationRules";
import { CapsLockHint, useToggleCapsLock } from "../../components/CapsLockHint";
import { CheckCircleFilled } from "@ant-design/icons";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";
import { INCORRECT_PASSWORD_MSG } from "../../lib/constants";

type PasswordInputProps = {
  setIsPasswordValid: (value: boolean) => void;
  form: any;
  label: string;
};

export const PasswordInput = ({
  setIsPasswordValid,
  form,
  label,
}: PasswordInputProps) => {
  const [rulesChecked, setRulesChecked] = useState<any>({});

  const [isCapsLockOn, onPasswordType] = useToggleCapsLock(false);

  const [validateOnBlur] = useValidateOnBlur(form);

  const value = form.getFieldValue("password");

  useEffect(() => {
    value && validate(value);
  }, [value]);

  useEffect(() => {
    setIsPasswordValid(Object.keys(rulesChecked).length === 5);
  }, [rulesChecked, setIsPasswordValid]);

  const validate = (value: string) => {
    const newRulesChecked: any = {};

    if (/[A-Z]/.test(value)) {
      newRulesChecked.UppercaseChecked = true;
    }

    if (/[a-z]/.test(value)) {
      newRulesChecked.DefaultChecked = true;
    }

    if (value.length >= 10 && value.length <= 30) {
      newRulesChecked.LengthChecked = true;
    }

    if (/[0-9]/.test(value)) {
      newRulesChecked.NumberChecked = true;
    }

    if (/[!@#$%^&*]/.test(value)) {
      newRulesChecked.specialCharsChecked = true;
    }

    setRulesChecked({ ...newRulesChecked });
  };

  const getRuleContent = (statement: boolean) => {
    return statement ? <CheckCircleFilled /> : <div className="dot" />;
  };

  const handleValidationOnBlur = useCallback(
    (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (Object.keys(rulesChecked).length === 5) {
        validateOnBlur(e, "password");
      }
    },
    [rulesChecked, validateOnBlur]
  );

  return (
    <>
      <div className="position-relative">
        <Form.Item
          className="mb-0"
          name="password"
          label={label}
          rules={[
            { required: true, message: "", validateTrigger: "onSubmit" },
            {
              pattern: PASSWORD_RULE,
              message: INCORRECT_PASSWORD_MSG,
              validateTrigger: "onSubmit",
            },
          ]}
        >
          <Input.Password
            size="large"
            onChange={(e) => validate(e.target.value)}
            onBlur={(e) => handleValidationOnBlur(e)}
            onKeyUp={onPasswordType}
          />
        </Form.Item>

        {isCapsLockOn && <CapsLockHint />}
      </div>

      <div className="password-requirements">
        <div className="mr-2">
          <span className="d-block mt-1" style={{ height: "16px" }}>
            Strong password has minimum:
          </span>
          <ul className="mb-0 mt-0 pl-0">
            <li className={rulesChecked.UppercaseChecked ? "checked" : ""}>
              {getRuleContent(rulesChecked.UppercaseChecked)}
              One uppercase character (A-Z)
            </li>
            <li className={rulesChecked.DefaultChecked ? "checked" : ""}>
              {getRuleContent(rulesChecked.DefaultChecked)}
              One lowercase character (a-z)
            </li>
          </ul>
        </div>
        <ul className="pl-0">
          <li className={rulesChecked.LengthChecked ? "checked" : ""}>
            {getRuleContent(rulesChecked.LengthChecked)}
            10 characters (30 max)
          </li>
          <li className={rulesChecked.NumberChecked ? "checked" : ""}>
            {getRuleContent(rulesChecked.NumberChecked)}
            One number (0-9)
          </li>
          <li className={rulesChecked.specialCharsChecked ? "checked" : ""}>
            {getRuleContent(rulesChecked.specialCharsChecked)}
            One symbol (!@#$%^&*)
          </li>
        </ul>
      </div>
    </>
  );
};
