import AccountsService from "../../../services/accounts";
import { saveAs } from "file-saver";
import { useState } from "react";

export const useExportExcel = (request: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onExportExcelButtonClicked = (): void => {
    setIsLoading(true);
    AccountsService.getExcel(request)
      .then((res) => {
        const blob = new Blob([res], {
          type: "application/octet-stream",
        });
        saveAs(blob, "accounts.xlsx");
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return { onExportExcelButtonClicked, isLoading };
};
