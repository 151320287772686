import { Col, Row } from "antd";
import { useMemo } from "react";
import { getFormatted } from "../../../lib/PhoneFormatter";
import moment from "moment";
import { DEFAULT_TIME_FORMAT } from "../../../lib/constants";

type InfoProps = {
  data: any;
};

export const Info = ({ data }: InfoProps) => {
  const getRow = (label: string, value: string, isTitle: boolean) => {
    return (
      <Row className="align-center mb-4">
        <Col span={9}>
          <span className="text-md title">{label}</span>
        </Col>
        <Col span={15}>
          <div className="elipsis" title={isTitle ? value : ""}>
            {value}
          </div>
        </Col>
      </Row>
    );
  };

  const formattedPhone = useMemo(
    () => getFormatted(data?.phone).slice(2),
    [data]
  );

  return (
    <Row className="mx-6 pt-8 info-container d-block">
      {data && (
        <>
          <h5 className="h h5 mb-4">Profile</h5>
          {getRow("Name", data?.fullName, true)}
          {getRow("Email", data?.email, true)}
          {getRow("Phone", formattedPhone, false)}
          <div className="pb-8 pt-4 mb-8 info-account__container">
            <h5 className="h h5 mb-4">Account</h5>
            {getRow("Account Number", data?.registrationAccountNumber, false)}
            {getRow("Tax ID", `•••••${data?.registrationTaxIdDigits}`, false)}
            {getRow(
              "Trade Role",
              data?.registrationType === 0 ? "Buyer" : "Seller",
              false
            )}
          </div>
          {data?.accessRequestedDateTime && (
            <span className="text-md title">{`Access requested ${moment(
              data?.accessRequestedDateTime
            ).format(DEFAULT_TIME_FORMAT)}`}</span>
          )}
        </>
      )}
    </Row>
  );
};
