import { Form, Row, Col, Input, Radio, Button } from "antd";
import {
  FIRST_NAME_REQUIRED,
  WRONG_ACCOUNT_NUMBER,
  ACCOUNT_NUMBER_REQUIRED,
  WRONG_TAX_ID,
  TAX_ID_REQUIRED,
  LAST_NAME_REQUIRED,
  WRONG_FIRST_NAME,
  WRONG_LAST_NAME,
  MAX_FIRSTNAME,
  MAX_LASTNAME,
  MAX_ACCOUNT_NUMBER,
} from "../../lib/constants";
import { useState } from "react";
import {
  ACCOUNT_NUMBER_RULE,
  NAME_RULE,
  TAX_ID_RULE,
} from "../../lib/validationRules";
import { useValidateOnBlur } from "../../lib/hooks/validateOnBlur";

type FirstStepProps = {
  onNextClicked: (values: any) => void;
  data: any;
};

export const FirstStep = ({ onNextClicked, data }: FirstStepProps) => {
  const [form] = Form.useForm();

  const [customerType, setCustomerType] = useState<number>(0);

  const [validateOnBlur] = useValidateOnBlur(form);

  const onFormValuesChange = (values: any) => {
    if (values.hasOwnProperty("registrationType")) {
      setCustomerType(values.registrationType);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      autoComplete="off"
      requiredMark={false}
      onValuesChange={onFormValuesChange}
      initialValues={data}
      onFinish={onNextClicked}
    >
      <Row>
        <Col span={12}>
          <Form.Item
            className="mr-3 mb-5"
            name="firstName"
            label="First Name"
            rules={[
              {
                required: true,
                message: FIRST_NAME_REQUIRED,
                validateTrigger: "onSubmit",
              },
              {
                pattern: NAME_RULE,
                message: WRONG_FIRST_NAME,
                validateTrigger: "onSubmit",
              },
              {
                max: 50,
                message: MAX_FIRSTNAME,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input
              size="large"
              onBlur={(e) => validateOnBlur(e, "firstName")}
            />
          </Form.Item>

          <Form.Item
            className="mr-3 mb-5"
            name="accountNumber"
            label="Account Number"
            rules={[
              {
                pattern: ACCOUNT_NUMBER_RULE,
                message: WRONG_ACCOUNT_NUMBER,
                validateTrigger: "onSubmit",
              },
              {
                required: true,
                message: ACCOUNT_NUMBER_REQUIRED,
                validateTrigger: "onSubmit",
              },
              {
                max: 50,
                message: MAX_ACCOUNT_NUMBER,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input
              size="large"
              onBlur={(e) => validateOnBlur(e, "accountNumber")}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            className="ml-3 mb-5"
            name="lastName"
            label="Last Name"
            rules={[
              {
                required: true,
                message: LAST_NAME_REQUIRED,
                validateTrigger: "onSubmit",
              },
              {
                pattern: NAME_RULE,
                message: WRONG_LAST_NAME,
                validateTrigger: "onSubmit",
              },
              {
                max: 50,
                message: MAX_LASTNAME,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input size="large" onBlur={(e) => validateOnBlur(e, "lastName")} />
          </Form.Item>

          <Form.Item
            className="ml-3 mb-5"
            name="registrationType"
            label="I'm a"
          >
            <Radio.Group value={customerType} size="large">
              <Radio.Button value={0}>Buyer</Radio.Button>
              <Radio.Button value={1}>Seller</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <div className="d-flex">
        <div className="tax-id__container mr-4">
          <Form.Item
            name="taxIdDigits"
            label="Tax ID Number"
            rules={[
              {
                pattern: TAX_ID_RULE,
                message: WRONG_TAX_ID,
                validateTrigger: "onSubmit",
              },
              {
                required: true,
                message: TAX_ID_REQUIRED,
                validateTrigger: "onSubmit",
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Last 4 digits"
              onBlur={(e) => validateOnBlur(e, "taxIdDigits")}
            />
          </Form.Item>
        </div>
        <div className="tax-id-hint mt-6">
          Last 4 digits of Social Security Number or a Business Tax ID on file
          for this account.
        </div>
      </div>

      <Form.Item className="mt-3 mb-12">
        <Button
          type="primary"
          shape="round"
          size="large"
          htmlType="submit"
          block
        >
          Next
        </Button>
      </Form.Item>

      <div className="d-flex justify-center align-center">
        <span>Already registered?</span>
        <Button
          type="link"
          className="height-0 pt-0 pl-2"
          style={{ paddingBottom: "1px" }}
          href="/login"
        >
          Log In
        </Button>
      </div>
    </Form>
  );
};
