import { ReactNode, useState, useMemo, useCallback } from "react";
import { SideBarContainer } from "../../../components/SideBarContainer";
import { SideBarContent } from "../SideBarContent";
import { App } from "antd";
import { useAppDispatch } from "../../../store";
import { deleteUser } from "../../../acions/registrationRequests";
import { useGetDeleteDialogProps } from "../../../lib/hooks/getDeleteDialogProps";

export const useSidebar = (
  fetchData: () => void,
  fetchDataCount: () => void
) => {
  const dispatch = useAppDispatch();

  const [selectedUser, setSelectedUser] = useState<any | null>(null);

  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const [sideBarTitle, setSideBarTitle] = useState<string | ReactNode>("");

  const [isDeleting, setIsDeleting] = useState(false);

  const [options] = useGetDeleteDialogProps(isDeleting);

  const { modal } = App.useApp();

  const reloadData = useCallback(() => {
    fetchData();
    fetchDataCount();
    setShowSideBar(false);
    setSelectedUser(null);
  }, [fetchData, fetchDataCount]);

  const openDeleteModal = useCallback(
    (userId: string) => {
      modal.confirm({
        ...options,
        title: "Delete the record?",
        content: (
          <div className="text-md mb-1">
            Rejected records are deleted automatically after 30 days.
          </div>
        ),
        onOk: async () => {
          setIsDeleting(true);

          try {
            await Promise.resolve(dispatch(deleteUser(userId)));
            reloadData();
            setIsDeleting(false);
          } catch {
            return setIsDeleting(false);
          }
        },
      });
    },
    [modal, options, dispatch, reloadData]
  );

  const onOpenSideBar = (
    header: string | ReactNode,
    userId: string,
    status: string
  ) => {
    setSelectedUser({ userId, status });
    setSideBarTitle(header);
    setShowSideBar(true);
  };

  const sideBar: any = useMemo(
    () => (
      <SideBarContainer
        title={sideBarTitle}
        setShowSideBar={() => setShowSideBar(false)}
      >
        <SideBarContent
          user={selectedUser}
          reloadData={reloadData}
          openDeleteModal={openDeleteModal}
        />
      </SideBarContainer>
    ),
    [reloadData, selectedUser, sideBarTitle, openDeleteModal]
  );

  return [showSideBar, sideBar, onOpenSideBar, openDeleteModal];
};
