import { Pie } from "@ant-design/plots";
import { CHART_TITLE_COLOR, CHART_VALUE_COLOR } from "../../../lib/constants";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { ACCOUNT_STATUSES } from "../../../lib/constants";

type PieChartCardProps = {
  data: any;
  colors: string[];
};

export const PieChartCard = ({ data, colors }: PieChartCardProps) => {
  const navigate = useNavigate();

  const config = useMemo(
    () => ({
      legend: {
        position: "bottom",
        layot: "horizontal",
        flipPage: false,
        itemWidth: 149,
        spacing: 20,
        itemName: {
          formatter: (text: string) => `${text}:`,
        },
        itemValue: {
          formatter: (text: string) => {
            const value = data.filter((item: any) => item.type === text)[0]
              .value;
            return value.toLocaleString();
          },
          style: {
            fontSize: 14,
            fontWeight: 500,
          },
        },
      } as const,
      height: 320,
      appendPadding: 10,
      data,
      theme: "light",
      angleField: "value",
      colorField: "type",
      color: colors,
      radius: 0.7,
      innerRadius: 0.8,
      meta: {
        value: {
          formatter: (v: string) => `${v.toLocaleString()}`,
        },
      },
      label: {
        formatter: () => "",
      },
      statistic: {
        title: {
          style: {
            color: CHART_TITLE_COLOR,
          },
          offsetY: 30,
        },
        content: {
          style: {
            color: CHART_VALUE_COLOR,
            fontSize: "24px",
            fontWeight: "500",
          },
          offsetY: -22,
          customHtml: (container: any, view: any, datum: any, data: any) => {
            const value = data.reduce((r: any, d: any) => r + d.value, 0);
            return value.toLocaleString();
          },
        },
      },
      pieStyle: {
        lineWidth: 0,
        cursor: "pointer",
      },
      onReady: (plot: any) => {
        plot.on("element:click", (args: any) => {
          const type = args.data.data.type;
          const status = ACCOUNT_STATUSES.find(
            (item: any) => item.label === type
          );
          const state: any = {};

          if (!["Semi-active", "Healthy"].includes(type)) {
            state.status = status?.value;
          } else {
            const activeStatus = ACCOUNT_STATUSES.find(
              (item: any) => item.label === "Active"
            );

            if (type === "Semi-active") {
              state.status = activeStatus?.value;
              state.dateFilter = 2;
            }

            if (type === "Healthy") {
              state.status = activeStatus?.value;
              state.dateFilter = 1;
            }
          }

          navigate("/accounts", {
            state: state,
            replace: true,
          });
        });
      },
      state: {
        active: {
          animate: { duration: 100, easing: "easeLinear" },
          style: (item: any) => {
            return {
              stroke: item.data.color,
              lineWidth: 3,
              cursor: "pointer",
            };
          },
        },
      },
      interactions: [
        {
          type: "element-active",
        },
      ],
    }),
    [data, colors, navigate]
  );
  return (
    <div>
      <Pie {...config} />
    </div>
  );
};
