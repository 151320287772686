import AccountsService from "../../services/accounts";
import { useEffect, useState, useMemo } from "react";
import moment from "moment";
import { DATE_FORMAT } from "../constants";

export const useGetLastUpdate = () => {
  const [lastUpdate, setLastUpdate] = useState<string | null>(null);

  useEffect(() => {
    AccountsService.getLastUpdate().then((res) => {
      const date = moment(res).format(DATE_FORMAT);

      setLastUpdate(date);
    });
  }, []);

  const lastUpdatedLabel = useMemo(() => {
    return lastUpdate ? (
      <div className="desktop-label">
        <p className="my-0 text-md title">Last Updated: {lastUpdate}</p>
      </div>
    ) : (
      <div />
    );
  }, [lastUpdate]);

  return [lastUpdatedLabel];
};
