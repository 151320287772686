import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  LOGIN_SUCCESS,
  UPDATE_USER_DATA,
} from "./types";
import { User } from "../types/User";
import AuthService from "../services/auth";
import { RootState } from "../store";
import { ThunkAction } from "redux-thunk";
import { AnyAction } from "redux";
import {
  CODE_SEND,
  FAIL_LOGIN_MESSAGE,
  ISSUE_WITH_ACCOUNT,
  RESET_PASSWORD_SEND,
  RESET_PASSWORD_SUCCESS,
} from "../lib/constants";
import Cookies from "js-cookie";
import { RegisterRequest } from "../types/RegisterRequest";
import jwtDecode from "jwt-decode";
import { getMessageWithContactLink } from "../lib/messageWithContactLink";

export const getRoleFromJWT = (tokenData: any) => {
  return tokenData[
    "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
  ];
};

export const register =
  (user: RegisterRequest): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await AuthService.register(user);
      dispatch({ type: REGISTER_SUCCESS });

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({ type: REGISTER_FAIL });

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: error.response.data.title },
      });

      return await Promise.reject(error.response.data.title);
    }
  };

export const loginFirstStep =
  (user: User): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await AuthService.loginFirstStep(user);

      const tokenData: any = response.token ? jwtDecode(response.token) : {};

      if (response.token && tokenData.IsDataUpdateRequired === "True") {
        dispatch(setUser({ token: response.token }));

        return await Promise.resolve({ ...response, shouldUpdate: true });
      } else {
        let role = null;

        if (
          (user.rememberDeviceCode && !response.verificationCodeId) ||
          !response.is2FARequired
        ) {
          dispatch(setUser({ token: response.token }));

          role = getRoleFromJWT(tokenData);
        } else {
          dispatch({
            type: SET_MESSAGE,
            payload: { type: "success", message: CODE_SEND },
          });
        }

        return await Promise.resolve({ ...response, role });
      }
    } catch (error: any) {
      dispatch({
        type: LOGIN_FAIL,
      });

      const errorMessage = error.response.data.title;

      const payload: any = {
        type: "error",
        message: error.response.data.title,
      };

      if (errorMessage === ISSUE_WITH_ACCOUNT) {
        payload.shouldDisapear = true;
      }

      dispatch({
        type: SET_MESSAGE,
        payload: payload,
      });
      return await Promise.reject(error);
    }
  };

export const acceptAgreements =
  (): ThunkAction<void, RootState, unknown, AnyAction> => async (dispatch) => {
    try {
      const response: any = await AuthService.acceptAgreements();

      if (response && response.token) {
        dispatch(setUser({ token: response.token }));
      }

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: error.response.data.title },
      });
      return await Promise.reject(error);
    }
  };

export const login =
  (user: User): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await AuthService.loginSecondStep(user);

      const userData = { token: response.token };

      const tokenData = jwtDecode(response.token);

      const role = getRoleFromJWT(tokenData);

      dispatch({
        type: LOGIN_SUCCESS,
        payload: userData,
      });

      localStorage.setItem("userData", JSON.stringify(userData));

      return await Promise.resolve({ ...response, role });
    } catch (error: any) {
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: FAIL_LOGIN_MESSAGE },
      });
      return await Promise.reject(error);
    }
  };

export const sendVerificationCode =
  (
    type: string,
    value: any
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await AuthService.sendVerificationCode(type, value);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: CODE_SEND },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      const errorTitle: string = error.response.data.title;

      if (type === "ForgotPassword") {
        if (errorTitle === "Email code expired") {
          return await Promise.reject({ ...error, isExpiredCode: true });
        } else if (errorTitle === ISSUE_WITH_ACCOUNT) {
          return await Promise.reject({ ...error, hasIssueWithAccount: true });
        }
      }

      if (type === "ForgotUsername") {
        if (errorTitle === "There was a problem identifying the user.") {
          return await Promise.reject({ ...error, isMultipleUsers: true });
        } else if (errorTitle === ISSUE_WITH_ACCOUNT) {
          dispatch({
            type: SET_MESSAGE,
            payload: {
              type: "error",
              message: getMessageWithContactLink(errorTitle),
            },
          });

          return await Promise.reject(error);
        }
      }

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: errorTitle },
      });
      return await Promise.reject(error);
    }
  };

export const setUser = (userData: any) => (dispatch: any) => {
  dispatch({
    type: LOGIN_SUCCESS,
    payload: userData,
  });

  localStorage.setItem("userData", JSON.stringify(userData));
};

export const resetUser =
  (): ThunkAction<void, RootState, unknown, AnyAction> => (dispatch: any) => {
    const cookieData = JSON.parse(localStorage.getItem("userData") || "");

    dispatch({
      type: LOGIN_SUCCESS,
      payload: cookieData,
    });
  };

export const updateUserData =
  (userData: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch: any) => {
    dispatch({
      type: UPDATE_USER_DATA,
      payload: userData,
    });
  };

export const deleteUserData =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    dispatch({ type: LOGOUT });

    localStorage.removeItem("userData");
  };

export const logout =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await AuthService.logout();

      dispatch(deleteUserData());

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: err.response.data.title },
      });

      return await Promise.reject(err);
    }
  };

export const confirmVerificationCode =
  (
    type: string,
    request: any
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await AuthService.confirmVerificationCode(type, request);

      type === "Auth" &&
        Cookies.set("rememberMeCode", response.data.rememberDeviceCode, {
          expires: 30,
        });

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: err.response.data.title },
      });

      return await Promise.reject(err);
    }
  };

export const requestPasswordChange =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await AuthService.requestPasswordChange(request);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: RESET_PASSWORD_SEND },
      });

      return await Promise.resolve(response);
    } catch (err) {
      return await Promise.reject(err);
    }
  };

export const changePassword =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await AuthService.changePassword(request);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: RESET_PASSWORD_SUCCESS },
      });
      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: err.response.data.title },
      });

      return await Promise.reject(err);
    }
  };

export const show =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response = await AuthService.show(request);

      return await Promise.resolve(response);
    } catch (err: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: err.response.data.title },
      });

      return await Promise.reject(err);
    }
  };

export const refresh =
  (): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch: any) => {
    try {
      const response: any = await AuthService.refresh();

      dispatch(setUser({ token: response.token }));

      return await Promise.resolve(response);
    } catch (err: any) {
      return await Promise.reject(err);
    }
  };
