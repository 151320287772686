import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import { UserRegistrationService } from "../services/registrationRequests";
import { SET_MESSAGE, SET_REGISTERED_COUNT } from "./types";
import {
  ACCEPT_USER_SUCCESS,
  DELETE_USER_SUCCESS,
  REJECT_USER_SUCCESS,
} from "../lib/constants";

export const acceptUser =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UserRegistrationService.acceptUser(request);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: ACCEPT_USER_SUCCESS },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: error.response.data.title },
      });
      return await Promise.reject(error);
    }
  };

export const rejectUser =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UserRegistrationService.rejectUser(request);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: REJECT_USER_SUCCESS },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: error.response.data.title },
      });
      return await Promise.reject(error);
    }
  };

export const deleteUser =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UserRegistrationService.deleteUser(id);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: DELETE_USER_SUCCESS },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: error.response.data.title },
      });
      return await Promise.reject(error);
    }
  };

export const fetchCount =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response =
        await UserRegistrationService.fetchRegistrationsListCount(request);

      dispatch({
        type: SET_REGISTERED_COUNT,
        payload: response,
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      return await Promise.reject(error);
    }
  };
