import { Tag, Dropdown, MenuProps } from "antd";
import { ColumnsType } from "antd/es/table";
import { getFormatted } from "../../lib/PhoneFormatter";
import { EllipsisOutlined } from "@ant-design/icons";
import { ReactNode } from "react";

const getRoleTagProps = (value: string) => {
  let color: string | undefined;

  if (value === "Admin") {
    color = "blue";
  } else if (value === "SuperAdmin") {
    color = "purple";
  }

  const props: any = {};

  if (color) {
    props.color = color;
  }

  return props;
};

export const onSortChange = (
  sorter: any,
  setUserOrder: (value: number | null) => void
) => {
  !sorter.order && setUserOrder(null);

  if (sorter.field === "firstName") {
    sorter.order === "ascend" && setUserOrder(0);

    sorter.order === "descend" && setUserOrder(1);
  }

  if (sorter.field === "role") {
    sorter.order === "ascend" && setUserOrder(2);

    sorter.order === "descend" && setUserOrder(3);
  }

  if (sorter.field === "status") {
    sorter.order === "ascend" && setUserOrder(4);

    sorter.order === "descend" && setUserOrder(5);
  }
};

export const getTableColumns = (
  onOpenSideBar: (header: string | ReactNode, userId: string) => void,
  role: string,
  onOpenBlockModal: (userId: string, isBlockValue: boolean) => void,
  onOpenEditUser: (header: string | ReactNode, userId: string) => void,
  me: any,
  getStatus: (value: number) => ReactNode,
  getHeader: (record: any, value: string) => ReactNode,
  openDeleteModal: (id: string) => void
): ColumnsType<any> => {
  return [
    {
      title: "Name",
      dataIndex: "firstName",
      render: (value: string, record: any) => {
        const header = getHeader(record, value);

        return (
          <div
            className="cell-elipsis"
            title={`${value} ${record.lastName}`}
            onClick={() => onOpenSideBar(header, record.id)}
          >
            <span className="link-btn">{`${value} ${record.lastName}`}</span>
          </div>
        );
      },
      sorter: true,
      width: "19%",
      ellipsis: true,
    },
    {
      title: "Username",
      dataIndex: "userName",
      render: (value: string) => {
        return (
          <div className="cell-elipsis" title={value || "-"}>
            <span>{value || "-"}</span>
          </div>
        );
      },
      width: "19%",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value: string) => {
        return (
          <div className="cell-elipsis" title={value}>
            <span>{value}</span>
          </div>
        );
      },
      width: "19%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (value: string) => getFormatted(value).slice(2),
      width: "18%",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (value: string) =>
        value ? <Tag {...getRoleTagProps(value)}>{value}</Tag> : "-",
      sorter: true,
      width: "9%",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value: number) => getStatus(value),
      sorter: true,
      width: "9%",
    },
    {
      title: "Action",
      render: (value: any, record: any) => {
        const header = getHeader(record, record.firstName);

        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <div onClick={() => onOpenSideBar(header, record.id)}>
                View Profile
              </div>
            ),
          },
        ];

        const editData = {
          key: "2",
          label: (
            <div onClick={() => onOpenEditUser(header, record.id)}>
              Edit Profile
            </div>
          ),
        };

        if (role === "Admin") {
          if (me.uid === record.id || record.role === "User") {
            items.push(editData);
          }
        } else {
          items.push(editData);
        }

        if (role === "Admin") {
          if (record.status === 2) {
            items.push({
              key: "3",
              label: (
                <div onClick={() => onOpenBlockModal(record.id, false)}>
                  Unblock Profile
                </div>
              ),
            });
          } else if (record.role === "User") {
            items.push({
              key: "3",
              label: (
                <div onClick={() => onOpenBlockModal(record.id, true)}>
                  Block Profile
                </div>
              ),
            });
          }
        } else {
          me.uid !== record.id &&
            items.push({
              key: "3",
              label: (
                <div
                  onClick={() =>
                    onOpenBlockModal(record.id, record.status === 1)
                  }
                >{`${record.status === 1 ? "Block" : "Unblock"} Profile`}</div>
              ),
            });
        }

        if (record.role === "User") {
          items.push({
            key: "4",
            label: (
              <div onClick={() => openDeleteModal(record.id)}>Delete User</div>
            ),
          });
        }

        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
      width: "7%",
    },
  ];
};
