import { Col, Row, Button } from "antd";
import { useAppDispatch } from "../../store";
import { requestPasswordChange } from "../../acions/auth";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";

type EmailCodeExpiredProps = {
  userName: string | null;
};

export const EmailCodeExpired = ({ userName }: EmailCodeExpiredProps) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const sendLink = () => {
    setIsLoading(true);
    Promise.resolve(dispatch(requestPasswordChange({ userName })))
      .then(() => {
        setIsLoading(false);
      })
      .catch(() => setIsLoading(false));
  };

  return (
    <Row className="mt-16 justify-center mx-4">
      <Col>
        <Row className="justify-center d-flex">
          <Col>
            {" "}
            <h1 className="header mb-2 text-center">
              Sorry, the link has expired
            </h1>
            <div className="sub-title__container mb-10">
              <p className="d-flex justify-center my-0">
                Please try to reset the password once more again.
              </p>
            </div>
            <div className="d-flex justify-center">
              <Button
                type="primary"
                shape="round"
                size="large"
                disabled={isLoading}
                onClick={sendLink}
              >
                Resend Password Link
                {isLoading && <LoadingOutlined />}
              </Button>
            </div>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
