import qs from "qs";
import { API_URL } from "../configs/apiUrl";
import { authHeader } from "./data";
import authAxios from "./data";

const fetchRegistrationsList = async (request: any) => {
  const response = await authAxios.get(
    API_URL + "UserRegistrationRequest/GetList",
    {
      params: request,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchRegistrationsListCount = async (request?: any) => {
  const response = await authAxios.get(
    API_URL + "UserRegistrationRequest/Count",
    {
      params: request,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
      headers: authHeader(),
    }
  );

  return response.data;
};

const fetchRegistrationUser = async (userId: string) => {
  const response = await authAxios.get(
    API_URL + "UserRegistrationRequest/Get?id=" + userId,
    {
      headers: authHeader(),
    }
  );

  return response.data;
};

const acceptUser = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "UserRegistrationRequest/Confirm",
    request,
    { headers: authHeader() }
  );

  return response.data;
};

const rejectUser = async (request: any) => {
  const response = await authAxios.post(
    API_URL + "UserRegistrationRequest/Deny",
    request,
    { headers: authHeader() }
  );

  return response.data;
};

const deleteUser = async (id: string) => {
  const response = await authAxios.delete(
    API_URL + "UserRegistrationRequest/Delete?id=" + id,
    { headers: authHeader() }
  );

  return response.data;
};

const fetchRejectedRegistrationEvents = async (id: string) => {
  const response = await authAxios.get(
    API_URL + "UserRejectedRegistrationRequestEvent?id=" + id,
    { headers: authHeader() }
  );

  return response.data;
};

export const UserRegistrationService = {
  fetchRegistrationsList,
  fetchRegistrationsListCount,
  fetchRegistrationUser,
  acceptUser,
  rejectUser,
  deleteUser,
  fetchRejectedRegistrationEvents,
};
