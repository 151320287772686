import { useState } from "react";
import { useAppDispatch } from "../../store";
import { loginFirstStep } from "../../acions/auth";
import { clearMessage } from "../../acions/message";
import "./styles.scss";
import { SecurityCheck } from "./components/SecurityCheck";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";
import { LoginForm } from "./components/LoginForm";
import { useInitialDataFromLocation } from "./hooks/initialDataFromLocation";
import { UpdateUserDataForm } from "./components/UpdateUserDataForm";
import { SEVERAL_USERS_WITH_SAME_EMAIL } from "../../lib/constants";
import { IssueWithAccount } from "../../components/IssueWithAccount";
import { TermsAndConditionsModal } from "../../components/TermsAndConditionsModal";
import jwtDecode from "jwt-decode";
import { useAcceptTerms } from "./hooks/useAcceptTerms";

export const Login = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { initialData } = useInitialDataFromLocation();

  const [isChecking, setIsChecking] = useState<boolean>(false);

  const [shouldUserUpdate, setShouldUserUpdate] = useState<boolean>(false);

  const [data, setData] = useState<any>(initialData);

  const [isIssueWithAccount, setIsIssueWithAccount] = useState<boolean>(false);

  const [firstStepData, setFirstStepData] = useState<any>({});

  const [username, setUsername] = useState<string>("");

  const onLoginFirstStepSucces = (
    rememberDeviceCode: string | null,
    res: any,
    values: any
  ): void => {
    if (res.shouldUpdate) {
      setShouldUserUpdate(true);
    } else {
      if (
        (rememberDeviceCode && !res.verificationCodeId) ||
        !res.is2FARequired
      ) {
        navigate(res.role && res.role === "User" ? "/accounts" : "/home");
      } else {
        setFirstStepData(res);
        setData(values);
        setIsChecking(true);
      }
    }
  };

  const {
    termsTabId,
    setTermsTabId,
    accept,
    acceptButton,
    setAcceptButton,
    isLoading,
    onCloseTerms,
  } = useAcceptTerms();

  const onLoginButtonClicked = async (values: any): Promise<any> => {
    dispatch(clearMessage());
    setUsername(values.username);

    const rememberDeviceCode: string | null = AuthService.getRememberMeCode();

    try {
      const res: any = await Promise.resolve(
        dispatch(loginFirstStep({ ...values, rememberDeviceCode }))
      );

      const tokenData: any = res.token ? jwtDecode(res.token) : {};

      if (tokenData.IsAgreementsConfirmationNeeded === "True") {
        setTermsTabId("1");

        setAcceptButton({
          label: "Accept",
          action: () =>
            accept((token: string) => {
              onLoginFirstStepSucces(
                rememberDeviceCode,
                { ...res, token },
                values
              );
            }),
        });
      } else {
        onLoginFirstStepSucces(rememberDeviceCode, res, values);
      }
    } catch (err: any) {
      if (err.response.data.title === SEVERAL_USERS_WITH_SAME_EMAIL) {
        setIsIssueWithAccount(true);
      }
      throw err;
    }
  };

  const onBackClicked = (): void => setShouldUserUpdate(false);

  return (
    <>
      {shouldUserUpdate ? (
        <UpdateUserDataForm onBackClicked={onBackClicked} />
      ) : isChecking ? (
        <SecurityCheck
          data={data}
          firstStepData={firstStepData}
          onLoginButtonClicked={onLoginButtonClicked}
          setIsChecking={setIsChecking}
        />
      ) : (
        <>
          {!isIssueWithAccount ? (
            <LoginForm
              data={data}
              onLoginButtonClicked={onLoginButtonClicked}
            />
          ) : (
            <IssueWithAccount
              header="There are several users with the same email/username"
              username={username}
              postInfo="&ensp;and provide the email/username you’ve tried to use."
            />
          )}
        </>
      )}
      <TermsAndConditionsModal
        termsTabId={termsTabId}
        setTermsTabId={setTermsTabId}
        acceptButton={acceptButton}
        isLoading={isLoading}
        onClose={onCloseTerms}
        closeLabel="Reject"
      />
    </>
  );
};
