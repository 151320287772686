import { useMemo } from "react";
import { Row } from "antd";
import { MultiSelect } from "../../components/MultiSelect";
import { SearchWithClear } from "../../components/SearchWithClear";

type FiltersProps = {
  setStatus: (value: string | number) => void;
  isLoading: boolean;
  search: string;
  onSearchChange: (e: any) => void;
  onClearSearch: () => void;
};

export const Filters = ({
  setStatus,
  isLoading,
  search,
  onSearchChange,
  onClearSearch,
}: FiltersProps) => {
  const statuses = useMemo(
    () => [
      { label: "Pending", value: 0 },
      { label: "Rejected", value: 4 },
    ],
    []
  );

  return (
    <Row className="user-management-filters justify-space-between">
      <div className="d-flex">
        <div className="mr-4">
          <SearchWithClear
            onSearchChange={onSearchChange}
            isLoading={isLoading}
            search={search}
            onClear={onClearSearch}
          />
        </div>
        <div className="mr-4">
          <MultiSelect
            label="Status"
            options={statuses}
            isLoading={isLoading}
            onChange={(values) => setStatus(values[0])}
          />
        </div>
      </div>
    </Row>
  );
};
