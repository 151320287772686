import { Row, Col } from "antd";
import "./styles.scss";
import { CheckOutlined } from "@ant-design/icons";
import { PRIMARY_6_COLOR } from "../../lib/constants";
import image from "../../images/register.svg";
import { Steps } from "./Steps";
import { useState, useMemo } from "react";
import { FirstStep } from "./FirstStep";
import { SecondStep } from "./SecondStep";
import { useAppDispatch } from "../../store";
import { register, sendVerificationCode } from "../../acions/auth";
import { clearMessage, setMessage } from "../../acions/message";
import { EMAIL_IS_USED } from "../../lib/constants";
import { SecurityCheck } from "../../components/SecurityCheck";
import { useNavigate } from "react-router-dom";
import AuthService from "../../services/auth";

export const SignUp = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isFirst, setIsFirst] = useState<boolean>(true);

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const [firstStepData, setFirstStepData] = useState<any>({
    registrationType: 0,
  });

  const [secondStepData, setSecondStepData] = useState<any>({});

  const [emailError, setEmailError] = useState<string | null>(null);

  const [isChecking, setIsChecking] = useState<boolean>(false);

  const [verificationCodeId, setVerificationCodeId] = useState<string>("");

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  const [phone, setPhone] = useState<string>("");

  const onNextClicked = (values: any) => {
    setFirstStepData(values);
    setIsFirst(false);
  };

  const onRegisterButtonClicked = (secondStepValues: any) => {
    const newData = { ...secondStepValues };
    setSecondStepData(newData);
    setIsFormDisabled(true);

    const formattedPhone = `1${secondStepValues.phone.replace(/[\s-()]/g, "")}`;
    setPhone(formattedPhone);

    onSendVerificationCode({
      ...firstStepData,
      ...newData,
      phone: formattedPhone,
    }).catch(() => {
      setIsFormDisabled(false);
      setIsConfirmDisabled(false);
    });
  };

  const onSendVerificationCode = async (data: any) => {
    setIsConfirmDisabled(true);
    dispatch(clearMessage());

    try {
      const res: any = await Promise.resolve(
        dispatch(sendVerificationCode("Register", data))
      );
      setVerificationCodeId(res.data.verificationCodeId);
      setIsFormDisabled(false);
      setIsConfirmDisabled(false);
      setIsChecking(true);
    } catch {
      setIsFormDisabled(false);
      setIsConfirmDisabled(false);
    }
  };

  const onRegisterSuccess = (values: any) => {
    const code = values.securityCode.replace(/-/g, "");

    dispatch(clearMessage());
    setIsConfirmDisabled(true);

    const request: any = {
      code: code,
      verificationCodeId,
      email: secondStepData.email,
    };
    AuthService.confirmVerificationCode("Register", request)
      .then(() => {
        Promise.resolve(
          dispatch(
            register({
              ...firstStepData,
              ...secondStepData,
              verificationCodeId,
              phone,
            })
          )
        )
          .then(() => {
            navigate("/register-completed");
          })
          .catch((error: string) => {
            error === EMAIL_IS_USED && setEmailError(error);
            setIsConfirmDisabled(false);
          });
      })
      .catch((err) => {
        dispatch(
          setMessage({ type: "error", message: err.response.data.title })
        );
        setIsConfirmDisabled(false);
      });
  };

  const securityCheckSubTitle = useMemo(
    () => (
      <div className="sub-title__container mb-10">
        <p className="justify-center my-0">
          Let us know this email belongs to you.
        </p>
        <p className="justify-center my-0">
          Enter the code we’ve just sent to
          <span className="email-text">&ensp;{secondStepData.email}</span>
        </p>
      </div>
    ),
    [secondStepData]
  );

  return (
    <>
      {isChecking ? (
        <SecurityCheck
          header="Enter the code from your email"
          setStep={() => setIsChecking(false)}
          isRememberCode={false}
          isTimer={false}
          subTitle={securityCheckSubTitle}
          onConfirm={(value) => onRegisterSuccess(value)}
          type="email"
          isConfirmDisabled={isConfirmDisabled}
          onResend={() =>
            onSendVerificationCode({
              ...firstStepData,
              ...secondStepData,
              phone,
            })
          }
        />
      ) : (
        <Row className="mt-16 justify-center">
          <Col className="signup">
            <Row>
              <Col className="singup-left__container pb-10">
                <h5 className="h h5 mb-10">Creating trust between strangers</h5>
                <div className="d-flex mb-4">
                  <CheckOutlined style={{ color: PRIMARY_6_COLOR }} />
                  <p className="pl-4 my-0">
                    Get up-to-date account information
                  </p>
                </div>
                <div className="d-flex mb-4">
                  <CheckOutlined style={{ color: PRIMARY_6_COLOR }} />
                  <p className="pl-4 my-0">View transactions history</p>
                </div>
                <div className="d-flex pb-16">
                  <CheckOutlined style={{ color: PRIMARY_6_COLOR }} />
                  <p className="pl-4 my-0">Make secure payments</p>
                </div>
                <div className="mt-3">
                  <img src={image} alt="register" />
                </div>
              </Col>
              <Col className="sign-right__container">
                <div className="d-flex justify-space-between align-center mb-6">
                  <h2 className="header h h2">Sign up</h2>
                  <div>
                    <Steps
                      isFirst={isFirst}
                      setIsFirst={setIsFirst}
                      isFormDisabled={isFormDisabled}
                    />
                  </div>
                </div>

                {isFirst ? (
                  <>
                    <p className="mb-6 mt-0">
                      Fill in the details from one of the escrow contract you’ve
                      signed
                    </p>
                    <FirstStep
                      onNextClicked={onNextClicked}
                      data={firstStepData}
                    />
                  </>
                ) : (
                  <SecondStep
                    data={secondStepData}
                    onRegister={onRegisterButtonClicked}
                    isFormDisabled={isFormDisabled}
                    emailError={emailError}
                  />
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  );
};
