import { Pagination, Row, Skeleton } from "antd";

type TablePaginationProps = {
  isTotalLoading: boolean;
  total: number;
  currentPage: number;
  pageSize: number;
  onPageChange: (value: number, pageSize: number) => void;
};

export const TablePagination = ({
  isTotalLoading,
  total,
  currentPage,
  pageSize,
  onPageChange,
}: TablePaginationProps) => {
  return (
    <Row className="mt-4 justify-end align-center">
      {!isTotalLoading ? (
        <>
          <div className="pr-4">{`Total ${total} item${
            total && total > 1 ? "s" : ""
          }`}</div>
          <Pagination
            size="small"
            total={total}
            current={currentPage}
            onChange={onPageChange}
            showSizeChanger
            defaultPageSize={pageSize}
            pageSizeOptions={[10, 20, 50, 100]}
          />
        </>
      ) : (
        <Skeleton
          active
          round
          paragraph={{ rows: 1, width: "288px" }}
          title={false}
        />
      )}
    </Row>
  );
};
