import { authHeader } from "./data";
import authAxios from "./data";
import { API_URL } from "../configs/apiUrl";
import qs from "qs";

const fetchDashboardData = async () => {
  const response = await authAxios.get(API_URL + "Dashboard", {
    headers: authHeader(),
  });

  return response.data;
};

const fetchDashboardHistoryData = async (request: any) => {
  const response = await authAxios.get(API_URL + "Dashboard/MonthlyHistory", {
    params: request,
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
    headers: authHeader(),
  });

  return response.data;
};

export const DashboardService = {
  fetchDashboardData,
  fetchDashboardHistoryData,
};
