import { useState, useEffect } from "react";

export const useDebounce = (value: string, delay: number = 500) => {
  const [debounced, setDebounced] = useState<string>(value);

  useEffect(() => {
    let id: any = null;

    if (value !== "") {
      id = setTimeout(() => {
        setDebounced(value);
      }, delay);
    } else {
      setDebounced(value);
    }

    return () => clearTimeout(id);
  }, [value, delay]);

  return debounced;
};
