import { Layout, Button } from "antd";
import { Outlet } from "react-router-dom";
import { Copyright } from "../../Copyright";
import "./styles.scss";
import { useState } from "react";
import { TermsAndConditionsModal } from "../../TermsAndConditionsModal";

export const Footer = () => {
  const { Footer } = Layout;

  const [termsTabId, setTermsTabId] = useState<undefined | "1" | "2">(
    undefined
  );

  const onCloseTerms = () => setTermsTabId(undefined);

  return (
    <>
      <Outlet />

      <Footer style={{ textAlign: "center" }}>
        <div className="footer__container">
          <Copyright />
          <div className="footer-link d-flex align-center">
            <div className="elipse" />
            <Button type="link" onClick={() => setTermsTabId("1")}>
              Terms of Use
            </Button>
            <div className="elipse" />
            <Button type="link" onClick={() => setTermsTabId("2")}>
              Privacy Policy
            </Button>
          </div>
        </div>

        <TermsAndConditionsModal
          termsTabId={termsTabId}
          setTermsTabId={setTermsTabId}
          onClose={onCloseTerms}
        />
      </Footer>
    </>
  );
};
