import "./styles.scss";
import { ReactNode, useMemo } from "react";
import { Button, Row, Tabs, TabsProps, Tag } from "antd";
import { Details } from "./Details";
import { PaymentHistory } from "./PaymentHistory";
import { getTagColorProps } from "../accountsTableColumns";
import { ArrowLeftOutlined } from "@ant-design/icons";

type ViewProps = {
  selectedContract: any;
  onBack: (value: any) => void;
  lastUpdatedLabel: ReactNode;
};

export const View = ({
  selectedContract,
  onBack,
  lastUpdatedLabel,
}: ViewProps) => {
  const tabItems: TabsProps["items"] = useMemo(
    () => [
      {
        key: "1",
        label: "Account Details",
        children: <Details selectedContract={selectedContract} />,
      },
      {
        key: "2",
        label: "Payment History",
        children: <PaymentHistory selectedContract={selectedContract} />,
      },
    ],
    [selectedContract]
  );

  const status: string = useMemo(
    () => selectedContract.status,
    [selectedContract]
  );

  return (
    <div className="logged-in__page position-absolute account-view__container">
      <Row className="py-5 px-8 align-center title justify-space-between">
        <div className="d-flex align-center">
          <Button
            shape="circle"
            icon={<ArrowLeftOutlined />}
            size="large"
            className="back-btn mr-6"
            onClick={() => onBack(null)}
          />
          <h3 className="h h3">
            <span className="desktop-label">Account:</span>{" "}
            {selectedContract.accountNumber}
          </h3>
          {status && (
            <Tag className="ml-4" {...getTagColorProps(status)}>
              {selectedContract.status}
            </Tag>
          )}
        </div>
        {lastUpdatedLabel}
      </Row>
      <Row className="account-tabs">
        <Tabs items={tabItems} defaultActiveKey="1" />
      </Row>
    </div>
  );
};
