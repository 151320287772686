import { Col, Row, Form, FormInstance, Input, Button } from "antd";
import { useUserDataCheck } from "../hooks/userDataCheck";
import { Header } from "../../../components/SecurityCheck/Header";
import { useMemo, useState } from "react";
import {
  EMAIL_REQUIRED,
  FIRST_NAME_REQUIRED,
  LAST_NAME_REQUIRED,
  MAX_EMAIL,
  MAX_FIRSTNAME,
  MAX_LASTNAME,
  MAX_USERNAME,
  WRONG_EMAIL_FORMAT,
  WRONG_FIRST_NAME,
  WRONG_LAST_NAME,
} from "../../../lib/constants";
import { EMAIL_RULE, NAME_RULE } from "../../../lib/validationRules";
import { useValidateOnBlur } from "../../../lib/hooks/validateOnBlur";
import { Loader } from "../../../components/Loader";
import { PhoneInput } from "../../../components/PhoneInput";
import { PasswordInput } from "../../../components/PasswordInput";
import { LoadingOutlined } from "@ant-design/icons";

type UpdateUserDataFormProps = {
  onBackClicked: () => void;
};

export const UpdateUserDataForm = ({
  onBackClicked,
}: UpdateUserDataFormProps) => {
  const [form]: FormInstance[] = Form.useForm();

  const [validateOnBlur] = useValidateOnBlur(form);

  const { data, isLoading, phone, isFormDisabled, updateData } =
    useUserDataCheck();

  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const nameFormProps = useMemo(
    () => [
      {
        name: "firstName",
        label: "First Name",
        className: "mr-3 mb-5",
        rules: {
          requiredMessage: FIRST_NAME_REQUIRED,
          patternMessage: WRONG_FIRST_NAME,
          maxMessage: MAX_FIRSTNAME,
        },
      },
      {
        name: "lastName",
        label: "Last Name",
        className: "ml-3 mb-5",
        rules: {
          requiredMessage: LAST_NAME_REQUIRED,
          patternMessage: WRONG_LAST_NAME,
          maxMessage: MAX_LASTNAME,
        },
      },
    ],
    []
  );

  const onConfirm = (values: any) => {
    isPasswordValid && updateData(values);
  };

  return (
    <Row className="mt-16 check-form justify-center">
      <Col>
        <Header
          header="Check and update your data if needed"
          onBackClicked={onBackClicked}
        />
        <div className="sub-title__container mb-10">
          <p className="d-flex justify-center my-0">
            We’ve updated our data storing rules to avoid mistakes in clients
            data and even more secure the system.
          </p>
          <p className="d-flex justify-center my-0">
            Please check and update your data before accessing the system.
          </p>
        </div>

        <Row className="d-flex justify-center">
          {!isLoading ? (
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              disabled={isFormDisabled}
              onFinish={onConfirm}
            >
              <Row>
                {nameFormProps.map((item: any, i: number) => {
                  return (
                    <Col span={12} key={i} className="check-form__name">
                      <Form.Item
                        className={item.className}
                        name={item.name}
                        label={item.label}
                        initialValue={data[item.name]}
                        rules={[
                          {
                            required: true,
                            message: item.rules.requiredMessage,
                            validateTrigger: "onSubmit",
                          },
                          {
                            pattern: NAME_RULE,
                            message: item.rules.patternMessage,
                            validateTrigger: "onSubmit",
                          },
                          {
                            max: 50,
                            message: item.rules.maxMessage,
                            validateTrigger: "onSubmit",
                          },
                        ]}
                      >
                        <Input
                          size="large"
                          onBlur={(e) => validateOnBlur(e, item.name)}
                        />
                      </Form.Item>
                    </Col>
                  );
                })}
              </Row>

              {phone !== null && (
                <PhoneInput form={form} initialValue={phone} />
              )}

              <Form.Item
                name="newUsername"
                label="Username"
                className="mb-5"
                initialValue={data.username}
                rules={[
                  {
                    max: 100,
                    message: MAX_USERNAME,
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled={!data.isUsernameChangeRequired}
                  onBlur={(e) => validateOnBlur(e, "newUsername")}
                />
              </Form.Item>

              <Form.Item
                name="email"
                label="Email"
                className="mb-5"
                initialValue={data.email}
                rules={[
                  {
                    required: true,
                    message: EMAIL_REQUIRED,
                    validateTrigger: "onSubmit",
                  },
                  {
                    pattern: EMAIL_RULE,
                    message: WRONG_EMAIL_FORMAT,
                    validateTrigger: "onSubmit",
                  },
                  {
                    max: 100,
                    message: MAX_EMAIL,
                    validateTrigger: "onSubmit",
                  },
                ]}
              >
                <Input
                  size="large"
                  disabled={!data.isEmailChangeRequired}
                  onBlur={(e) => validateOnBlur(e, "email")}
                />
              </Form.Item>

              <PasswordInput
                setIsPasswordValid={setIsPasswordValid}
                form={form}
                label="Password"
              />

              <Form.Item className="mt-6">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  disabled={!isPasswordValid || isFormDisabled}
                  block
                >
                  Confirm
                  {isFormDisabled && <LoadingOutlined />}
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Loader fontSize="64" />
          )}
        </Row>
      </Col>
    </Row>
  );
};
