import {
  CloseCircleFilled,
  CheckCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { ReactNode } from "react";
import "./styles.scss";
import { useAppDispatch } from "../../store";
import { clearMessage } from "../../acions/message";
import { useEffect } from "react";

type AlertProps = {
  children: ReactNode | string;
  type: "success" | "error" | "info";
  shouldDisapear: boolean;
};

const TIME_TO_HIDE = 3000;

export const Alert = ({ children, type, shouldDisapear }: AlertProps) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    let timetId: any = null;
    if (type === "success" || type === "info" || shouldDisapear) {
      timetId = setTimeout(() => {
        dispatch(clearMessage());
      }, TIME_TO_HIDE);
    }

    return () => clearTimeout(timetId);
  }, [dispatch, type, shouldDisapear]);

  return (
    <div
      className={`notification__container ${type} py-2 px-3 d-flex align-center`}
    >
      {type === "error" && <CloseCircleFilled className="mr-2" />}

      {type === "success" && <CheckCircleFilled className="mr-2" />}

      {type === "info" && <InfoCircleFilled className="mr-2" />}

      <div className="notification-content">
        <div className="message">{children}</div>
      </div>
    </div>
  );
};
