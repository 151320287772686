import { Button } from "antd";
import { NavigateFunction, Location } from "react-router-dom";
import { useSelector } from "react-redux";

type NavItemsProps = {
  navigate: NavigateFunction;
  location: Location;
};

export const NavItems = ({ navigate, location }: NavItemsProps) => {
  const count: number = useSelector((state: any) => state.requestUsers.count);

  const getClassName = (path: string): string => {
    return `header-button ${location.pathname === path && "active"}`;
  };

  return (
    <div className="d-flex">
      <Button
        type="text"
        className={getClassName("/home")}
        onClick={() => navigate("/home")}
      >
        Home
      </Button>
      <Button
        type="text"
        className={getClassName("/accounts")}
        onClick={() => navigate("/accounts")}
      >
        Accounts
      </Button>
      <Button
        type="text"
        className={getClassName("/user-management")}
        onClick={() => navigate("/user-management")}
      >
        User Management
      </Button>
      <Button
        type="text"
        className={getClassName("/user-activation")}
        onClick={() => navigate("/user-activation")}
      >
        User Activation
        {count !== 0 && (
          <span className="counter-label ml-1 text-sm">
            {count > 9 ? "9+" : count}
          </span>
        )}
      </Button>
    </div>
  );
};
