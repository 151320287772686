import { ColumnsType } from "antd/es/table";
import { getFormatted } from "../../lib/PhoneFormatter";
import { EllipsisOutlined } from "@ant-design/icons";
import { ReactNode } from "react";
import { Dropdown, MenuProps } from "antd";

export const onSortChange = (
  sorter: any,
  setRequest: (value: any) => void,
  request: any
) => {
  const newRequest = { ...request };
  if (!sorter.order) {
    delete newRequest["order"];
  }

  if (sorter.field === "fullName") {
    if (sorter.order === "ascend") {
      newRequest["order"] = 0;
    }

    if (sorter.order === "descend") {
      newRequest["order"] = 1;
    }
  }

  if (sorter.field === "status") {
    if (sorter.order === "ascend") {
      newRequest["order"] = 2;
    }

    if (sorter.order === "descend") {
      newRequest["order"] = 3;
    }
  }

  setRequest(newRequest);
};

const getDefaultCell = (value: string) => {
  return (
    <div className="cell-elipsis" title={value}>
      <span>{value}</span>
    </div>
  );
};

export const getTableColumns = (
  onOpenSideBar: (
    header: string | ReactNode,
    userId: string,
    status: number
  ) => void,
  getStatus: (value: number) => ReactNode,
  getHeader: (record: any, value: string) => ReactNode,
  openDeleteModal: (userId: string) => void
): ColumnsType<any> => {
  return [
    {
      title: "Name",
      dataIndex: "fullName",
      render: (value: string, record: any) => {
        const header = getHeader(record, record.firstName);
        return (
          <div
            className="cell-elipsis"
            title={value}
            onClick={() => onOpenSideBar(header, record.id, record.status)}
          >
            <span className="link-btn">{value}</span>
          </div>
        );
      },
      sorter: true,
      ellipsis: true,
      width: "20%",
    },
    {
      title: "Username",
      dataIndex: "userName",
      render: (value: string) => getDefaultCell(value),
      width: "20%",
    },
    {
      title: "Email",
      dataIndex: "email",
      render: (value: string) => getDefaultCell(value),
      width: "20%",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (value: string) => getFormatted(value).slice(2),
      width: "20%",
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (value: number) => getStatus(value),
      sorter: true,
      width: "12%",
    },
    {
      title: "Action",
      render: (value: any, record: any) => {
        const header = getHeader(record, record.firstName);

        const items: MenuProps["items"] = [
          {
            key: "1",
            label: (
              <div
                onClick={() => onOpenSideBar(header, record.id, record.status)}
              >
                View Request
              </div>
            ),
          },
        ];

        if (record.status === 4) {
          items.push({
            key: "2",
            label: (
              <div onClick={() => openDeleteModal(record.id)}>
                Delete Request
              </div>
            ),
          });
        }

        return (
          <Dropdown menu={{ items }} trigger={["click"]}>
            <EllipsisOutlined />
          </Dropdown>
        );
      },
      width: "8%",
    },
  ];
};
