import { useState } from "react";
import { useLocation } from "react-router-dom";

type propState = {
  email?: string;
};

export const useGetEmailFromLocation = () => {
  const location = useLocation();

  let initialData: string = "";
  if (location.state) {
    const { email } = location.state as propState;
    initialData = email || "";
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, _] = useState<string>(initialData);

  return [email];
};
