import { CustomModal } from "../../lib/CustomModal";
import { Input, Form, Button } from "antd";
import { REASON_REQUIRED } from "../../lib/constants";
import { useAppDispatch } from "../../store";
import { blockUnblockUser } from "../../acions/users";
import { useState } from "react";

type ReasonModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (value: boolean) => void;
  isBlock: boolean;
  userId: string | null;
  reloadData: () => void;
};

const { TextArea } = Input;

export const ReasonModal = ({
  isModalOpen,
  setIsModalOpen,
  isBlock,
  userId,
  reloadData,
}: ReasonModalProps) => {
  const dispatch = useAppDispatch();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onSubmitClicked = (values: any) => {
    if (userId) {
      setIsLoading(true);
      Promise.resolve(
        dispatch(blockUnblockUser({ userId, reason: values.reason }, isBlock))
      )
        .then(() => {
          setIsLoading(false);
          setIsModalOpen(false);
          reloadData();
          form.resetFields();
        })
        .catch(() => {
          setIsLoading(false);
          form.resetFields();
        });
    }
  };

  return (
    <CustomModal
      isModalOpen={isModalOpen}
      setIsModalOpen={setIsModalOpen}
      titleText={`Specify the reason for ${
        isBlock ? "blocking" : "unblocking"
      }`}
      width={396}
      headerClass="mr-3 text w-500"
      modalClass="reason-modal"
    >
      <div className="mt-8">
        <p className="my-0">{`${
          isBlock ? "Blocking" : "Unblocking"
        } reason will be sent to the user via email.`}</p>
        <div className="mt-6">
          <Form
            layout="vertical"
            autoComplete="off"
            requiredMark={false}
            onFinish={onSubmitClicked}
            disabled={isLoading}
            form={form}
          >
            <Form.Item
              name="reason"
              label={`${isBlock ? "Blocking" : "Unblocking"} reason`}
              className="mb-5"
              rules={[
                {
                  required: true,
                  message: REASON_REQUIRED,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <TextArea rows={2} />
            </Form.Item>

            <Form.Item className="mt-8 mb-0">
              <div className="d-flex justify-end mt-8 mb-0">
                <Button
                  shape="round"
                  className="mr-2"
                  onClick={() => setIsModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button type="primary" shape="round" htmlType="submit">
                  Submit
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </CustomModal>
  );
};
