import moment from "moment";
import { DATE_FORMAT } from "../../../../lib/constants";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";

type CouponProps = {
  selectedContract: any;
  data: any;
};

export const Coupon = ({ selectedContract, data }: CouponProps) => {
  return (
    <div id="coupon">
      <div className="data column">
        <p className="label">Buyer(s):</p>
        <p>{selectedContract.buyer}</p>
        <p>&nbsp;</p>
        <p className="label">Please Remit Payment to:</p>
        <p>
          Sunwest Escrow, LC
          <br />
          P.O. Box 36371
          <br />
          Albuquerque, NM 87176-6371
        </p>
        <p>&nbsp;</p>
      </div>
      {data && (
        <dl className="data column">
          <dt>Payment Date:</dt>
          <dd className="underline">
            {data.nextPayment.paymentDate
              ? moment(data.nextPayment.paymentDate).format(DATE_FORMAT)
              : "-"}
          </dd>
          <dt>Contract Number:</dt>
          <dd>{selectedContract.accountNumber}</dd>
          <dt>Payment Amount:</dt>
          <dd className="underline">$</dd>
          <dt className="noemph">*Expected Pmt Amt:</dt>
          <dd className="noemph">
            {getFormattedCurrency(data.nextPayment.amount)}
          </dd>
        </dl>
      )}
      <p className="data">
        To insure proper credit to your account, please include a coupon with
        the payment.
      </p>
    </div>
  );
};
