import { Row } from "antd";

export const Policy = () => {
  return (
    <Row className="d-block pa-8 terms-modal-body">
      <h4 className="h h4 mt-0 mb-4 w-500">NOTESERVE, LLC</h4>
      <h4 className="h h4 mt-0 mb-4 w-500">PRIVACY POLICY</h4>
      <p className="text-md mt-0 mb-4 w-500">Effective as of April 15, 2024​</p>
      <p className="text-md mt-0 mb-4">
        At NoteServe, LLC, we respect the privacy of our customers, visitors,
        users, and others (collectively "
        <span className="underline">Users</span>" or individually a “
        <span className="underline">User</span>”). This Privacy Policy ("
        <span className="underline">Privacy Policy</span>") describes our
        privacy practices and applies to the websites, platform, software, and
        all other services (collectively, “
        <span className="underline">Services</span>”) we offer to Users. Please
        read this Privacy Policy carefully to learn how we collect, use, share
        and otherwise process personally identifiable information and
        information that can reasonably be used to contact or distinguish a
        person (collectively "<span className="underline">Personal Data</span>
        "), and your rights and choices regarding our processing of your
        Personal Data.
      </p>
      <p className="text-md mt-0 mb-4">
        A reference to “we,” “us,” “our,” “NoteServe” or the “Company” is a
        reference to NoteServe, LLC, a New Mexico limited liability company. A
        reference to “you” or “your” means the User, an individual or, if
        applicable, the company or legal entity you represent.
      </p>
      <p className="text-md mt-0 mb-4">
        Use of our Sites and Services is subject to our Terms of Use (“
        <span className="underline">Terms of Use</span>”), available here.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        1.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Activities Covered</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        This Privacy Policy applies to the following activities:
      </p>
      <ul>
        <li className="mb-4">
          Visiting any of our websites (“
          <span className="underline">Sites</span>”), such as
          portal.sunwestescrow.com, and any other site that displays or link to
          this Privacy Policy;
        </li>
        <li className="mb-4">
          Using our software and platform, including our proprietary technology
          like the NoteServe record keeping platform (the “
          <span className="underline">Platform</span>”);
        </li>
        <li className="mb-4">
          Creating, changing or using a User account, whether as an Escrow
          Provider or Customer, as such terms are defined in the Terms of Use
          (an “<span className="underline">Account</span>”);
        </li>
        <li className="mb-4">
          Browsing or interacting with our Sites and Services via the Internet;
        </li>
        <li className="mb-4">
          Services, as defined in the Terms of Use, for Users;
        </li>
        <li className="mb-4">
          Interacting with our personnel, whether in person, on the telephone,
          via email or any other form of communication;
        </li>
        <li className="mb-4">
          Processing payments through third-party service providers on our Sites
          for Services;
        </li>
        <li className="mb-4">
          Interacting with and submitting inquiries to third-parties with whom
          we have a relationship, including Escrow Providers;
        </li>
        <li className="mb-4">
          Internal research and development, including quality assurance and
          control of the Services and Platform; and
        </li>
        <li className="mb-4">
          Receiving communications from us, including our newsletter, texts,
          fax, notifications through the Platform or emails.
        </li>
      </ul>
      <p className="text-md mt-0 mb-4">
        This Privacy Policy does not apply to information collected by:
      </p>
      <ul>
        <li className="mb-4">
          Offline interactions with NoteServe through any other means, including
          any application, software, platform, or website provided by any
          third-party other than the Platform (even if co-branded with NoteServe
          or operated for NoteServe’s benefit); or
        </li>
        <li className="mb-4">
          Any third-party, including any application or content (including
          advertising) that may link to or be accessible from (or on) any Site
          or through the Services.
        </li>
      </ul>
      <h4 className="h h4 mt-0 mb-4">
        2.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Responsible Entity</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        When Sites, Services, or other materials are provided by us and linked
        to this Privacy Policy, this Privacy Policy applies. Our Sites may
        contain links to other websites, applications and services maintained by
        third-parties. When visiting such websites, applications or services,
        this Privacy Policy does not apply. The information practices of such
        other websites, applications or services are governed by the third-party
        privacy policies, which we encourage you to review to better understand
        those third-parties’ privacy practices.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        3.&nbsp;&nbsp;&nbsp;
        <span className="w-500">What Personal Data Do We Collect and How?</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        Personal Data we collect directly from you may include the following:
      </p>
      <ul>
        <li className="mb-4">
          <span className="underline">Personal Information:</span>&nbsp; This
          includes your social security number, employer identification number,
          or other tax ID number for verification purposes to utilize the
          Services.
        </li>
        <li className="mb-4">
          <span className="underline">Contact Information:</span>&nbsp; This
          includes, but is not limited to, your name, email, address, and
          telephone number(s).
        </li>
        <li className="mb-4">
          <span className="underline">Entity Information:</span>&nbsp; This
          includes, but is not limited to, the name of your legal entity or
          employer, the jurisdiction of organization, and your affiliation or
          title.
        </li>
        <li className="mb-4">
          <span className="underline">Account Information:</span>&nbsp; This
          includes your account identification number, username, password, and
          answers to your recovery/security questions.
        </li>
        <li className="mb-4">
          <span className="underline">Billing Information:</span>&nbsp; To the
          extent necessary for you to purchase products or services from us or a
          third-party who offers products or services in connection with our
          Services, either NoteServe or a third-party payment processor will
          collect your financial account information, including routing and
          wiring numbers, credit card information, including your billing zip
          code, and/or other payment information.
        </li>
        <li className="mb-4">
          <span className="underline">Demographic Information:</span>&nbsp; This
          includes, but is not limited to, your date of birth, your country of
          citizenship, and/or your country of residence.
        </li>
        <li className="mb-4">
          <span className="underline">Transaction Information:</span>&nbsp; This
          includes details about your transaction history with Escrow Providers,
          including contracts you may execute (by using your traditional,
          electronic or any other binding signature or form of assent).
        </li>
        <li className="mb-4">
          <span className="underline">Technical Data:</span>&nbsp; If you use
          and interact with our Sites, we automatically collect log files and
          other information about your device, browser and your usage of our
          sites through cookies, web beacons or similar technologies, such as
          IP-addresses, media access control (MAC) addresses or other
          identifiers, which may qualify as Personal Data (view the "
          <span className="text-md w-500">
            Device and Usage Data We Process
          </span>
          " section below).
        </li>
        <li className="mb-4">
          <span className="underline">Feedback and correspondence:</span>&nbsp;
          This includes information you provide when you request information
          from us, receive customer support, or otherwise correspond with us,
          including by interacting with our pages on social networking sites or
          services. This includes your preferences for receiving marketing
          communications and details about how you engage with our marketing
          communications
        </li>
        <li className="mb-4">
          <span className="underline">Other Information:</span>&nbsp; We may
          retain a record of any other information you provide us while using
          our Services.
        </li>
      </ul>
      <p className="text-md mt-0 mb-4">
        Additionally, we may collect data that is about you but does not
        individually identify you, such as your domain server, device type,
        browser type, internet connection, search terms, operating systems, and
        other information about your use of our Sites and the Services. We
        cannot identify you by this information alone, but it is helpful for
        generally understanding our traffic when aggregated and improving Users’
        experiences when using our Sites and Services.
      </p>
      <p className="text-md mt-0 mb-4">We collect information:</p>
      <ul>
        <li className="mb-4">Directly from you when you provide it to us;</li>
        <li className="mb-4">
          From Escrow Providers when they engage with our Platform; and
        </li>
        <li className="mb-4">
          From third-parties, for example, our business partners, including
          vendors.
        </li>
      </ul>
      <h4 className="h h4 mt-0 mb-4">
        4.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Third-Party Service Providers</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        To assist us in meeting business operations needs and perform certain
        services and functions, your Personal Data may be shared with
        third-party providers of payment, hosting, customer relations management
        software developers, website development, creative development,
        technical support, network and security, e-mail communication, and
        customer support services. We use Azure, including Azure Communications,
        for hosting and communications, and you can find more information about
        their privacy practices&nbsp;
        <a
          href="https://privacy.microsoft.com/en-us/privacystatement/"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . We use Fluid Pay as a payment processor and provider, and you can find
        more information about their privacy practices&nbsp;
        <a
          href="https://www.fluidpay.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . We use Coherent Solutions for development and technical support, and
        you can find more information about their privacy practices&nbsp;
        <a
          href="https://www.coherentsolutions.com/privacy-policy"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <p className="text-md mt-0 mb-4">
        For more details on the third parties that may place cookies through the
        Site, please see the&nbsp;
        <span className="text-md w-500">
          “Cookies, Web Beacons, and Other Tracking Technologies”
        </span>
        &nbsp; section below. Following our instructions, these parties may
        access, process or store Personal Data in the course of performing their
        duties for us.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        5.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Device and Usage Data We Process</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        We use common information-gathering tools, such as log files, web
        beacons, and similar technologies to automatically collect information,
        which may contain Personal Data, from your computer or mobile device as
        you navigate our website or interact with emails and other messages, we
        send you.
      </p>
      <p className="text-md mt-0 mb-4">
        Like many websites, our Sites gather certain other
        non-personally-identifying information (“
        <span className="underline">Non-PII</span>”) automatically via log
        files. This collected Non-PII may include your Internet Protocol (IP)
        address (or proxy server), device and application identification
        numbers, your location, your browser type, your Internet service
        provider and/or mobile carrier, the pages and files you viewed, your
        searches, your operating system and system configuration information,
        and date/time stamps associated with your usage. This Non-PII is used to
        analyze overall trends, to help us provide and improve our Sites and
        Services and to guarantee their security and continued proper
        functioning. We may also collect IP addresses and MAC addresses from
        Users when they log into the services as part of the Company’s security
        features.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        6.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Cookies, Web Beacons, and Other Tracking Technologies
        </span>
      </h4>
      <p className="text-md mt-0 mb-4">
        Our Third-Party Service Providers may use cookies and similar
        technologies such as web beacons, tags, and Javascript alone or in
        conjunction with cookies to compile information about usage of our
        websites and interaction with emails from us.
      </p>
      <p className="text-md mt-0 mb-4">
        When you visit our Sites, our servers or an authorized third-party may
        place a cookie on your browser, which can collect information, including
        Personal Data, about your online activities over time and across
        different sites. Cookies allow us to track overall usage, determine
        areas that you prefer, make your usage easier by recognizing you and
        providing you with a customized experience.
      </p>
      <p className="text-md mt-0 mb-4">
        We may use both session-based and persistent cookies. Session cookies
        exist only during one session and disappear from your computer when you
        close your browser software or turn off your computer. Persistent
        cookies remain on your computer or device after you close your browser
        or turn off your computer. You can control the use of cookies at the
        individual browser level, but if you choose to disable cookies, it may
        limit your use of certain features or functions on our websites or
        services. To opt-out of tracking by Third-Party Analytics Providers,
        please use the specific opt-out mechanism as indicated in the&nbsp;
        <span className="text-md w-500">
          “Opting-Out from Collection of Device and Usage Data”
        </span>
        &nbsp;section below.
      </p>
      <p className="text-md mt-0 mb-4">
        As described above, we or third-parties may place or recognize a web
        beacon or unique cookie on your browser when you visit our websites for
        the purposes of serving you targeted advertising (also referred to as
        “online behavioral advertising” or “interest-based advertising”).
      </p>
      <p className="text-md mt-0 mb-4">
        To manage the use of targeting or advertising cookies on our websites,
        consult your individual browser settings for cookies. Various browsers
        may offer their own management tools for removing HTML5 local storage.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        7.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Opting-Out from Collection of Device and Usage Data
        </span>
      </h4>
      <p className="text-md mt-0 mb-4">
        You may opt-out from the collection of device and usage data by managing
        your cookies at the individual browser level. In addition, if you wish
        to opt-out of interest-based advertising, click here. Please note,
        however, that by blocking or deleting cookies and similar technologies
        used on our websites, you may not be able to take full advantage of our
        Sites.
      </p>
      <p className="text-md mt-0 mb-4">
        While some Internet browsers offer a “do not track” or “DNT” option that
        lets you tell websites that you do not want to have your online
        activities tracked, these features are not yet uniform and there is no
        common standard that has been adopted by industry groups, technology
        companies, or regulators. Therefore, we do not currently commit to
        responding to browsers' DNT signals with respect to our Sites. In other
        words, we do not alter our practices with respect to the collection of
        information based upon a User’s selection of the DNT setting or other
        opt out setting. The Company takes privacy and meaningful choice
        seriously and will make efforts to continue to monitor developments
        around DNT browser technology and the implementation of a standard.
      </p>
      <p className="text-md mt-0 mb-4">
        To learn more about targeted advertising, advertising networks and your
        ability to opt out of collection by certain third parties, please visit
        the opt-out pages of the Network Advertising Initiative&nbsp;
        <a
          href="https://optout.networkadvertising.org/?c=1"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        , and the Digital Advertising Alliance,&nbsp;
        <a
          href="https://optout.aboutads.info/?c=2&lang=EN"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        . To learn how to manage privacy and storage settings for Flash
        cookies,&nbsp;
        <a
          href="https://optout.networkadvertising.org/?c=1"
          target="_blank"
          rel="noreferrer"
        >
          here
        </a>
        .
      </p>
      <h4 className="h h4 mt-0 mb-4">
        8.&nbsp;&nbsp;&nbsp;
        <span className="w-500">How We Use Your Personal Data</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        We use information that we collect about you or that you provide to us,
        including any Personal Data, for the following:
      </p>
      <ul>
        <li className="mb-4">
          <span className="underline">Services:</span>&nbsp;We will process your
          Personal Data to the extent necessary for the performance of the
          Services through our contract with you for the use of our Site and
          Services and to fulfill our obligations under our Terms of Use and
          other applicable contracts with you or other Users; where we have not
          entered into a contract with you (or where our Terms of Use do not
          apply), we base the processing of your Personal Data on our legitimate
          interest to operate and administer our Sites and to provide you with
          content you access and request. Examples of how we use your Personal
          Data in connection with the provision of Service include: (i) to
          process data on our platform as part of the Services, including for
          other Users; (ii) to establish and manage your account, including to
          communicate with you about changes or updates to our Services,
          technical and security alerts, account verification, support, etc.;
          and (iii) to provide, operate, maintain, improve, and monitor the
          effectiveness of our Services.
        </li>
        <li className="mb-4">
          <span className="underline"> Marketing Communications:</span>&nbsp;
          With your consent, we may use your contact data to send you marketing
          and other promotional information about us, our products, or services.
          You may opt out of marketing communications at any time by clicking on
          the “unsubscribe” link in any marketing email or by following the
          applicable instruction in a communication from us.
        </li>
        <li className="mb-4">
          <span className="underline">Users’ Requests:</span>&nbsp;For the
          purposes for which you provided the information, for the legitimate
          business purpose of fulfilling our Users’ requests.
        </li>
        <li className="mb-4">
          <span className="underline">Administration of Services:</span>&nbsp;
          To monitor and analyze information to diagnose problems of our Sites,
          for the legitimate business interest of operating and administering
          our websites, platform and other Services.
        </li>
        <li className="mb-4">
          <span className="underline">Anonymous Analytics:</span>&nbsp;To carry
          out internal operations and to analyze transactions on an anonymous
          basis by de-identifying certain Personal Data or other personally
          identifiable information, for the legitimate business interests of
          understanding our current and potential User demographics.
        </li>
        <li className="mb-4">
          <span className="underline">Security:</span>&nbsp;For fraud
          prevention, internal operations, legal compliance, and other public
          and security related purposes, to the extent this is necessary for the
          purpose of our legitimate interests in promoting the safety and
          security of the systems and application used for our Sites and
          protecting our rights and the rights of others.
        </li>
        <li className="mb-4">
          <span className="underline">Contract Enforcement:</span>&nbsp;We may
          use your Personal Data to investigate, enforce, and apply our Terms of
          Use, this Privacy Policy, or any other agreement we may have with a
          User or third-party vendor.
        </li>
        <li className="mb-4">
          <span className="underline">Legal Compliance:</span>&nbsp;We will
          process your Personal Data when cooperating with public and government
          authorities, courts or regulators in accordance with our legal
          obligations under applicable laws to the extent this requires the
          processing or disclosure of Personal Data to protect our rights, and
          is necessary for our legitimate interests to protect against misuse or
          abuse of our websites, to protect personal property or safety, to
          pursue remedies available to us and limit our damages, to comply with
          a judicial proceedings, court order or legal process, and/or to
          respond to lawful requests.
        </li>
        <li className="mb-4">
          <span className="underline">With Consent:</span>&nbsp;To fulfill any
          other purpose for which you provide Personal Data, with your consent.
        </li>
      </ul>
      <h4 className="h h4 mt-0 mb-4">
        9.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Who Do We Share Personal Data With?</span>
      </h4>
      <ul>
        <li className="mb-4">
          <span className="underline">Advisors.</span>&nbsp;In individual cases
          we may also share Personal Data with professional advisors acting as
          processors or joint controllers including lawyers, bankers, auditors
          and insurers who provide consultancy, banking, legal, insurance, and
          accounting services.
        </li>
        <li className="mb-4">
          <span className="underline">Internal Disclosures.</span>&nbsp;We may
          share Personal Data within our headquarters and among affiliates,
          other companies and brands owned or controlled by NoteServe, LLC and
          other companies owned by or under common ownership as NoteServe, LLC.
          Personal Data will only be available to those who need such access for
          purposes consistent with this Privacy Policy, including without
          limitation product development, product management, customer support,
          account management, marketing, sales, and operations, or where
          required by applicable law.
        </li>
        <li className="mb-4">
          <span className="underline">Service Providers.</span>&nbsp;We engage
          certain trusted third-parties to perform functions and provide
          services to us (e.g., verification providers, website hosting
          providers and other parties who assist us in operating our Sites,
          conducting the Services, managing our marketing campaigns and
          mailings, performing tax and accounting activities, performing
          analytics, or serving our customers). We may share your Personal Data
          with these third-parties, but only to the extent necessary to perform
          these functions and provide such services. We also require these
          third-parties to maintain the privacy and security of the Personal
          Data they process on our behalf.
        </li>
        <li className="mb-4">
          <span className="underline">Other Disclosures.</span>&nbsp;If we are
          involved in a merger or reorganization, a financing that involves due
          diligence, bankruptcy, receivership, transition to different service
          providers, sell business unit, or if all or a portion of our business,
          assets or stock are acquired by another company, we may transfer some
          or all of your Personal Data to such third party. In accordance with
          applicable laws, we will use reasonable efforts to notify you of any
          such transfer of Personal Data to an unaffiliated third party as
          processing of your Personal Data will be required for the purposes set
          out in "
          <span className="text-md w-500">How We Use Your Personal Data</span>"
          section above.
        </li>
      </ul>
      <h4 className="h h4 mt-0 mb-4">
        10.&nbsp;&nbsp;&nbsp;
        <span className="w-500">How Long Do We Keep Your Personal Data?</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        We may retain your Personal Data for a period of time consistent with
        the original purpose of collection. We determine the appropriate
        retention period for Personal Data on the basis of the amount, nature,
        contact frequency, and sensitivity of your Personal Data, the potential
        risk of harm from unauthorized use or disclosure, and whether we can
        achieve the purposes of the processing through other means, as well as
        the applicable legal requirements. For example, NoteServe may be
        required to maintain transaction documentation for seven (7) years from
        the close of the transaction related to the Contracts.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        11.&nbsp;&nbsp;&nbsp;
        <span className="w-500">
          Your Rights Relating to Your Personal Data
        </span>
      </h4>
      <p className="text-md mt-0 mb-4">
        You may have certain rights regarding your Personal Data,&nbsp;
        <span className="text-md w-500 underline">
          subject to local data protection laws
        </span>
        &nbsp;. These may or may not include the following rights:
      </p>
      <ul>
        <li className="mb-4">
          To access your Personal Data held by us (“Right to Access”);
        </li>
        <li className="mb-4">
          To rectify inaccurate Personal Data and ensure it is complete (“Right
          to Rectification”);
        </li>
        <li className="mb-4">
          To erase/delete your Personal Data to the extent permitted by other
          legal obligations (“Right to Erasure; Right to be Forgotten”);
        </li>
        <li className="mb-4">
          To restrict our processing of your Personal Data (“Right to
          Restriction of Processing”);
        </li>
        <li className="mb-4">
          To transfer your Personal Data to another controller, to the extent
          possible (“Right to Data Portability”);
        </li>
        <li className="mb-4">
          To object to any processing of your Personal Data carried out on the
          basis of our legitimate interests (“Right to Object”). Where we
          process your Personal Data for direct marketing purposes or if we
          share it with third-parties for their own direct marketing purposes,
          you can exercise your right to object at any time to such processing
          without having to provide any specific reason for such objection;
        </li>
        <li className="mb-4">
          · Not to be subject to a decision based solely on automated
          processing, including profiling, which produces legal effects ("
          <span className="underline">Automated Decision-Making</span>");
          Automated Decision-Making currently does not take place on our Sites;
          and
        </li>
        <li className="mb-4">
          To the extent we base the collection, processing, and sharing of your
          Personal Data on your consent, to withdraw your consent at any time,
          without affecting the lawfulness of the processing based on such
          consent before its withdrawal.
        </li>
      </ul>
      <p className="text-md mt-0 mb-4">
        The specific rights to which you are entitled are based upon your
        geographic location, and the location of where your Personal Data is
        stored.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        12.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Notice to California and Nevada Residents</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        If you are a resident of California, the California Consumer Protection
        Act (“<span className="underline">CCPA</span>”) provides you with
        certain rights over your personal information (defined under the CCPA as
        information which identifies, relates to, describes, is reasonably
        capable of being associated with, or could reasonably be linked,
        directly or indirectly, with a particular consumer or household). In
        addition, the term “sale” is defined broadly under the CCPA. To the
        extent that “sale” under the CCPA is interpreted to include interest
        based advertising or other data uses described in this Privacy Policy,
        we will comply with applicable law as to those activities. You can read
        more about your rights and choices with respect to your personal
        information that is held by us under the CCPA at here.
      </p>
      <p className="text-md mt-0 mb-4">
        We reserve the right to disclose your Personal Data to any of our: (i)
        affiliates that referred you to our site for purposes of sales by us,
        and (ii) resellers that may re-sell our products or services to you. We
        may also disclose your Personal Data to any marketing partners that may
        participate in the offer or related fulfillment as described herein.
        Understand that you originated with these affiliates and resellers, and
        that the information you may provide to them is subject to their privacy
        policies also. California Civil Code Sec. 1798.83 permits California
        residents to request certain information regarding disclosure of their
        Personal Data to third-parties for purposes of direct marketing. You may
        request further information about our compliance with California Civil
        Code Section 1798.83 at the following email address:
        support@noteserve.com.
      </p>
      <p className="text-md mt-0 mb-4">
        If you are a Nevada resident, you have the right to opt out of the sale
        of your Personal Data collected by us through the Site.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        13.&nbsp;&nbsp;&nbsp;
        <span className="w-500">How to Exercise Your Rights</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        To exercise your rights, please contact us in accordance with the
        “Contact Us” section below. We try to respond to all legitimate requests
        within one month and will contact you if we need additional information
        from you in order to honor your request. Occasionally it may take us
        longer than a month, considering the complexity and number of requests
        we receive.
      </p>
      <p className="text-md mt-0 mb-4">
        In addition, if you have registered for an account with us, you may
        generally update your User settings, profile, organization’s settings by
        logging into the Platform and editing your information, however we are
        not able to access, change, or delete all of your Personal Data. To
        update any financial information, update Contracts and transaction
        information, and/or request return or deletion of your Personal Data and
        other information associated with your account, please direct the
        request to your Escrow Provider to update or remove your Personal Data
        from our systems.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        14.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Security</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        We take precautions including organizational, technical, and physical
        measures, to help safeguard against accidental or unlawful destruction,
        loss, alteration, unauthorized disclosure of, or access to, the Personal
        Data we process or use. These precautions include hashing of passwords,
        restricting access to authorized users, two-factor authentication for
        certain actions, segregated databases, segregated database and
        application environments, and encrypted connections to the databases and
        user browsers.
      </p>
      <p className="text-md mt-0 mb-4">
        While we follow generally accepted standards to protect Personal Data,
        no method of storage or transmission is 100% secure. You are solely
        responsible for protecting your password, limiting access to your
        devices, and signing out of websites after your sessions. If you have
        any questions about the security of our websites, please contact us via
        the “Contact Us” section below.
      </p>
      <p className="text-md mt-0 mb-4">
        Unfortunately, the transmission of information via the Internet is not
        completely secure. Although we take steps to protect your Personal Data,
        we cannot guarantee the security of your Personal Data transmitted to
        our Sites or Services. Any transmission of Personal Data or information
        is at your own risk. We are not responsible for circumvention of any
        privacy settings or security measures contained on the Sites or the
        Services.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        15.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Changes to this Privacy Policy</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        We will update this Privacy Policy from time to time in our sole
        discretion to reflect changes in our practices, technology, legal
        requirements, and other factors. If we do, we will update the effective
        date at the top of this Privacy Policy. If we make an update, we may
        provide you with notice prior to the update taking effect, such as by
        posting a conspicuous notice on our Sites or by contacting you using the
        email address you provided.
      </p>
      <p className="text-md mt-0 mb-4">
        We encourage you to periodically review this Privacy Policy to stay
        informed about our collection, processing and sharing of your Personal
        Data.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        16.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Children</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        Our websites are not directed at children. We do not knowingly collect
        Personal Data from children under the age of 13. The Services are
        intended for Users over the age of 18. If you are a parent or guardian
        and believe your child has provided us with Personal Data without your
        consent, please contact us as described in the “Contacting Us” section
        below and we will take steps to delete such Personal Data from our
        systems.
      </p>
      <h4 className="h h4 mt-0 mb-4">
        17.&nbsp;&nbsp;&nbsp;
        <span className="w-500">Contact Us</span>
      </h4>
      <p className="text-md mt-0 mb-4">
        To exercise your rights regarding your Personal Data, or if you have
        questions regarding this Privacy Policy or our privacy practices please
        mail us at:
      </p>
      <div className="ml-4">
        <p className="text-md mt-0 mb-1">
          NoteServe, LLC; Data Protection and Privacy
        </p>
        <p className="text-md mt-0 mb-1">
          PO Box 36371, Albuquerque, NM 87176-6371
        </p>
        <p className="text-md mt-0 mb-4">
          <a href="mailto:info@sunwestescrow.com">support@noteserve.com</a>
        </p>
      </div>
      <p className="text-md mt-0 mb-4">
        We are committed to working with you to obtain a fair resolution of any
        complaint or concern about privacy.
      </p>
    </Row>
  );
};
