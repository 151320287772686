import { Row, Col, Button } from "antd";
import image from "../../images/404.svg";
import "./styles.scss";
import { useNavigate } from "react-router-dom";

export const NotFound = () => {
  const navigate = useNavigate();

  return (
    <div className="height-100">
      <Row className="flex-column justify-center height-100 error-page">
        <Row>
          <Col className="error-page-left__container">
            <h1 className="header h h1 pb-9">
              Sorry, we can’t find this page.
            </h1>
            <div className="pt-9 pb-9">
              <div className="text">Error code: 404.</div>
              <div className="text pt-6">
                This page might have removed or you might accidentally typed the
                wrong URL in the address bar
              </div>
            </div>
            <h4 className="pt-9 pb-8 h h4">What to do?</h4>
            <div className="text pb-5">You might try retyping the URL.</div>
            <div className="text pb-5">
              Or{" "}
              <Button
                type="link"
                className="text"
                onClick={() => navigate("/accounts")}
              >
                return to homepage
              </Button>
              .
            </div>
            <div className="text">
              Or if you feel something is missing that should be here,{" "}
              <Button
                type="link"
                className="text"
                href="mailto:info@sunwestescrow.com"
              >
                contact us
              </Button>
              .
            </div>
          </Col>
          <Col className="error-img">
            <img src={image} alt="404" />
          </Col>
        </Row>
      </Row>
    </div>
  );
};
