import { useEffect, useState, useMemo, useCallback, ReactNode } from "react";
import { Row, Button } from "antd";
import { MultiSelect } from "../../components/MultiSelect";
import { createPortal } from "react-dom";
import { SideBarContainer } from "../../components/SideBarContainer";
import { CreateAdmin } from "./SideBar/CreateAdmin";
import { SearchWithClear } from "../../components/SearchWithClear";

type FiltersProps = {
  roles: any[];
  isLoading: boolean;
  query: any;
  search: string;
  debouncedSearch: string;
  setQuery: (value: any) => void;
  fetchUsers: () => void;
  setCurrentPage: (value: number) => void;
  loadCount: () => void;
  onSearchChange: (e: any) => void;
  onClearSearch: () => void;
};

export const Filters = ({
  roles,
  isLoading,
  query,
  search,
  debouncedSearch,
  setQuery,
  fetchUsers,
  setCurrentPage,
  loadCount,
  onSearchChange,
  onClearSearch,
}: FiltersProps) => {
  const [showSideBar, setShowSideBar] = useState<boolean>(false);

  const statuses: any[] = useMemo(
    () => [
      { label: "Active", value: 1 },
      { label: "Blocked", value: 2 },
    ],
    []
  );

  const relaodData = useCallback((): void => {
    fetchUsers();
    loadCount();
  }, [fetchUsers, loadCount]);

  const sideBar = useMemo(
    (): ReactNode => (
      <SideBarContainer
        title={<h4 className="my-0 title">Create Admin</h4>}
        setShowSideBar={setShowSideBar}
      >
        <CreateAdmin
          setShowSideBar={setShowSideBar}
          roles={roles}
          reloadData={relaodData}
        />
      </SideBarContainer>
    ),
    [roles, relaodData]
  );

  const updateQuery = (filter: string, value: any): void => {
    const newQuery = { ...query };

    newQuery[filter] = value.length === 1 && value[0] === "All" ? [] : value;

    setCurrentPage(1);
    setQuery(newQuery);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [debouncedSearch, setCurrentPage]);

  useEffect(() => fetchUsers(), [fetchUsers]);

  return (
    <Row className="user-management-filters justify-space-between">
      <div className="d-flex">
        <div className="mr-4">
          <SearchWithClear
            onSearchChange={onSearchChange}
            isLoading={isLoading}
            search={search}
            onClear={onClearSearch}
          />
        </div>
        <div className="mr-4 roles">
          <MultiSelect
            label="Role"
            options={roles}
            isLoading={isLoading}
            onChange={(values) => updateQuery("roles", values)}
          />
        </div>
        <div className="mr-4">
          <MultiSelect
            label="Status"
            options={statuses}
            isLoading={isLoading}
            onChange={(values) => updateQuery("statuses", values)}
          />
        </div>
      </div>
      <div>
        <Button
          type="primary"
          shape="round"
          size="middle"
          htmlType="submit"
          block
          onClick={() => setShowSideBar(true)}
        >
          Create Admin
        </Button>
      </div>

      {showSideBar && createPortal(sideBar, document.body)}
    </Row>
  );
};
