import "./styles.scss";
import { useEffect, useState, useMemo } from "react";
import { Row, Col, Card, Button, Switch } from "antd";
import { Title } from "../../components/LoggedIn/Title";
import { ProfilesService } from "../../services/profiles";
import { LoadingOutlined } from "@ant-design/icons";
import { getFormatted } from "../../lib/PhoneFormatter";
import { useNavigate } from "react-router-dom";
import { useGetRole } from "../../lib/hooks/getRole";

type CardType = {
  email: string;
  data: string | undefined;
  label: string;
  isBorder?: boolean;
  isNotChange?: boolean;
  path?: string;
};

const CardData = ({
  email,
  data,
  label,
  isBorder,
  isNotChange,
  path,
}: CardType) => {
  const navigate = useNavigate();

  const value = useMemo(
    () => (label === "Phone" ? getFormatted(data) : data),
    [data, label]
  );

  return (
    <div className={`${isBorder && "bordered"} mb-4`}>
      <p className="label text my-0">{label}</p>
      <div className="d-flex justify-space-between item">
        <div className="value__container">
          <p title={value} className="text text-primary mb-4 mt-1">
            {value}
          </p>
        </div>
        {!isNotChange && path && (
          <Button
            type="link"
            className="pr-2"
            onClick={() =>
              navigate(path, {
                state: { email },
                replace: true,
              })
            }
          >
            Change
          </Button>
        )}
      </div>
    </div>
  );
};

export const Profile = () => {
  const [data, setData] = useState<any>({});

  const [role, isAdmin] = useGetRole();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isPaymentNoticeEmailed, setIsPaymentNoticeEmailed] =
    useState<boolean>(false);

  const [isNotificationsLoading, setIsNotificationsLoading] =
    useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    setIsNotificationsLoading(true);
    ProfilesService.fetchUser()
      .then((res: any) => {
        setData(res);
        setIsPaymentNoticeEmailed(res.isPaymentNoticeEmailed);
        setIsLoading(false);
        setIsNotificationsLoading(false);
      })
      .catch(() => {});
  }, []);

  const onNotificationsChange = (value: boolean) => {
    setIsNotificationsLoading(true);
    ProfilesService.changeEmailNotificationSettings({
      isPaymentNoticeEmailed: value,
    })
      .then(() => {
        setIsPaymentNoticeEmailed(value);
        setIsNotificationsLoading(false);
      })
      .catch(() => setIsNotificationsLoading(false));
  };

  return (
    <Row className="d-block page-content">
      <Title title="Profile" path="/accounts" />
      <Row className="justify-center mt-6">
        <Col className="profile__container">
          <Row>
            <Card className="card card-data left">
              {isLoading ? (
                <div className="d-flex justify-center align-center loader__container">
                  <LoadingOutlined className="loader" />
                </div>
              ) : (
                <Col>
                  <h3
                    className="header h h2"
                    title={`${data.firstName}`}
                  >{`${data.firstName}`}</h3>
                  <h3
                    className="header h h2"
                    title={`${data.lastName}`}
                  >{`${data.lastName}`}</h3>
                  <Row className="mt-6">
                    <Col className="item">
                      {data.userName && (
                        <CardData
                          email={data.email}
                          label="Username"
                          data={data.userName || "-"}
                          isBorder
                          isNotChange={true}
                        />
                      )}

                      <CardData
                        email={data.email}
                        label="Email"
                        data={data.email}
                        isBorder
                        path="/profile/change-email"
                      />

                      <CardData
                        email={data.email}
                        label="Phone"
                        data={data.phone || undefined}
                        isBorder
                        path="/profile/change-phone"
                      />

                      <CardData
                        email={data.email}
                        label="Password"
                        data="**********"
                        path="/profile/change-password"
                      />
                    </Col>
                  </Row>
                </Col>
              )}
            </Card>
            <Card className="card right">
              <h5 className="h h5 mb-4">
                {!isAdmin ? "Notifications" : "Role"}
              </h5>
              {!isAdmin ? (
                <div className="d-flex justify-space-between align-center">
                  <p className="text ls-5 my-0">Receive email payment notice</p>
                  <Switch
                    loading={isNotificationsLoading}
                    checked={isPaymentNoticeEmailed}
                    onChange={onNotificationsChange}
                  />
                </div>
              ) : (
                <p className="text ls-5 my-0">{role}</p>
              )}
            </Card>
          </Row>
        </Col>
      </Row>
    </Row>
  );
};
