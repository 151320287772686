import { Row, Tooltip } from "antd";
import { QuestionCircleOutlined, RightOutlined } from "@ant-design/icons";
import { useMemo } from "react";
import { useNavigate, NavigateFunction } from "react-router-dom";

type OtherAccountsCardProps = {
  value: number;
};

export const OtherAccountsCard = ({ value }: OtherAccountsCardProps) => {
  const navigate: NavigateFunction = useNavigate();

  const helpText: string =
    "The Accounts which don’t have statuses or with wrong Last Payment/Next Payment dates.";

  const tooltipStyle: any = useMemo(
    () => ({
      maxWidth: "532px",
    }),
    []
  );

  const redirectToOthers = (): void => navigate("/other-accounts");

  return (
    <Row className="d-block filters">
      <Row className="justify-space-between mb-2">
        <Row className="align-center logo-cursor" onClick={redirectToOthers}>
          <p className="my-0 text-md">Other Accounts</p>
          <RightOutlined className="ml-2 search-icon" />
        </Row>
        <Tooltip
          placement="top"
          title={helpText}
          overlayStyle={tooltipStyle}
          overlayClassName="text-sm"
        >
          <QuestionCircleOutlined className="search-icon logo-cursor" />
        </Tooltip>
      </Row>
      <Row>
        <h3 className="my-0 h3">{value.toLocaleString()}</h3>
      </Row>
    </Row>
  );
};
