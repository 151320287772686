import { authHeader } from "./data";
import authAxios from "./data";
import { API_URL } from "../configs/apiUrl";

const get = async () => {
  const response = await authAxios.get(API_URL + "UserDataCheck", {
    headers: authHeader(),
  });

  return response.data;
};

const update = async (request: any) => {
  const response = await authAxios.post(API_URL + "UserDataCheck", request, {
    headers: authHeader(),
  });

  return response.data;
};

const UserDataCheckService = {
  get,
  update,
};

export default UserDataCheckService;
