import { Row, Col, Form, Button } from "antd";
import { PasswordInput } from "../../components/PasswordInput";
import { useAppDispatch } from "../../store";
import { useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { Header } from "../../components/SecurityCheck/Header";
import { clearMessage } from "../../acions/message";
import { changePassword } from "../../acions/auth";
import { useNavigate } from "react-router-dom";

type SendNewPasswordFormProps = {
  data: any;
  setData: (values: any) => void;
  setIsFirstStep: (value: boolean) => void;
  request: any;
};

export const SendNewPasswordForm = ({
  data,
  setData,
  setIsFirstStep,
  request,
}: SendNewPasswordFormProps) => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);

  const [isFormDisabled, setIsFormDisabled] = useState<boolean>(false);

  const onSubmit = (values: any) => {
    if (isPasswordValid) {
      setIsFormDisabled(true);
      setData(values);
      dispatch(clearMessage());

      Promise.resolve(
        dispatch(changePassword({ ...request, password: values.password }))
      )
        .then(() => {
          navigate("/login");
          setIsFormDisabled(false);
        })
        .catch(() => setIsFormDisabled(false));
    }
  };

  return (
    <Row className="mt-16 justify-center mx-4">
      <Col className="forgot-acc-form">
        <Header
          header="Update your password"
          onBackClicked={() => setIsFirstStep(true)}
        />

        <Row className="mt-10 d-flex justify-center links-container">
          <Col className="forgot-pass">
            <Form
              form={form}
              layout="vertical"
              autoComplete="off"
              requiredMark={false}
              onFinish={onSubmit}
              disabled={isFormDisabled}
              initialValues={data}
            >
              <PasswordInput
                label="New Password"
                form={form}
                setIsPasswordValid={setIsPasswordValid}
              />

              <Form.Item className="mt-6">
                <Button
                  type="primary"
                  shape="round"
                  size="large"
                  htmlType="submit"
                  disabled={!isPasswordValid || isFormDisabled}
                  block
                >
                  Update password
                  {isFormDisabled && <LoadingOutlined />}
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
