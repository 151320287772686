import { ReactNode } from "react";

export const getMessageWithContactLink = (text: string): ReactNode => (
  <>
    {text}. Please
    <a
      href="mailto:info@sunwestescrow.com"
      className="ant-btn-link no-decoration"
    >
      &ensp;contact our support
    </a>
  </>
);
