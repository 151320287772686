import "./styles.scss";
import { Row, Col, Card } from "antd";
import { PieChartCard } from "./components/PieChartCard";
import { LineChartCard } from "./components/LineChartCard";
import { useEffect, useState } from "react";
import { DashboardService } from "../../services/dashboard";
import { useGetDashboardData } from "./hooks/getDashboardData";
import { PaymentNumberCard } from "./components/PaymentNumberCard";
import { useGetLastUpdate } from "../../lib/hooks/getLastUpdate";
import { OtherAccountsCard } from "./components/OtherAccountsCard";
import { Loader } from "./components/Loader";
import { KPI_CODES } from "./kpiCodes";
import { InternalServerError } from "../InternalServerError";

export const Dashboard = () => {
  const [data, setData] = useState<any>(null);

  const [isLoading, setIsLoading] = useState<boolean>(true);

  const [opened, closed, other] = useGetDashboardData(data);

  const [totalImpound, setTotalImpound] = useState<number | null>(null);

  const [paymnentAmounts, setPaymentAmounts] = useState<any[] | null>(null);

  const [noKpi, setNoKpi] = useState<boolean>(false);

  const [lastUpdatedLabel] = useGetLastUpdate();

  useEffect(() => {
    DashboardService.fetchDashboardData()
      .then((res) => setData(res))
      .catch(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    if (opened && closed && other !== null) {
      setIsLoading(false);
    }
  }, [closed, opened, other]);

  useEffect(() => {
    if (data && data.kpiValues.length > 0) {
      const value = data.kpiValues.find(
        (item: any) => item.code === KPI_CODES.IMPOUNDS_TOTAL_BALANCE
      ).value;
      setTotalImpound(value);

      const paymentValues = data.kpiValues
        .filter(
          (item: any) =>
            item.code === KPI_CODES.PAYMENTS_THIS_MONTH ||
            item.code === KPI_CODES.PAYMENTS_LAST_DAY
        )
        .map((item: any) => ({
          label:
            item.code === KPI_CODES.PAYMENTS_THIS_MONTH
              ? "thisMonth"
              : "lastDay",
          value: item.value,
        }));

      setPaymentAmounts(paymentValues);
    }

    if (data && data.kpiValues.length === 0) {
      setNoKpi(true);
    }
  }, [data]);

  return (
    <Row className="table-page-content">
      <Row className="d-block w-100">
        <Row className="py-6 mx-8 align-center justify-space-between">
          <h3 className="h h3">Home</h3>
          {lastUpdatedLabel}
        </Row>
        {!noKpi ? (
          <Row className="px-8 py-8 home-content align-center flex-column">
            <div className="d-flex">
              <Col span={8} className="pr-4">
                <Card className="dashboard-card top">
                  <p className="text mt-0 mb-2">Opened Accounts</p>
                  {!isLoading ? (
                    <div>
                      <PieChartCard
                        data={opened.values}
                        colors={opened.colors}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
              <Col span={8} className="pr-4">
                <Card className="dashboard-card top">
                  <p className="text mt-0 mb-2">Closed Accounts</p>
                  {!isLoading ? (
                    <div>
                      <PieChartCard
                        data={closed.values}
                        colors={closed.colors}
                      />
                    </div>
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
              <Col span={8}>
                <Card className="dashboard-card top">
                  <p className="text mt-0 mb-2">Total Impound Balance</p>
                  <div className="h-100">
                    <LineChartCard
                      totalImpound={totalImpound}
                      isDataLoading={isLoading}
                    />
                  </div>
                </Card>
              </Col>
            </div>
            <div className="mt-4 d-flex">
              <Col span={8} className="pr-4">
                <Card className="dashboard-card payments">
                  {!isLoading ? (
                    <OtherAccountsCard value={other} />
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
              <Col span={8} className="pr-4">
                <Card className="dashboard-card payments">
                  {!isLoading ? (
                    <PaymentNumberCard
                      isLoading={isLoading}
                      paymnentAmounts={paymnentAmounts}
                    />
                  ) : (
                    <Loader />
                  )}
                </Card>
              </Col>
            </div>
          </Row>
        ) : (
          <InternalServerError dashboard />
        )}
      </Row>
    </Row>
  );
};
