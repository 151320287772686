import { Button, Col, Row } from "antd";
import { Link } from "react-router-dom";
import { Header } from "../SecurityCheck/Header";

type IssueWithAccountProps = {
  header: string;
  username: string;
  subTitle?: string;
  postInfo?: string;
  onUseAnotherLogin?: () => void;
};

export const IssueWithAccount = ({
  header,
  username,
  subTitle = "Please",
  postInfo = ".",
  onUseAnotherLogin,
}: IssueWithAccountProps) => {
  return (
    <Row className="mt-16 register-cases justify-center w-100">
      <Col className="mx-4">
        <Header header={header} onBackClicked={() => {}} removeBackButton />
        <div className="sub-title__container mb-10">
          <p className="justify-center my-0">
            Email/Username entered:
            <span className="email-text">&ensp;{username}</span>.
          </p>
          <p className="justify-center my-0">
            {subTitle}
            <Link to="mailto:info@sunwestescrow.com">
              &ensp;contact our support
            </Link>
            {postInfo}
          </p>
        </div>
        {onUseAnotherLogin && (
          <Row className="justify-center">
            <Button
              type="primary"
              shape="round"
              size="large"
              htmlType="submit"
              onClick={onUseAnotherLogin}
            >
              Use another login
            </Button>
          </Row>
        )}
      </Col>
    </Row>
  );
};
