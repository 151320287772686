import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { RootState } from "../store";
import UserDataCheckService from "../services/userDataCheck";
import { SET_MESSAGE } from "./types";
import { setUser, getRoleFromJWT } from "./auth";
import { DATA_UPDATED } from "../lib/constants";
import jwtDecode from "jwt-decode";

export const update =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UserDataCheckService.update(request);

      const tokenData: any = jwtDecode(response.token);

      const role = getRoleFromJWT(tokenData);

      dispatch(setUser({ token: response.token }));

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: DATA_UPDATED },
      });

      return await Promise.resolve({ ...response, role });
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "error",
          message: error.response.data.title,
          shouldDisapear: true,
        },
      });
      return await Promise.reject(error);
    }
  };
