import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import {
  CREATE_ADMIN_SUCCESS,
  NEW_ROLE,
  UPDATE_USER_SUCCESS,
  DELETE_USER_SUCCESS,
} from "../lib/constants";
import { UsersService } from "../services/users";
import { RootState } from "../store";
import { SET_MESSAGE } from "./types";

export const registerAdmin =
  (request: any): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UsersService.registerAdmin(request);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: CREATE_ADMIN_SUCCESS },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "error",
          message: error.response.data.title,
          shouldDisapear: true,
        },
      });
      return await Promise.reject(error);
    }
  };

export const blockUnblockUser =
  (
    request: any,
    isBlock: boolean
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UsersService.blockUnblockUser(request, isBlock);

      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "success",
          message: `User was ${isBlock ? "blocked" : "unblocked"}`,
        },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: { type: "error", message: error.response.data.title },
      });
      return await Promise.reject(error);
    }
  };

export const updateUser =
  (
    request: any,
    userId: string,
    roles: any[],
    isMe: boolean,
    role: string
  ): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UsersService.updateUser(request, userId);

      if (isMe) {
        const newRole = roles.find(
          (role: any) => role.value === request.roleId
        ).label;

        newRole &&
          newRole !== role &&
          dispatch({
            type: SET_MESSAGE,
            payload: { type: "info", message: NEW_ROLE },
          });
      } else {
        dispatch({
          type: SET_MESSAGE,
          payload: { type: "success", message: UPDATE_USER_SUCCESS },
        });
      }

      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "error",
          message: error.response.data.title,
          shouldDisapear: true,
        },
      });
      return await Promise.reject(error);
    }
  };

export const deleteUser =
  (id: string): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch) => {
    try {
      const response = await UsersService.deleteUser(id);

      dispatch({
        type: SET_MESSAGE,
        payload: { type: "success", message: DELETE_USER_SUCCESS },
      });
      return await Promise.resolve(response);
    } catch (error: any) {
      dispatch({
        type: SET_MESSAGE,
        payload: {
          type: "error",
          message: error.response.data.title,
          shouldDisapear: true,
        },
      });
      return await Promise.reject(error);
    }
  };
