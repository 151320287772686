import { useState } from "react";
import { SecondStep } from "./SecondStep";
import { ValidationStep } from "../VerificationStep";
import { useGetEmailFromLocation } from "../../../lib/hooks/getEmailfromLocation";
import { useNavigate } from "react-router-dom";

export const ChangePassword = () => {
  const navigate = useNavigate();

  const [isFirstStep, setIsFirstStep] = useState<boolean>(true);

  const [email] = useGetEmailFromLocation();

  return (
    <>
      {isFirstStep ? (
        <ValidationStep
          setStep={() => setIsFirstStep(false)}
          type="email"
          onBack={() => navigate("/profile")}
          subHeaderText="We just want to be sure it’s you."
          headerText="Quick security check"
          email={email}
        />
      ) : (
        <SecondStep onBackClicked={setIsFirstStep} />
      )}
    </>
  );
};
