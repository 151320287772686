import { SingleSelect } from "../../../../components/SingleSelect";
import { useEffect, useState, useCallback, useMemo } from "react";
import AccountsService from "../../../../services/accounts";
import { Row, Table } from "antd";
import { getColumns } from "./historyTableColumns";
import { getFormattedCurrency } from "../../../../lib/currencyFormatter";

type PaymentHistoryProps = {
  selectedContract: any;
};

export const PaymentHistory = ({ selectedContract }: PaymentHistoryProps) => {
  const [selectedYear, setSelectedYear] = useState<number | null>(null);

  const onChangeDate = (value: any) => {
    setSelectedYear(value);
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [data, setData] = useState<any>({});

  const [yearOptions, setYearOptions] = useState<any[]>([]);

  const columns = useMemo(
    () =>
      getColumns(
        selectedYear || data.year,
        <div className="balance-value text-md">
          <span className="label">Balance at the beginning of the year:</span>
          <span className="pl-2">
            {getFormattedCurrency(data.balanceBeginOfYear || 0)}
          </span>
        </div>
      ),
    [data, selectedYear]
  );

  useEffect(() => {
    if (selectedContract) {
      setIsLoading(true);

      AccountsService.fetchAccountContractsHistory(
        selectedContract.accountNumber,
        selectedYear
      )
        .then((res) => {
          setIsLoading(false);

          if (res.availableYears && yearOptions.length === 0) {
            const options = res.availableYears.map((item: number) => ({
              label: item.toString(),
              value: item,
            }));

            setYearOptions(options);
          }

          if (res) {
            const transactions = res.transactions.map(
              (item: any, i: number) => ({ ...item, id: i })
            );
            setData({ ...res, transactions });
          }
        })
        .catch(() => setIsLoading(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContract, selectedYear]);

  const summaryRow = useCallback(() => {
    return (
      <>
        {data.total ? (
          <Table.Summary.Row className="table-footer total-row">
            <Table.Summary.Cell index={0}>
              <p className="my-0 text-md w-500">Total</p>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={1} />
            <Table.Summary.Cell index={2} align="right">
              <p className="my-0 text-md w-500">
                {getFormattedCurrency(data.total.amount)}
              </p>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={3} />
            <Table.Summary.Cell index={4} align="right">
              <p className="my-0 text-md w-500">
                {getFormattedCurrency(data.total.principalPaid)}
              </p>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={5} align="right">
              <p className="my-0 text-md w-500">
                {getFormattedCurrency(data.total.interestPaid)}
              </p>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={6} align="right">
              <p className="my-0 text-md w-500">
                {getFormattedCurrency(data.total.fees)}
              </p>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={7} align="right">
              <p className="my-0 text-md w-500">
                {getFormattedCurrency(data.total.paymentImpound)}
              </p>
            </Table.Summary.Cell>
            <Table.Summary.Cell index={8} />
          </Table.Summary.Row>
        ) : (
          <></>
        )}
      </>
    );
  }, [data]);

  return (
    <div className="table-page-content history-table-content px-8">
      <div className="pt-6">
        <SingleSelect
          options={yearOptions}
          label="Year"
          value={selectedYear || data.year}
          onChange={onChangeDate}
          loading={isLoading}
        />
        <p className="my-0 pt-4 text-sm">
          Please be advised that it may take up to 24 hours to process the
          transaction. All payments will be posted as of the payment effective
          date and not the payment initiation date.
        </p>
      </div>
      <Row className="d-block table__container mt-4">
        <Table
          dataSource={data.transactions}
          columns={columns}
          pagination={false}
          loading={isLoading}
          summary={summaryRow}
          className="overflow-y-auto"
          rowKey={"id"}
        ></Table>

        <p className="my-0 pt-4 text-sm title">
          Disclaimer: This balance and/or transaction history is provided for
          information purposes only and should not be used for payoff purposes.
        </p>
      </Row>
    </div>
  );
};
