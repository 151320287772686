import { SecurityCheck } from "../../components/SecurityCheck";
import { useMemo, useState, useCallback, useEffect } from "react";
import { useAppDispatch } from "../../store";
import { clearMessage } from "../../acions/message";
import {
  confirmVerificationCode,
  sendVerificationCode,
} from "../../acions/profiles";
import { useGetFormattedPhoneSecure } from "../../lib/hooks/getFormattedPhoneSecure";

type ValidationStepProps = {
  headerText: string;
  subHeaderText: string;
  setStep: () => void;
  type: "email" | "SMS";
  email: string;
  onBack: () => void;
  onConfirmSuccess?: () => any;
  phone?: any;
};

export const ValidationStep = ({
  headerText,
  subHeaderText,
  setStep,
  type,
  onConfirmSuccess,
  onBack,
  phone,
  email,
}: ValidationStepProps) => {
  const dispatch = useAppDispatch();

  const [isConfirmDisabled, setIsConfirmDisabled] = useState<boolean>(false);

  const [verificationCodeId, setVerificationCodeId] = useState<string | null>(
    null
  );

  const [formattedPhone] = useGetFormattedPhoneSecure({ phone });

  const securityCheckSubTitle = useMemo(
    () => (
      <div className="sub-title__container mb-10">
        <p className="justify-center my-0">{subHeaderText}</p>
        <p className="justify-center my-0">
          Enter the code we’ve just sent to
          <span className="email-text">&ensp;{formattedPhone || email}</span>
        </p>
      </div>
    ),
    [email, formattedPhone, subHeaderText]
  );

  const sendCode = useCallback(async () => {
    setIsConfirmDisabled(true);
    dispatch(clearMessage());

    const sendTo =
      type === "email" ? email : `1${phone.replace(/[\s-()]/g, "")}`;

    const request: any = {
      type: type === "email" ? 0 : 1,
      sendTo,
    };

    try {
      const response: any = await Promise.resolve(
        dispatch(sendVerificationCode(request))
      );
      setVerificationCodeId(response.verificationCodeId);
      setIsConfirmDisabled(false);
    } catch {
      setIsConfirmDisabled(false);
    }
  }, [dispatch, email, phone, type]);

  useEffect(() => {
    sendCode();
  }, [sendCode]);

  const onConfirmClicked = (values: any) => {
    const code = values.securityCode.replace(/-/g, "");

    setIsConfirmDisabled(true);
    dispatch(clearMessage());

    const request = {
      code,
      verificationCodeId,
    };

    Promise.resolve(dispatch(confirmVerificationCode(request)))
      .then(() => {
        if (onConfirmSuccess) {
          onConfirmSuccess()
            .then(() => {
              setStep();
              setIsConfirmDisabled(false);
            })
            .catch(() => {
              setIsConfirmDisabled(false);
            });
        } else {
          setStep();
          setIsConfirmDisabled(false);
        }
      })
      .catch(() => {
        setIsConfirmDisabled(false);
      });
  };

  return (
    <>
      <SecurityCheck
        header={headerText}
        isRememberCode={false}
        isTimer={true}
        subTitle={securityCheckSubTitle}
        type={type}
        isConfirmDisabled={isConfirmDisabled}
        onConfirm={(values) => onConfirmClicked(values)}
        onResend={() => sendCode()}
        setStep={onBack}
      />
    </>
  );
};
