import { createPortal } from "react-dom";
import { useEffect } from "react";

export const NotificationContainer = ({ children }: any) => {
  const mount = document.getElementById("notification");
  const el = document.createElement("div");

  useEffect((): any => {
    if (mount) {
      mount.appendChild(el);

      return () => mount.removeChild(el);
    }
  }, [el, mount]);

  return createPortal(children, el);
};
